import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Benutzer } from '../../../models/generated/benutzer';

@Injectable({
  providedIn: 'root',
})
export class PasswordrecoveryService {
  constructor(private http: HttpClient) {}

  registerRecovery(userId: string, email: string, password: string, connectionId?: string | null) {
    const recoveryData = { guid: userId, email, password, connectionId };
    const recoverydataBase64 = btoa(JSON.stringify(recoveryData));

    return lastValueFrom(
      this.http.post<Benutzer>('/api/PasswordRecovery/registerrecovery', { content: recoverydataBase64 }, {})
    );
  }

  getRecoveryInfo(): Promise<Benutzer> {
    return lastValueFrom(this.http.get<Benutzer>('/api/PasswordRecovery/getrecoveryinfo'));
  }
  getRecoveryInfoRegister(id: string): Promise<Benutzer> {
    const params = new HttpParams().append('id', id);
    return lastValueFrom(this.http.get<Benutzer>('/api/PasswordRecovery/getrecoveryinforegister', { params }));
  }

  declineRecovery(): Promise<Benutzer> {
    return lastValueFrom(this.http.get<Benutzer>('/api/PasswordRecovery/declinerecovery'));
  }

  waitForActivation(email: string, connectionId?: string | null) {
    let httpParams = new HttpParams().append('email', email);

    if (connectionId != null) {
      httpParams = httpParams.append('connectionId', connectionId);
    }

    const options = { params: httpParams };

    return lastValueFrom(this.http.get('/api/PasswordRecovery/waitforactivation', options));
  }

  waitForActivationRegister(userId: string, email: string, connectionId?: string | null) {
    let httpParams = new HttpParams().append('email', email).append('id', userId);

    if (connectionId != null) {
      httpParams = httpParams.append('connectionId', connectionId);
    }

    const options = { params: httpParams };

    return lastValueFrom(this.http.get('/api/PasswordRecovery/waitforactivationregister', options));
  }

  requestPassword(benutzername: string, connectionId?: string | null): Promise<string> {
    let httpParams = new HttpParams().append('benutzername', benutzername);
    if (connectionId != null) {
      httpParams = httpParams.append('connectionId', connectionId);
    }

    const options = { params: httpParams, responseType: 'text' as const };

    return lastValueFrom(this.http.get('/api/PasswordRecovery/recover', options));
  }
}
