import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

export interface Font {
  family: string;
  weight: string;
  size: string;
}

@Directive({ selector: '[fzFont]' })
export class FontDirective implements OnChanges {
  @Input('fzFont') font: Font | undefined;
  constructor(private elementRef: ElementRef<HTMLElement>) {}
  ngOnChanges(): void {
    if (this.font != null) {
      this.elementRef.nativeElement.style.fontFamily = this.font.family;
      this.elementRef.nativeElement.style.fontWeight = this.font.weight;
      this.elementRef.nativeElement.style.fontSize = this.font.size; // * factor
    }
  }
}
