import { WorkspaceService } from '../../../shared/services';
import { FlinkyAuthService } from './../../services/auth/flinky-auth.service';
import { Component, Input } from '@angular/core';
import { FormularFactory } from '@modules/formular/formular-factory';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HalbjahrDescription } from '@modules/formular/formularsatz';
import { JahrgangService } from '../../services/jahrgang.service';
import { Zeugnissatz } from '../../../models/zeugnissatz';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'fz-jahrgang-neu',
  templateUrl: './jahrgang-neu.component.html',
  styleUrls: ['./jahrgang-neu.component.scss'],
})
export class JahrgangNeuComponent {
  @Input() mode: 'neu' | 'wiederfuellen' = 'neu';
  @Input() idJahrgang: string | null = null;

  steps = [{ label: 'Lizenz auswählen' }, { label: 'Schuljahr und Zeugnisformular auswählen' }];
  activeStep = 0;
  zeugnislevels: ({ text: string; wert: number; value: number } | { text: string; wert: undefined; value: number })[];
  schuljahre: { text: string; wert: number }[];
  zeugnisse: HalbjahrDescription[] = [];
  jahrgangform: UntypedFormGroup;

  isUnique: boolean = true;
  isLoading: boolean = false;

  constructor(
    public authService: FlinkyAuthService,
    private schuelerService: WorkspaceService,
    private jahrgangService: JahrgangService,
    private notificationService: NotificationService
  ) {
    this.jahrgangform = new UntypedFormGroup(
      {
        schulleitung: new UntypedFormControl('', Validators.required),
        klassenbezeichnung: new UntypedFormControl('', Validators.required),
        klassenleitung: new UntypedFormControl('', Validators.required),
        schuljahr: new UntypedFormControl('', Validators.required),
        halbjahrDescription: new UntypedFormControl('', { validators: Validators.required }),
        zeugnislevel: new UntypedFormControl({ value: undefined }),
        rufname: new UntypedFormControl(''),
        kundennummer: new UntypedFormControl('', Validators.required),
      }
      // { updateOn: 'submit', asyncValidators: jahrgangUniqueValidator.validate.bind(this.jahrgangUniqueValidator) }
    );

    const currentYear = new Date().getFullYear();

    this.schuljahre = [
      { text: `${currentYear - 1}/${currentYear}`, wert: currentYear - 1 },
      { text: `${currentYear}/${currentYear + 1}`, wert: currentYear },
      { text: `${currentYear + 1}/${currentYear + 2}`, wert: currentYear + 1 },
      { text: `${currentYear + 2}/${currentYear + 3}`, wert: currentYear + 2 },
      { text: `${currentYear + 3}/${currentYear + 4}`, wert: currentYear + 3 },
      { text: `${currentYear + 4}/${currentYear + 5}`, wert: currentYear + 4 },
    ];

    this.zeugnislevels = [
      { text: 'Formulare Stufe 1', wert: 1, value: 1 },
      { text: 'Formulare Stufe 2', wert: 2, value: 2 },
      { text: 'Gemischt', wert: undefined, value: 0 },
    ];

    const today = new Date();
    this.jahrgangform.patchValue({ schuljahr: today.getMonth() <= 7 ? today.getFullYear() - 1 : today.getFullYear() });

    this.authService.lizenzChanged.subscribe(() => {
      this.updateZeugnisse();
      this.updateKundennummer();
    });
    this.updateZeugnisse();
    this.updateKundennummer();
  }

  private updateKundennummer() {
    this.jahrgangform.patchValue({ kundennummer: this.authService.lizenz?.kundennummer ?? '' });
  }

  private updateZeugnisse() {
    if (this.authService.lizenz != null) {
      this.zeugnisse = FormularFactory.getFormularsatz(this.authService.lizenz.bundesland).getAllHalbjahre(
        this.jahrgangform.value.schuljahr
      );
    }
  }

  get isSL() {
    return this.authService.lizenz?.bundesland === 'SL';
  }

  get lizenzImage() {
    const zeugniskopflizenz = this.authService.lizenz?.findZeugniskopflizenz({
      schuljahr: this.jahrgangform.value.schuljahr,
      halbjahr: this.jahrgangform.value.halbjahrDescription?.halbjahr,
    });
    return zeugniskopflizenz?.zeugniskopf;
  }

  schuljahrChanged(): void {
    if (this.jahrgangform.value.schuljahr && this.authService.lizenz != null) {
      this.zeugnisse = FormularFactory.getFormularsatz(this.authService.lizenz.bundesland).getAllHalbjahre(
        this.jahrgangform.value.schuljahr
      );
      this.jahrgangform.patchValue({ zeugnislevel: undefined, halbjahrDescription: undefined });
    }
  }

  zeugnisChanged(): void {
    if (this.jahrgangform.value.halbjahrDescription?.klassenstufe !== 2 && this.isSL) {
      this.jahrgangform.patchValue({ zeugnislevel: undefined });
    }
  }

  async onSubmit(): Promise<void> {
    if (this.authService.lizenz != null) {
      const zeugnissatz = await this.createZeugnissatz();

      this.isUnique = (await this.jahrgangService.isJahrgangVorhanden(zeugnissatz)) == null;

      if (this.isUnique && this.jahrgangform.valid) {
        try {
          this.isLoading = true;
          const jahrgangId = await this.jahrgangService.createJahrgang(zeugnissatz);
          this.schuelerService.openJahrgang(jahrgangId);
        } catch (_ex) {
          zeugnissatz.remove();
        } finally {
          this.isLoading = false;
        }
      } else {
        this.jahrgangform.controls['klassenleitung'].markAllAsTouched();
        this.jahrgangform.controls['schulleitung'].markAllAsTouched();
        this.jahrgangform.controls['klassenbezeichnung'].markAllAsTouched();
        this.jahrgangform.controls['schuljahr'].markAllAsTouched();
        this.jahrgangform.controls['halbjahrDescription'].markAllAsTouched();
        this.jahrgangform.controls['zeugnislevel'].markAllAsTouched();
        this.jahrgangform.controls['rufname'].markAllAsTouched();
      }
    }
  }

  async createZeugnissatz(): Promise<Zeugnissatz> {
    const formValue = this.jahrgangform.value;
    const jahrgang =
      this.mode === 'neu' || this.schuelerService.jahrgang == null
        ? await this.jahrgangService.factory.createJahrgang()
        : this.schuelerService.jahrgang;
    jahrgang.rufname = formValue.rufname;

    const zeugnissatz = await this.jahrgangService.factory.createZeugnissatz(
      jahrgang,
      formValue.schuljahr,
      formValue.halbjahrDescription.halbjahr,
      formValue.halbjahrDescription.klassenstufe
    );
    zeugnissatz.zeugnislevel = formValue.zeugnislevel?.wert;
    zeugnissatz.klassenbezeichnung = formValue.klassenbezeichnung;
    zeugnissatz.klassenleitung = formValue.klassenleitung;
    zeugnissatz.schulleitung = formValue.schulleitung;
    return zeugnissatz;
  }
}
