import { Formular } from '@modules/formular/formular';
import { Bereich } from './bereich';
import { KPRowField } from './fields/kp-item-benotung-field';
import { NoteField } from './fields/note-field';
import { TextField } from './fields/text-field';
import { ValueField } from './fields/value-field';
import { VerbalField } from './fields/verbal-field';
import { FormularFach } from './formular-fach';

export interface BereichDescription {
  label: string;
  labelShort: string;
  subs?: string[];
  isKopfnote?: boolean;
  kategorien?: string;
  eigeneTextbausteinKey?: string;
  zusatzTextRequired?: boolean;
}
export class FormularBereich {
  bereich: Bereich;
  text: VerbalField<Bereich>;
  zusatztext: VerbalField<Bereich>;
  note: NoteField;
  label: TextField<Bereich>;
  isVisibleOverrideNoteDefaultFalseField: ValueField<FormularBereich, boolean>;
  isVisibleOverrideTextDefaultFalseField: ValueField<FormularBereich, boolean>;
  isVisibleOverrideTextDefaultTrueField: ValueField<FormularBereich, boolean>;
  isVisibleOverrideZusatztextDefaultFalseField: ValueField<FormularBereich, boolean>;
  isVisibleOverrideZusatztextDefaultTrueField: ValueField<FormularBereich, boolean>;
  isVisibleOverrideDefaultTrueField: ValueField<FormularBereich, boolean>;
  kpRows: KPRowField[] = [];

  constructor(
    public readonly formular: Formular,
    public readonly fach: FormularFach,
    bereichOrKey: Bereich | string | null,
    public readonly description: BereichDescription
  ) {
    this.bereich = bereichOrKey instanceof Bereich ? bereichOrKey : fach.fach.bereiche.getItem(bereichOrKey);
    const keyPrefix = `${this.fach.key}.${this.bereich.key ?? 'gesamt'}`;
    this.text = new VerbalField(
      { ...description, property: 'text', keyPrefix, bereich: this },
      { object: this.bereich }
    );
    this.zusatztext = new VerbalField(
      {
        ...description,
        required: (description.zusatzTextRequired ?? false) === false ? false : undefined,
        property: 'zusatztext',
        keyPrefix,
        bereich: this,
      },
      { object: this.bereich }
    );
    this.note = new NoteField(
      { ...description, fachKey: fach.key ?? '', isKopfnote: description.isKopfnote ?? false },
      { zeugnis: formular.zeugnis, bereich: this.bereich }
    );
    this.label = new TextField({ ...description, property: 'label', keyPrefix }, { object: this.bereich });
    this.isVisibleOverrideNoteDefaultFalseField = new ValueField(
      {
        ...description,
        required: false,
        property: 'isVisibleOverrideNoteDefaultFalse',
        keyPrefix,
        defaultValue: false,
      },
      { object: this }
    );
    this.isVisibleOverrideTextDefaultFalseField = new ValueField(
      { ...description, required: false, property: 'isVisibleOverrideTextDefaultFalse', defaultValue: false },
      { object: this }
    );
    this.isVisibleOverrideTextDefaultTrueField = new ValueField(
      { ...description, required: false, property: 'isVisibleOverrideTextDefaultTrue', defaultValue: true },
      { object: this }
    );
    this.isVisibleOverrideZusatztextDefaultFalseField = new ValueField(
      { ...description, required: false, property: 'isVisibleOverrideZusatztextDefaultFalse', defaultValue: false },
      { object: this }
    );
    this.isVisibleOverrideZusatztextDefaultTrueField = new ValueField(
      { ...description, required: false, property: 'isVisibleOverrideZusatztextDefaultTrue', defaultValue: true },
      { object: this }
    );
    this.isVisibleOverrideDefaultTrueField = new ValueField(
      { ...description, required: false, property: 'isVisibleOverrideDefaultTrue', defaultValue: true },
      { object: this }
    );
    this.kpRows = Array.from(this.bereich.rasterBereich.items)
      .filter((item) => (item.text?.trim() ?? '').length > 0)
      .map(
        (item) =>
          new KPRowField(
            {
              ...description,
              required: this.formular.areKPItemsOptional ? false : undefined,
              warn: this.formular.warnKPItemsIfOptional ? true : undefined,
            },
            { benotung: this.bereich.items.getBenotung(item) }
          )
      );
  }
  get key(): string | null {
    return this.bereich.key;
  }

  get labelNbsp(): string {
    return this.bereich.label?.replace(/ /g, String.fromCharCode(160)) ?? '';
  }

  get isNoteVisible(): boolean {
    return this.formular.noteFields.indexOf(this.note) >= 0;
  }

  get isVisibleOverrideDefaultFalse(): boolean {
    return this.bereich.isVisibleOverride ?? false;
  }
  set isVisibleOverrideDefaultFalse(value: boolean) {
    this.bereich.isVisibleOverride = value ? true : null;
  }
  get isVisibleOverrideDefaultTrue(): boolean {
    return this.bereich.isVisibleOverride ?? true;
  }
  set isVisibleOverrideDefaultTrue(value: boolean) {
    this.bereich.isVisibleOverride = value ? null : false;
  }
  get isVisibleOverrideNoteDefaultFalse(): boolean {
    return this.bereich.isVisibleOverrideNote ?? false;
  }
  set isVisibleOverrideNoteDefaultFalse(value: boolean) {
    this.bereich.isVisibleOverrideNote = value ? true : null;
  }
  get isVisibleOverrideNoteDefaultTrue(): boolean {
    return this.bereich.isVisibleOverrideNote ?? true;
  }
  set isVisibleOverrideNoteDefaultTrue(value: boolean) {
    this.bereich.isVisibleOverrideNote = value ? null : false;
  }
  get isVisibleOverrideTextDefaultFalse(): boolean {
    return this.bereich.isVisibleOverrideText ?? false;
  }
  set isVisibleOverrideTextDefaultFalse(value: boolean) {
    this.bereich.isVisibleOverrideText = value ? true : null;
  }
  get isVisibleOverrideTextDefaultTrue(): boolean {
    return this.bereich.isVisibleOverrideText ?? true;
  }
  set isVisibleOverrideTextDefaultTrue(value: boolean) {
    this.bereich.isVisibleOverrideText = value ? null : false;
  }
  get isVisibleOverrideItemsDefaultFalse(): boolean {
    return this.bereich.isVisibleOverrideItems ?? false;
  }
  set isVisibleOverrideItemsDefaultFalse(value: boolean) {
    this.bereich.isVisibleOverrideItems = value ? true : null;
  }
  get isVisibleOverrideItemsDefaultTrue(): boolean {
    return this.bereich.isVisibleOverrideItems ?? true;
  }
  set isVisibleOverrideItemsDefaultTrue(value: boolean) {
    this.bereich.isVisibleOverrideItems = value ? null : false;
  }
  get isVisibleOverrideZusatztextDefaultFalse(): boolean {
    return this.bereich.isVisibleOverrideZusatztext ?? false;
  }
  set isVisibleOverrideZusatztextDefaultFalse(value: boolean) {
    this.bereich.isVisibleOverrideZusatztext = value ? true : null;
  }
  get isVisibleOverrideZusatztextDefaultTrue(): boolean {
    return this.bereich.isVisibleOverrideZusatztext ?? true;
  }
  set isVisibleOverrideZusatztextDefaultTrue(value: boolean) {
    this.bereich.isVisibleOverrideZusatztext = value ? null : false;
  }
  get kpRowsVisible(): KPRowField[] {
    return this.kpRows.filter((r) => r.value !== 0);
  }
  get kpRowsHidden(): KPRowField[] {
    return this.kpRows.filter((r) => r.value === 0);
  }
}
