import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkspaceService } from '../../../../../shared/services';
import { SchuelerlisteService } from '../../schuelerliste.service';
import { JahrgangService } from '../../../../../shared/services/jahrgang.service';
import { ImportSchueler } from '../model/import-schueler';

@Component({
  selector: 'fz-schuelerliste-import-assign',
  templateUrl: './schuelerliste-import-assign.component.html',
})
export class SchuelerlisteImportAssignComponent {
  @Input() lines: any[] = [];
  @Output() next: EventEmitter<ImportSchueler[]> = new EventEmitter<ImportSchueler[]>();
  @Output() back = new EventEmitter<null>();
  Object = Object;

  selectedHeaders: string[] = [];
  currentIndex: number = 0;

  currentColumn: string = 'Nachname';
  availableColums: string[] = ['Nachname', 'Vorname', 'Geburtsdatum', 'Geburtsort', 'Geschlecht', 'Klasse'];

  constructor(
    public schuelerlisteService: SchuelerlisteService,
    private schuelerService: WorkspaceService,
    private jahrgangService: JahrgangService
  ) {}

  select(index: number): void {
    this.selectedHeaders[index] = this.currentColumn;
    this.currentIndex++;
    this.currentColumn = this.availableColums[this.currentIndex];
  }

  skip(): void {
    this.currentIndex++;
    this.currentColumn = this.availableColums[this.currentIndex];
  }

  restart(): void {
    this.selectedHeaders = [];
    this.currentIndex = 0;
    this.currentColumn = this.availableColums[this.currentIndex];
  }

  async continue() {
    if (this.schuelerService.jahrgang != null) {
      const schuelers: ImportSchueler[] = [];
      const nameIndex = this.selectedHeaders.indexOf('Nachname');
      const vornameIndex = this.selectedHeaders.indexOf('Vorname');
      const geschlechtIndex = this.selectedHeaders.indexOf('Geschlecht');
      const geburtsortIndex = this.selectedHeaders.indexOf('Geburtsort');
      const geburtsdatumIndex = this.selectedHeaders.indexOf('Geburtsdatum');
      const klasseIndex = this.selectedHeaders.indexOf('Klasse');
      for (const line of this.lines) {
        const schueler = {} as ImportSchueler;
        schueler.name = line[nameIndex];
        schueler.vorname = line[vornameIndex];
        schueler.geschlecht = line[geschlechtIndex];
        schueler.geburtsdatum = this.schuelerlisteService.getDisplayValue(line[geburtsdatumIndex]);
        schueler.geburtsort = line[geburtsortIndex];
        schueler.klasse = line[klasseIndex];
        schuelers.push(schueler);
      }
      this.next.emit(schuelers);
    }
  }
}
