<fz-panel *ngIf="model != null">
  <fz-select
    placeholder="Bitte wählen sie zwischen LERNEN und GANZHEITLICHE ENTWICKLUNG"
    class="Allgemeiner_Zeugnis_Text"
    [field]="model.spsSelectField"
    emptyLabel="(Bleibt leer - es wird nichts gedruckt)"
    [emptyValue]="'Leer'"
  >
    <fz-panel fzSelectCandidate [fzValue]="'Lernen'" fzLabel="LERNEN">
      <fz-label>{{ lernenLabel }}</fz-label>
    </fz-panel>
    <fz-panel fzSelectCandidate [fzValue]="'GanzheitlicheEntwicklung'" fzLabel="GANZHEITLICHE ENTWICKLUNG">
      <fz-label>{{ ganzheitlicheEntwicklungLabel }}</fz-label>
    </fz-panel>
    <fz-panel fzSelectCandidate [fzValue]="'Sprache'" fzLabel="SPRACHE">
      <fz-label>{{ spracheLabel }}</fz-label>
    </fz-panel>
    <fz-panel fzSelectCandidate [fzValue]="'Frei'" fzLabel="Freier Bildungsgang">
      <fz-label>{{ freiPrefixLabel }}</fz-label>
      <fz-text [field]="formular.spsText"></fz-text>
      <fz-label>{{ freiSuffixLabel }}</fz-label>
    </fz-panel>
  </fz-select>
</fz-panel>
