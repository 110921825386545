import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { NIFormular } from './ni-formular';
import { NIZeugnisFormular } from './ni-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { NIZeugnistypComponent } from '../projectables/ni-zeugnistyp.component';
import { NIZeugnissatzComponent } from '../projectables/ni-zeugnissatz.component';
import { NISchuelerComponent } from '../projectables/ni-schueler.component';
import { NIVersaeumnisComponent } from '../projectables/ni-versaeumnis.component';
import { NIUnterschriftComponent } from '../projectables/ni-unterschrift.component';
import { FormularBereich } from '../../../models/formular-bereich';
import { KPBlockFactoryComponent } from '../../kp/kp-block-factory.component';

export class NI12hjStandardFormular extends NIZeugnisFormular {
  get viewType(): FormularViewType {
    return NI12hjStandardFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [this.deutsch, this.mathematik];
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    return noteFields;
  }
}

@Component({
  selector: 'fz-ni-12hj-standard-formular',
  templateUrl: 'ni-12hj-standard-formular.component.html',
})
export class NI12hjStandardFormularComponent extends FormularComponentBase<NI12hjStandardFormular> {
  static createBereichModel(bereich: FormularBereich) {
    const formular = bereich.formular as NIFormular;
    return {
      key: bereich.bereich.key ?? 'gesamt',
      label: bereich.description.label,
      textField:
        formular.zeugnis.zeugnissatz.schuljahr < 2024 ||
        bereich.kpRowsVisible.length === 0 ||
        bereich.isVisibleOverrideTextDefaultTrue
          ? bereich.text
          : undefined,
      kp:
        formular.zeugnis.zeugnissatz.schuljahr >= 2024
          ? KPBlockFactoryComponent.createModelFromBereich(bereich)
          : undefined,
      verbalVisibleField:
        formular.zeugnis.zeugnissatz.schuljahr >= 2024 && bereich.kpRowsVisible.length > 0
          ? bereich.isVisibleOverrideTextDefaultTrueField
          : undefined,
    };
  }

  static createModel(formular: NIFormular) {
    return formular.zeugnis.zeugnissatz.schuljahr >= 2023
      ? {
          zeugnistyp: NIZeugnistypComponent.createModel(formular),
          zeugnissatz: NIZeugnissatzComponent.createModel(formular),
          schueler: NISchuelerComponent.createModel(formular),
          versaeumnis: NIVersaeumnisComponent.createModel(formular),
          interessen: {
            label: formular.interessen.description.label,
            textField: formular.interessen,
          },
          faecherMitBereichen: [
            {
              key: formular.deutsch.key,
              label: 'Deutsch Kompetenzbereiche¹',
              bereiche: [
                this.createBereichModel(formular.deutsch.sprechenZuhoeren),
                { ...this.createBereichModel(formular.deutsch.texteVerfassen), label: 'Schreiben' },
                { ...this.createBereichModel(formular.deutsch.lesen), label: 'Lesen - mit Texten und Medien umgehen' },
              ],
            },
            {
              key: formular.mathematik.key,
              label: 'Mathematik Kompetenzbereiche²',
              bereiche: [
                this.createBereichModel(formular.mathematik.zahlenOperationen),
                this.createBereichModel(formular.mathematik.raumForm),
                this.createBereichModel(formular.mathematik.groessenMessen),
              ],
            },
          ],
          lernArbeitsverhalten: {
            label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
          },
          sozialverhalten: {
            label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
          },
          bemerkungen: {
            label: formular.bemerkungen.description.label,
            textField: formular.bemerkungen,
          },
          unterschrift: NIUnterschriftComponent.createModel(formular),
        }
      : {
          zeugnistyp: NIZeugnistypComponent.createModel(formular),
          zeugnissatz: NIZeugnissatzComponent.createModel(formular),
          schueler: NISchuelerComponent.createModel(formular),
          versaeumnis: NIVersaeumnisComponent.createModel(formular),
          interessen: {
            label: formular.interessen.description.label,
            textField: formular.interessen,
          },
          faecherMitBereichen: [
            {
              key: formular.deutsch.key,
              label: 'Deutsch*',
              bereiche: [
                this.createBereichModel(formular.deutsch.sprechenZuhoeren),
                this.createBereichModel(formular.deutsch.lesen),
                this.createBereichModel(formular.deutsch.texteVerfassen),
              ],
            },
            {
              key: formular.mathematik.key,
              label: 'Mathematik**',
              bereiche: [
                this.createBereichModel(formular.mathematik.zahlenOperationen),
                this.createBereichModel(formular.mathematik.groessenMessen),
                this.createBereichModel(formular.mathematik.raumForm),
              ],
            },
          ],
          lernArbeitsverhalten: {
            label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
          },
          sozialverhalten: {
            label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
          },
          bemerkungen: {
            label: formular.bemerkungen.description.label,
            textField: formular.bemerkungen,
          },
          unterschrift: NIUnterschriftComponent.createModel(formular),
        };
  }

  get model() {
    return NI12hjStandardFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<NI12hjStandardFormular>) {
    super(formularService);
  }
}
