import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import moment from 'moment';
import { ImportSchueler } from '../model/import-schueler';

@Component({
  selector: 'fz-schuelerliste-import-filter',
  templateUrl: './schuelerliste-import-filter.component.html',
  styleUrls: ['./schuelerliste-import-filter.component.scss'],
})
export class SchuelerlisteImportFilterComponent implements OnChanges {
  @Input() schuelers: ImportSchueler[] = [];
  @Output() next: EventEmitter<ImportSchueler[]> = new EventEmitter<ImportSchueler[]>();
  @Output() back = new EventEmitter<null>();
  selectedSchuelers: ImportSchueler[] = [];
  klassen: string[] = [];
  selectedKlasse: string = '-- Alle --';
  filteredSchuelers: ImportSchueler[] = [];

  ngOnChanges(): void {
    this.klassen = [];
    this.klassen.push('-- Alle --');

    this.klassen.push(...[...new Set(this.schuelers.map((s) => s.klasse ?? ''))]);
    this.filteredSchuelers = this.schuelers;
  }

  klasseChange(): void {
    if (this.selectedKlasse !== '-- Alle --') {
      this.filteredSchuelers = this.schuelers.filter((s) => s.klasse === this.selectedKlasse);
    } else {
      this.filteredSchuelers = this.schuelers;
    }
  }

  isValidDate(dateString: string): boolean {
    return moment(dateString, 'dd.MM.yyyy').isValid();
  }
}
