/* generated file - do not touch! */

enum UpdateRasterResultCode {
  unknown = 'Unknown',
  serverResultNewer = 'ServerResultNewer',
  nothingToDo = 'NothingToDo',
  updated = 'Updated',
  overwritten = 'Overwritten',
  overwriteError = 'OverwriteError',
  updateError = 'UpdateError',
}
export default UpdateRasterResultCode;
