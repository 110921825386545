import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FlinkyAuthService } from './../../../services/auth/flinky-auth.service';
import { Component, OnInit } from '@angular/core';
import { AppInfoService } from '../../../../shared/services';

import * as signalR from '@microsoft/signalr';
import { PasswordrecoveryService } from '../passwordrecovery.service';

@Component({
  selector: 'app-passwordrecovery-recover',
  templateUrl: './passwordrecovery-recover.component.html',
  styleUrls: ['./passwordrecovery-recover.component.scss'],
})
export class PasswordrecoveryRecoverComponent implements OnInit {
  requestedText = '';
  formVisible = true;
  successButtons = false;
  email = '';
  connection?: signalR.HubConnection;
  recoveryform: UntypedFormGroup;

  constructor(
    private flinkyAuth: FlinkyAuthService,
    public appInfo: AppInfoService,
    private router: Router,
    private recoveryService: PasswordrecoveryService
  ) {
    this.recoveryform = new UntypedFormGroup({
      login: new UntypedFormControl('', Validators.required),
    });
  }

  async ngOnInit() {
    const baseUrl = document.getElementsByTagName('base')[0].href;

    const url = (baseUrl + '/signalr_passwordrecovery').replace(/([^:]\/)\/+/g, '$1');

    this.connection = new signalR.HubConnectionBuilder().withUrl(url).build();

    this.connection.on('PasswordRequested', () => {
      this.requestedText = `Das Passwort wurde an die E-Mail-Adresse ${this.email} gesendet.`;
      this.successButtons = true;
    });

    this.connection.on('PasswordRequestError', () => {
      this.requestedText = `Das Passwort konnte nicht an die E-Mail-Adresse ${this.email} gesendet werden. Bitte versuchen Sie es später erneut.`;
      this.successButtons = false;
    });

    this.connection.start().catch((err) => console.log(err));
  }

  toLogin(): void {
    this.router.navigate(['/login-form']);
  }

  repeat(): void {
    this.formVisible = true;
  }

  async recover() {
    this.formVisible = false;

    this.requestedText = 'Die Passwortwiederherstellung wird angefordert.';

    this.email = await this.recoveryService.requestPassword(
      this.recoveryform.value.login,
      this.connection?.connectionId
    );
    if (this.email !== '') {
      this.requestedText = `Das Passwortanfrage für die E-Mail-Adresse ${this.email} wird gesendet...`;
      // this.successButtons = true;
    } else {
      this.requestedText =
        'Die Passwortwiederherstellung steht nicht zur Verfügung, da keine E-Mail-Adresse registriert ist.';
      this.successButtons = false;
    }
  }
}
