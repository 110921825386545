import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { BWFormular } from './bw-formular';
import { BWZeugnisFormular } from './bw-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { BWZeugnistypComponent } from '../projectables/bw-zeugnistyp.component';
import { BWSchuelerComponent } from '../projectables/bw-schueler.component';
import { BWUnterschriftComponent } from '../projectables/bw-unterschrift.component';
import { BWLeistungenDeMaComponent } from '../projectables/bw-leistungen-de-ma.component';

export class BW12hjStandardFormular extends BWZeugnisFormular {
  get viewType(): FormularViewType {
    return BW12hjStandardFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    if (this.zeugnis.zeugnissatz.klassenstufe === 2 && this.zeugnis.zeugnissatz.halbjahr === 1) {
      noteFields.push(this.deutsch.gesamt.note, this.mathematik.gesamt.note);
    }
    return noteFields;
  }
}

@Component({
  selector: 'fz-bw-12hj-standard-formular',
  templateUrl: 'bw-12hj-standard-formular.component.html',
})
export class BW12hjStandardFormularComponent extends FormularComponentBase<BW12hjStandardFormular> {
  static createModel(formular: BWFormular) {
    return {
      zeugnistyp: BWZeugnistypComponent.createModel(formular),
      schueler: BWSchuelerComponent.createModel(formular),
      sozialverhalten: {
        label: 'Verhalten',
        textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
      },
      arbeitsverhalten: {
        label: formular.sozialLernArbeitsverhalten.arbeitsverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.arbeitsverhalten.text,
      },
      lernverhalten: {
        label: formular.sozialLernArbeitsverhalten.lernverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.lernverhalten.text,
      },
      leistungen:
        formular.zeugnis.zeugnissatz.klassenstufe === 2 && formular.zeugnis.zeugnissatz.halbjahr === 1
          ? BWLeistungenDeMaComponent.createModel(formular)
          : undefined,
      hinweise: {
        label: formular.hinweise.description.label,
        textField: formular.hinweise,
      },
      bemerkungen: {
        label: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      unterschrift: BWUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return BW12hjStandardFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<BW12hjStandardFormular>) {
    super(formularService);
  }
}
