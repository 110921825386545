<div>
  <p-checkbox [(ngModel)]="isHerkunftssprache" [binary]="true" label="Unterricht in der Herkunftssprache"></p-checkbox>
</div>
<div>
  <p-checkbox
    [(ngModel)]="isHerkunftssprache2"
    [binary]="true"
    [disabled]="!isHerkunftssprache"
    label="Unterricht in der 2. Herkunftssprache"
  ></p-checkbox>
</div>
<div *ngIf="formular.isKPMoeglich && !formular.isAbgang">
  <p-checkbox
    [(ngModel)]="formular.zeugnis.ausnahmeVerbal"
    [binary]="true"
    label="Ausnahme Verbalbeurteilung (z.B. Migrationshintergrund)"
  ></p-checkbox>
</div>
