import { Line, LineDescription } from './line';
import itiriri from 'itiriri';
import { ChunkedCloneWalker } from './chunked-clone-walker';
import { ContentProperties } from './content-properties';
import { CharPosition, NodeHelper, NodeWalker } from '@modules/dom';

export class Paragraph {
  public readonly lines: Line[];
  public readonly prevParagraph: Paragraph | null;
  public readonly top: number;
  public readonly height: number;
  public readonly start: number;
  public readonly length: number;

  private readonly className: string;
  private readonly paragraphElement: HTMLParagraphElement;

  constructor(
    lineDescriptions: LineDescription[],
    prevParagraph: Paragraph | null,
    className: string,
    isLast: boolean
  ) {
    this.prevParagraph = prevParagraph;
    this.className = className;
    this.paragraphElement = document.createElement('p');
    this.paragraphElement.className = className;
    this.top = this.prevParagraph == null ? 0 : this.prevParagraph.top + this.prevParagraph.height;
    this.start = this.prevParagraph == null ? 0 : this.prevParagraph.start + this.prevParagraph.length + 1;
    this.lines = lineDescriptions.reduce(
      (acc, desc, index, array) =>
        acc.concat(
          new Line(desc, acc.slice(-1)[0], this.start, this.top, index === array.length - 1, className, isLast)
        ),
      [] as Line[]
    );
    // let prevLine: LineElement | null = null;
    // for (const line of this.lines) {
    //   line.init(prevLine, this.start, this.top, line === this.lines[this.lines.length - 1]);
    //   prevLine = line;
    // }
    this.height = this.lines.map((l) => l.height).reduce((prev, curr) => prev + curr, 0);
    this.length =
      this.lines.map((l) => l.length).reduce((prev, curr) => prev + curr, 0) +
      (this.lines.length > 1 ? this.lines.length - 1 : 0);
  }

  public static from(
    paragraphElement: HTMLParagraphElement,
    prevParagraph: Paragraph | null,
    properties: ContentProperties,
    hostElement: HTMLElement,
    isLast: boolean
  ): Paragraph {
    let className = paragraphElement.className;
    if (className === '') className = properties.defaultClass;
    const fragment = NodeHelper.cloneContents(paragraphElement);
    const lineDescriptions: LineDescription[] = [];
    const walker = new NodeWalker(fragment);
    const measureElement = document.createElement('div');
    measureElement.className = className;
    measureElement.style.whiteSpace = 'pre';
    measureElement.style.position = 'absolute';
    hostElement.appendChild(measureElement);
    const heightMeasureElement = document.createElement('div');
    heightMeasureElement.className = className;
    heightMeasureElement.style.whiteSpace = 'pre';
    heightMeasureElement.style.position = 'absolute';
    hostElement.appendChild(heightMeasureElement);
    if (itiriri(walker.beforeCharacterPositions).first() == null) {
      fragment.normalize();
      this.firstLeaf(fragment).appendChild(document.createTextNode(''));
      lineDescriptions.push(this.createLine(fragment, heightMeasureElement));
    } else {
      const firstTwo = itiriri(walker.beforeCharacterPositions).take(2).toArray();
      if (firstTwo.length === 2 && firstTwo[0].char === '-' && firstTwo[1].char === ' ') {
        const bullet = NodeHelper.cloneContents({
          startNode: fragment,
          startOffset: 0,
          endNode: firstTwo[1].node,
          endOffset: firstTwo[1].nodeOffset + 1,
        });
        measureElement.appendChild(bullet);
        NodeHelper.replaceSpaceWithNbsp(measureElement); // &nbsp; und normales Space haben in unserem Arial-Font leicht unterschiedliche Breiten -> Messung muss auch mit &nbsp; erfolgen, da wir &nbsp; für die Darstellung und Caret-Position brauchen
        const bulletWidth = NodeHelper.getBoundingClientRect(measureElement).width;
        lineDescriptions.push(
          this.createLine(Paragraph.getFirstLine(fragment, properties.width, measureElement), heightMeasureElement)
        );
        while (itiriri(walker.beforeCharacterPositions).first() != null) {
          lineDescriptions.push(
            this.createLine(
              Paragraph.getFirstLine(fragment, properties.width, measureElement, bulletWidth),
              heightMeasureElement,
              bulletWidth
            )
          );
        }
      } else {
        while (itiriri(walker.beforeCharacterPositions).first() != null) {
          lineDescriptions.push(
            this.createLine(Paragraph.getFirstLine(fragment, properties.width, measureElement), heightMeasureElement)
          );
        }
      }
    }
    hostElement.removeChild(measureElement);
    hostElement.removeChild(heightMeasureElement);
    return new Paragraph(lineDescriptions, prevParagraph, className, isLast);
  }

  public static getFirstLine(
    node: DocumentFragment,
    width: number,
    measureElement: HTMLElement,
    bulletWidth: number = 0
  ): DocumentFragment {
    const walker = new ChunkedCloneWalker(node, measureElement);
    const it = walker.beforeCharacterPositions[Symbol.iterator]();
    let result = it.next();
    let fittingPosition: CharPosition | null = null;
    let isFirstWord = !result.done && result.value.position.char !== ' ';
    while (!result.done && result.value.position.char === ' ') result = it.next();
    fittingPosition = result.value?.position;
    do {
      while (!result.done && result.value.position.char !== ' ') result = it.next();
      NodeHelper.replaceSpaceWithNbsp(measureElement); // &nbsp; und normales Space haben in unserem Arial-Font leicht unterschiedliche Breiten -> Messung muss auch mit &nbsp; erfolgen, da wir &nbsp; für die Darstellung und Caret-Position brauchen
      const rect = result.done
        ? NodeHelper.getBoundingClientRect(measureElement)
        : NodeHelper.getBoundingClientRect({
            startNode: measureElement,
            startOffset: 0,
            endNode: result.value.positionClone.node,
            endOffset: result.value.positionClone.nodeOffset,
          });
      if (isFirstWord || bulletWidth + rect.width < width) {
        isFirstWord = false;
        while (!result.done && result.value.position.char === ' ') result = it.next();
        fittingPosition = result.value?.position;
      } else {
        break;
      }
    } while (!result.done);
    const contents =
      fittingPosition == null
        ? NodeHelper.extractContents(node)
        : NodeHelper.extractContents({
            startNode: node,
            startOffset: 0,
            endNode: fittingPosition.node,
            endOffset: fittingPosition.nodeOffset /*-1*/,
          });
    if (fittingPosition != null) {
      const first = itiriri(new NodeWalker(contents).beforeCharacterPositionsReverse).first();
      if (first != null && first.char === ' ') {
        first.node.textContent = first.node.textContent!.slice(0, -1);
      }
    }
    return contents;
  }

  // public cloneElement() {
  //   const element = this.paragraphElement.cloneNode();
  //   element.appendChild(NodeHelper.join(
  //     document.createTextNode(' '),
  //     ...this.lines.map((line) => line.cloneFragment())
  //   ));
  //   return element;
  // }
  private static createLine(
    fragment: DocumentFragment,
    heightMeasureElement: HTMLElement,
    bulletWidth: number = 0
  ): LineDescription {
    NodeHelper.empty(heightMeasureElement);
    if (fragment.textContent!.length > 0) heightMeasureElement.appendChild(fragment.cloneNode(true));
    else heightMeasureElement.appendChild(document.createTextNode('x'));
    return {
      fragment,
      height: heightMeasureElement.getBoundingClientRect().height,
      bulletWidth,
    };
  }

  private static firstLeaf(node: Node): Node {
    if (node.firstChild == null) return node;
    else return this.firstLeaf(node.firstChild);
  }
}
