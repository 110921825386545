import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Formular } from '@modules/formular/formular';
import { FormularFactory } from '@modules/formular/formular-factory';
import { Guid } from 'guid-typescript';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { Subscription } from 'rxjs';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { StringHelper } from '../../../modules/dom/string-helper';
import { WorkspaceService } from '../../services/workspace.service';

@Component({
  selector: 'fz-noteneingabeliste',
  templateUrl: './noteneingabeliste.component.html',
})
export class NoteneingabelisteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('dropdowns') dropdowns?: QueryList<Dropdown>;
  @ViewChild('tableDiv') tableDiv?: ElementRef;
  cols: { field: string; header: string }[] = [];
  data: any[] = [];
  stateOptions: { label: string; value: string }[] = [];
  inputMode: string = 'row';
  notenform: UntypedFormGroup;
  calculatedHeight: string = '10px';
  subscriptions: Subscription[] = [];

  constructor(public workspaceService: WorkspaceService) {
    this.notenform = new UntypedFormGroup({});
  }
  ngAfterViewInit(): void {
    this.getTableHeight();
  }

  getTableHeight() {
    Promise.resolve(null).then(
      () => (this.calculatedHeight = window.innerHeight - (this.tableDiv?.nativeElement.offsetTop ?? 0) + 'px')
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.getTableHeight();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.workspaceService.selectedZeugnissatzChange.subscribe(() => this.initTable()),
      this.workspaceService.dataLoadedBehaviour.subscribe(() => {
        this.initTable();
      })
    );
    this.stateOptions = [
      { label: 'Zeilenweise', value: 'row' },
      { label: 'Spaltenweise', value: 'col' },
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  getFachLabel(fachKey: string, bereichKey: string, formular: Formular): string {
    let label = ((formular as any)[fachKey] as FormularFach).bereiche.find((b) => b.key === bereichKey)?.description
      .labelShort;

    if (!label) label = ((formular as any)[fachKey] as FormularFach).description.labelShort;

    if (!label) return bereichKey.charAt(0).toUpperCase() + bereichKey.substring(1, 3);

    return label;
  }

  initTable(): void {
    this.data = [];

    this.cols = [{ field: 'nameVorname', header: 'Name, Vorname' }];

    const allZeugnisse = this.workspaceService.selectedZeugnisse.filter((z) => z.schueler.isAktiv);
    const overview = FormularFactory.createOverview(allZeugnisse);

    overview.columns.forEach((zeugnisFeld) => {
      if (zeugnisFeld.type === NoteField) {
        // if ((zeugnisFeld.description as any).labelKey != null) {
        //   // noch was machen
        //   const additionalField = overview.columns.find((c) => c.key === (zeugnisFeld.description as any).labelKey);
        //   if (additionalField)
        //     this.cols.push({ field: additionalField.key, header: additionalField.description.labelShort });
        // }
        this.cols.push({ field: zeugnisFeld.key, header: zeugnisFeld.description.labelShort });
      }
    });

    overview.rows.forEach((zeugnis) => {
      const obj = {};
      Object.assign(obj, {
        nameVorname: StringHelper.removeSpecialCharacters(
          (zeugnis['schuelerName']?.field?.value ?? '') + ', ' + (zeugnis['schuelerVorname']?.field?.value ?? '')
        ),
      });
      Object.assign(obj, { zeugnis });

      this.cols.forEach((col) => {
        const data = zeugnis[col.field];
        if (data) {
          Object.assign(obj, { [col.field]: data.field?.value });
          const formControlName = Guid.create().toString() + '_' + col.field;
          const formControlNameSuffix = Guid.create().toString() + '_' + col.field + '_suffix';
          this.notenform.addControl(formControlName, new UntypedFormControl(data.field.value));
          // console.log(data.field);
          this.notenform.addControl(formControlNameSuffix, new UntypedFormControl((data.field as NoteField).suffix));
          Object.assign(obj, { [col.field + '_options']: this.getOptions(data.field as NoteField) });
          Object.assign(obj, { [col.field + '_hasSuffix']: (data.field as NoteField).hasSuffix });
          Object.assign(obj, { [col.field + '_value']: (data.field as NoteField).value });
          Object.assign(obj, { [col.field + '_suffix']: this.getOptionsSuffix(data.field as NoteField) });
          Object.assign(obj, { [col.field + '_name']: formControlName });
          Object.assign(obj, { [col.field + '_name_suffix']: formControlNameSuffix });
          Object.assign(obj, { [col.field + '_visible']: true });
        }
      });
      this.data.push(obj);
    });
    this.data.sort((a, b) => String(a.nameVorname).localeCompare(String(b.nameVorname), 'de-DE'));
  }

  inputChange(event: DropdownChangeEvent, dataRow: any, fieldName: string, id: string, hasSuffix: boolean): void {
    console.log('inputChange', event, dataRow, fieldName, id, hasSuffix);
    // find dropdown
    if (this.dropdowns) {
      const currentDropdown = this.dropdowns.find((d) => d.inputId === id);
      if (currentDropdown) {
        // const currentValue = currentDropdown.value;

        dataRow.zeugnis[fieldName].field.value = event.value;
      }

      this.goToNext(id, hasSuffix);
    }
  }

  goToNext(id: string, hasSuffix: boolean) {
    if (this.dropdowns == null) return;
    let found = false;
    let cnt = 0;
    while (!found && cnt < 1000) {
      id = this.getNextId(id, hasSuffix);
      const el = this.dropdowns.find((d) => d.inputId === id);
      if (el) {
        setTimeout(() => {
          el.focus();
          // el.applyFocus();
        }, 100);
        found = true;
      }
      cnt++;
    }
  }

  inputChangeSuffix(event: DropdownChangeEvent, dataRow: any, fieldName: string, id: string): void {
    // find dropdown
    if (this.dropdowns) {
      const currentDropdown = this.dropdowns.find((d) => d.inputId === id);
      if (currentDropdown) {
        dataRow.zeugnis[fieldName].field.suffix = event.value;
      }
    }
    this.goToNext(id, false);
  }

  getOptions(field: NoteField) {
    return field.candidates.map((c) => {
      return { label: c.displayString, value: c.value };
    });
  }

  getOptionsSuffix(field: NoteField) {
    return field.suffixCandidates.map((c) => {
      return { label: c.displayString, value: c.value };
    });
  }

  getValue(dataRow: any, fach: string, bereich: string): string {
    const currentFormular = dataRow.formular as Formular;

    const currentNoteField = currentFormular.noteFields.find(
      (nf) => nf.bereich.key === bereich && nf.description.fachKey === fach
    );
    return currentNoteField?.text ?? '';
  }

  getNextId(id: string, hasSuffix: boolean): string {
    const splittedId = id.split('_');
    // ist das aktuelle ein suffix?
    const isSuffix = id.includes('_suffix');

    if (isSuffix || !hasSuffix) {
      return this.getNextIdField(splittedId);
    } else {
      return this.getNextIdSuffix(splittedId);
    }
  }

  getNextIdSuffix(splittedId: string[]): string {
    return splittedId.join('_') + '_suffix';
  }

  getNextIdField(splittedId: string[]): string {
    let nextId = '';
    let nextRow = 0;
    let nextCol = 0;
    // todo: am ende wieder von vorn
    if (this.inputMode === 'row') {
      nextCol = (+splittedId[1] + 1) % (this.cols.length - 1);
      nextRow = +splittedId[0];
      if (nextCol < +splittedId[1]) {
        nextRow = (nextRow + 1) % this.workspaceService.selectedZeugnisse.length;
      }
    } else {
      nextCol = +splittedId[1];
      nextRow = (+splittedId[0] + 1) % this.workspaceService.selectedZeugnisse.length;
      if (nextRow < +splittedId[0]) {
        nextCol = (nextCol + 1) % (this.cols.length - 1);
      }
    }

    nextId = nextRow + '_' + nextCol;
    return nextId;
  }
}
