import { UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { NotificationService } from '../../services/notification/notification.service';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { Subscription } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  standalone: true,
  selector: 'fz-select-kundennummer',
  imports: [CommonModule, ReactiveFormsModule, ButtonModule, PanelModule, InputTextModule],
  templateUrl: './select-kundennummer.component.html',
  styleUrls: ['./select-kundennummer.component.scss'],
})
export class SelectKundennummerComponent implements OnInit, OnDestroy {
  @Input() header: TemplateRef<any> | undefined;
  @Input() showCancel = true;
  @Input() showDemoLizenzen = false;
  @Output() kundennummerChecked = new EventEmitter<void>();
  @Output() restart = new EventEmitter<void>();

  form: UntypedFormGroup;
  loading: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    public authService: FlinkyAuthService,
    private notificationService: NotificationService
  ) {
    this.form = new UntypedFormGroup({
      kundennummer: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.updateKundennummer();
    this.subscriptions.push(this.authService.lizenzChanged.subscribe(() => this.updateKundennummer()));
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) subscription.unsubscribe();
  }

  private updateKundennummer() {
    if (this.authService.lizenz != null) this.form.patchValue({ kundennummer: this.authService.lizenz.kundennummer });
  }

  async loadLicense() {
    this.form.value.kundennummer = this.form.value.kundennummer.trim();
    if (this.form.value.kundennummer !== '') {
      try {
        this.loading = true;
        await this.authService.loadLizenz(this.form.value.kundennummer);
        this.authService.lizenz = this.authService.lizenzen.find(
          (l) => l.kundennummer.toLowerCase() === this.form.value.kundennummer.toLowerCase()
        );

        this.kundennummerChecked.next();
      } catch {
        this.notificationService.showPermanentError(
          'Die Lizenz konnte nicht gefunden werden. Bitte überprüfen Sie die Schreibweise und versuchen es erneut.',
          'Lizenz nicht gefunden'
        );
      } finally {
        this.loading = false;
      }
    }
  }
  setKundennummer(kundenunnumer: string) {
    this.form.patchValue({ kundennummer: kundenunnumer });
  }

  firstStep(): void {
    this.restart.next();
  }
}
