import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { NIFormular } from './ni-formular';
import { NIZeugnisFormular } from './ni-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { NIZeugnistypComponent } from '../projectables/ni-zeugnistyp.component';
import { NIZeugnissatzComponent } from '../projectables/ni-zeugnissatz.component';
import { NISchuelerComponent } from '../projectables/ni-schueler.component';
import { NIVersaeumnisComponent } from '../projectables/ni-versaeumnis.component';
import { NIUnterschriftComponent } from '../projectables/ni-unterschrift.component';

export class NI1234hjFreitextFormular extends NIZeugnisFormular {
  get viewType(): FormularViewType {
    return NI1234hjFreitextFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    return noteFields;
  }
}

@Component({
  selector: 'fz-ni-1234hj-freitext-formular',
  templateUrl: 'ni-1234hj-freitext-formular.component.html',
})
export class NI1234hjFreitextFormularComponent extends FormularComponentBase<NI1234hjFreitextFormular> {
  static createModel(formular: NIFormular) {
    return {
      zeugnistyp: NIZeugnistypComponent.createModel(formular),
      zeugnissatz: NIZeugnissatzComponent.createModel(formular),
      schueler: NISchuelerComponent.createModel(formular),
      versaeumnis: NIVersaeumnisComponent.createModel(formular),
      bericht: {
        label: formular.bericht.gesamt.description.label,
        textField: formular.bericht.gesamt.text,
      },
      lernArbeitsverhalten: {
        label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
      },
      sozialverhalten: {
        label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
      },
      bemerkungen: {
        label: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      unterschrift: NIUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return NI1234hjFreitextFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<NI1234hjFreitextFormular>) {
    super(formularService);
  }
}
