import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PanelDecoratorBase } from '@modules/projectables/panel.component';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { FormularFach } from '../../models/formular-fach';

@Component({
  selector: 'fz-fach-list-item',
  templateUrl: 'fach-list-item.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, FachListItemComponent)],
})
export class FachListItemComponent extends PanelDecoratorBase {
  static createModel(fach: FormularFach) {
    return {
      labelField: fach.gesamt.label,
      noteField: fach.gesamt.note,
      label: fach.gesamt.labelNbsp,
    };
  }

  @Input() model: ReturnType<typeof FachListItemComponent.createModel> | undefined;
  @Output() delete = new EventEmitter<void>();
  deleteFach(): void {
    this.delete.emit();
  }
}
