import { SystemActionsService } from './shared/components/system-actions/system-actions.service';
import { VersionService } from './shared/services/version.service';
import { WorkspaceService } from './shared/services';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Component, OnInit, NgZone, Renderer2, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FlinkyAuthService } from './shared/services/auth/flinky-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './shared/services/notification/notification.service';
import { lastValueFrom } from 'rxjs';
import { FzeGuidedTourService } from './shared/services/fze-guided-tour.service';
import { environment } from '../environments/environment';
import { JahrgangService } from './shared/services/jahrgang.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loadingNews = true;
  eventRemovers: (() => void)[] = [];
  isProduction = environment.production;

  constructor(
    private flinkyAuth: FlinkyAuthService,
    private router: Router,
    private messageService: MessageService,
    private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private translate: TranslateService,
    private workspaceService: WorkspaceService,
    private notify: NotificationService,
    private versionService: VersionService,
    private notificationService: NotificationService,
    public systemActionsService: SystemActionsService,
    private fzeTour: FzeGuidedTourService,
    private zone: NgZone,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private jahrgangService: JahrgangService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('de');
    this.fzeTour.init();
  }
  // @HostListener('wheel', ['$event']) // Registrierung erfolgt in OnInit
  onMouseWheel(event: WheelEvent) {
    if (event.ctrlKey) {
      if (event.deltaY < 0) {
        this.workspaceService.zoomed.next(3);
      } else {
        this.workspaceService.zoomed.next(-3);
      }
      event.preventDefault();
      this.changeDetector.detectChanges();
      return false;
    }

    return true;
  }

  private async setLang() {
    this.primengConfig.setTranslation(await lastValueFrom(this.translate.get('primeng')));
  }

  async ngOnInit() {
    this.zone.runOutsideAngular(() => {
      this.eventRemovers.push(
        this.renderer.listen(this.elementRef.nativeElement, 'wheel', (e) => {
          this.onMouseWheel(e);
        })
      );
    });
    this.primengConfig.ripple = true;
    await this.setLang();
    const user = this.flinkyAuth.getUser();
    if (user != null) {
      if (await this.jahrgangService.throwsCryptographicException()) {
        this.router.navigate(['/restore-user-backup'], { queryParams: { throwsCryptographicException: true } });
      } else {
        this.flinkyAuth.loginStateChanged.next(true);
      }
    }

    const res = await lastValueFrom(this.http.get<boolean>('/api/backup/checkWriteAccess'));
    if (res !== true) {
      this.notify.showPermanentInfo(
        'Flinky Zeugnis Evo benötigt Zugriff auf Ihren Dokumente-Ordner, um automatisch Sicherungen erstellen zu können. Unter macOS finden Sie diese Option unter "Sicherheit" in den macOS-Systemeinstellungen und dort unter "Dateien und Ordner". Setzen Sie an dieser Stelle das Häkchen vor dem Eintrag "Flinky Zeugnis Evo". Nähere Infos finden Sie in den FAQs auf unserer Homepage bzw. beim Apple Support. Unter Windows verhindert möglicherweise ein Virenscanner den Zugriff auf Ihren Dokumente-Ordner. Fragen Sie Ihren Administrator oder den Hersteller der Antivirenlösung, um eine Ausnahme zu erstellen.',
        'Wichtig - Ihr Handeln ist notwendig!'
      );
    }
  }

  ngOnDestroy(): void {
    for (const eventRemover of this.eventRemovers) {
      eventRemover();
    }
  }

  isLoggedInPage(): boolean {
    if (
      this.router.url.startsWith('/login-form') ||
      this.router.url.startsWith('/register-form') ||
      this.router.url === '/recover' ||
      this.router.url === '/download-license-public'
    ) {
      return false;
    }
    return true;
  }
  onConfirm(): void {
    this.messageService.clear('errorDlg');
    this.notificationService.removeMessages();
  }

  iframeLoad(): void {
    this.loadingNews = false;
  }
}
