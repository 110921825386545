import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { SLPanelDecoratorBase } from './sl-panel-decorator-base';
import { SLFormular } from '../formulare/sl-formular';

@Component({
  selector: 'fz-sl-versetzungsvermerk',
  templateUrl: 'sl-versetzungsvermerk.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, SLVersetzungsvermerkComponent)],
})
export class SLVersetzungsvermerkComponent extends SLPanelDecoratorBase {
  static createModel(formular: SLFormular) {
    return {
      selectField: formular.versetzungsvermerkTyp,
      dateField:
        formular.versetzungsvermerkTyp.value === 'Versetzt' ? formular.zeugnissatz.klassenkonferenzdatum : undefined,
      textField: formular.versetzungsvermerkTyp.value === 'FreierText' ? formular.versetzungsvermerkText : undefined,
      infoField: formular.versetzungsvermerkInfo,
    };
  }

  @Input() model: ReturnType<typeof SLVersetzungsvermerkComponent.createModel> | undefined;
}
