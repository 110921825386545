import { Type } from '@angular/core';
import { BorderDirection } from '@modules/dom/border-direction';
import { Geschlecht } from '../../models/enums/geschlecht';
import { VersetzungsvermerkTyp } from '../../models/enums/versetzungsvermerk-typ';
import { DateField } from '../../models/fields/date-field';
import { DropdownField } from '../../models/fields/dropdown-field';
import { IntelliField } from '../../models/fields/intelli-field';
import { NoteField } from '../../models/fields/note-field';
import { SchuelerTextField } from '../../models/fields/schueler-text-field';
import { TextField } from '../../models/fields/text-field';
import { ValueField } from '../../models/fields/value-field';
import { BereichDescription, FormularBereich } from '../../models/formular-bereich';
import { FormularFach } from '../../models/formular-fach';
import BereichKey from '../../models/generated/bereich-key';
import FachKey from '../../models/generated/fach-key';
import { Zeugniskopflizenz } from '../../models/zeugniskopflizenz';
import { Schueler } from '../../models/schueler';
import { Zeugnis } from '../../models/zeugnis';
import { Zeugnissatz } from '../../models/zeugnissatz';
import { FormularFactory } from './formular-factory';
import { FormularViewModel } from '../../models/formular-view-model';
import { Field } from '../../models/fields/field';
import { FormularSummary } from './formular-summary';
import { FormularOverviewCell, FormularOverviewRow } from './formular-overview';
import { FormularComponentBase } from './formular-component-base';

export class SchuelerViewModel {
  geburtsdatum: DateField<Schueler>;
  geburtsort: TextField<Schueler>;
  geschlecht = new DropdownField<Schueler, Geschlecht | null>(
    {
      label: 'Geschlecht',
      labelShort: 'G',
      required: false,
      property: 'geschlecht',
      keyPrefix: 'schueler',
      candidates: [
        { value: null, displayStringShort: String.fromCharCode(160), content: '<Gesch.>' },
        { value: 'm', displayStringShort: 'm', content: '(männl)' },
        { value: 'w', displayStringShort: 'w', content: '(weibl)' },
        { value: 'divers', displayStringShort: 'div', content: '(divers)' },
      ],
      ignoreReset: true,
    },
    { object: this.schueler }
  );

  constructor(private schueler: Schueler) {
    this.geburtsdatum = new DateField(
      {
        label: 'Geburtsdatum',
        labelShort: 'GebDat',
        property: 'geburtsdatum',
        keyPrefix: 'schueler',
        ignoreReset: true,
      },
      { object: schueler }
    );
    this.geburtsort = new TextField(
      {
        label: 'Geburtsort',
        labelShort: 'GebOrt',
        property: 'geburtsort',
        keyPrefix: 'schueler',
        ignoreReset: true,
      },
      { object: schueler }
    );
  }

  get personalPronomen(): string {
    if (this.schueler.geschlecht === 'm') return 'Er';
    if (this.schueler.geschlecht === 'w') return 'Sie';
    return 'Er/Sie';
  }

  get label(): string {
    if (this.schueler.geschlecht === 'm') return 'Schüler';
    if (this.schueler.geschlecht === 'w') return 'Schülerin';
    return 'Schüler(in)';
  }
}

export class ZeugnissatzViewModel {
  klassenbezeichnung: TextField<Zeugnissatz>;
  schulleitung: TextField<Zeugnissatz>;
  klassenleitung: TextField<Zeugnissatz>;
  zeugnisausgabedatum: DateField<Zeugnissatz>;
  klassenkonferenzdatum: DateField<Zeugnissatz>;
  protokollDatum: DateField<Zeugnissatz>;
  sprachenportfolioSprache: ValueField<Zeugnissatz, string | null>;
  fremdspracheBezeichnung: TextField<Zeugnissatz>;

  constructor(private zeugnissatz: Zeugnissatz) {
    this.klassenbezeichnung = new TextField(
      {
        label: 'Klassenbezeichnung',
        labelShort: 'KlBez',
        property: 'klassenbezeichnung',
        keyPrefix: 'zeugnissatz',
        ignoreReset: true,
      },
      { object: zeugnissatz }
    );
    this.schulleitung = new TextField(
      {
        label: 'Schulleitung',
        labelShort: 'SL',
        property: 'schulleitung',
        keyPrefix: 'zeugnissatz',
        ignoreReset: true,
      },
      { object: zeugnissatz }
    );
    this.klassenleitung = new TextField(
      {
        label: 'Klassenleitung',
        labelShort: 'KL',
        property: 'klassenleitung',
        keyPrefix: 'zeugnissatz',
        required: false,
        ignoreReset: true,
      },
      { object: zeugnissatz }
    );
    this.zeugnisausgabedatum = new DateField(
      {
        label: 'Zeugnisausgabedatum',
        labelShort: 'ZAusgDat',
        property: 'zeugnisausgabedatum',
        keyPrefix: 'zeugnissatz',
        ignoreReset: true,
      },
      { object: zeugnissatz }
    );
    this.klassenkonferenzdatum = new DateField(
      {
        label: 'Klassenkonferenzdatum',
        labelShort: 'KlKonfDat',
        property: 'klassenkonferenzdatum',
        keyPrefix: 'zeugnissatz',
        ignoreReset: true,
      },
      { object: zeugnissatz }
    );
    this.protokollDatum = new DateField(
      {
        label: 'Protokolldatum',
        labelShort: 'ProtDat',
        property: 'protokollDatum',
        keyPrefix: 'zeugnissatz',
        ignoreReset: true,
      },
      { object: zeugnissatz }
    );
    this.sprachenportfolioSprache = new ValueField(
      {
        label: 'Sprachenportfoliosprache',
        labelShort: 'SprPortSpr',
        property: 'sprachenportfolioSprache',
        keyPrefix: 'zeugnissatz',
        ignoreReset: true,
      },
      { object: zeugnissatz }
    );
    this.fremdspracheBezeichnung = new TextField(
      {
        label: 'Fremdsprachenbezeichnung',
        labelShort: 'FrmdSprBez',
        property: 'fremdspracheBezeichnung',
        keyPrefix: 'zeugnissatz',
        ignoreReset: true,
      },
      { object: zeugnissatz }
    );
  }

  get klassenstufe(): number {
    return this.zeugnissatz.klassenstufe;
  }
  get klassenstufeLabel(): string {
    return this.zeugnissatz?.klassenstufe < 3 && this.zeugnissatz?.jahrgang?.bundesland === 'SL'
      ? 'Schuleingangsphase 1/2'
      : `Klassenstufe ${this.zeugnissatz.klassenstufe}`;
  }

  get zeugnisTypLabel(): string {
    return this.zeugnissatz?.halbjahr === 0 ? 'Halbjahreszeugnis' : 'Jahreszeugnis';
  }
  get zeugnisTypLabelGenetiv(): string {
    return this.zeugnissatz.halbjahr === 0 ? 'Halbjahreszeugnisses' : 'Jahreszeugnisses';
  }

  get halbjahrLabel(): string {
    return `${this.zeugnissatz.halbjahr + 1}. Halbjahr`;
  }

  get schuljahrLabel(): string {
    return `${this.zeugnissatz.schuljahr}/${this.zeugnissatz.schuljahr + 1}`;
  }

  get schuljahrPlus1Label(): string {
    return `${this.zeugnissatz.schuljahr + 1}/${this.zeugnissatz.schuljahr + 2}`;
  }

  get schuljahr(): number {
    return this.zeugnissatz.schuljahr;
  }

  get zeugniskopflizenz(): Zeugniskopflizenz {
    return this.zeugnissatz.zeugniskopflizenz;
  }

  get is15(): boolean {
    return this.zeugnissatz.is15;
  }
  set is15(value: boolean) {
    this.zeugnissatz.is15 = value;
  }
}

export class SozialLernArbeitsverhalten extends FormularFach {
  sozialverhalten: FormularBereich;
  lernArbeitsverhalten: FormularBereich;
  constructor(
    formular: Formular,
    descriptionGesamt: BereichDescription,
    descriptionSozialverhalten: BereichDescription,
    descriptionLernArbeitsverhalten: BereichDescription
  ) {
    super(formular, FachKey.sozialLernArbeitsverhalten, descriptionGesamt);
    this.sozialverhalten = this.add(
      new FormularBereich(formular, this, BereichKey.sozialverhalten, descriptionSozialverhalten)
    );
    this.lernArbeitsverhalten = this.add(
      new FormularBereich(formular, this, BereichKey.lernArbeitsverhalten, descriptionLernArbeitsverhalten)
    );
  }
}

export type FormularViewType = Type<FormularComponentBase<Formular>> & {
  createModel(formular: Formular): FormularViewModel;
};

export abstract class Formular {
  zeugnissatz: ZeugnissatzViewModel;
  schueler: SchuelerViewModel;
  schuelerGeburtsdatum = new Date(2014, 1, 1);
  schuelerName: SchuelerTextField;
  schuelerVorname: SchuelerTextField;
  bemerkungen: IntelliField<Zeugnis>;
  zeugnisausgabedatum: DateField<Zeugnis>;
  versetzungsvermerkTyp: ValueField<Zeugnis, VersetzungsvermerkTyp | null>;
  versetzungsvermerkText: TextField<Zeugnis>;
  headerLabels: string[][];
  borderDirection = BorderDirection;

  constructor(public zeugnis: Zeugnis) {
    this.zeugnissatz = new ZeugnissatzViewModel(zeugnis.zeugnissatz);
    this.schueler = new SchuelerViewModel(zeugnis.schueler);
    this.schuelerName = new SchuelerTextField(
      { label: 'Name', labelShort: 'Name', property: 'schuelerName', schuelerProperty: 'name', ignoreReset: true },
      { object: zeugnis }
    );
    this.schuelerVorname = new SchuelerTextField(
      {
        label: 'Vorname',
        labelShort: 'Vorn',
        property: 'schuelerVorname',
        schuelerProperty: 'vorname',
        ignoreReset: true,
      },
      { object: zeugnis }
    );
    this.bemerkungen = new IntelliField(
      { label: 'Bemerkungen', labelShort: 'Bem', property: 'bemerkungen', textbausteinKey: 'Bemerkungen' },
      { object: zeugnis }
    );
    this.zeugnisausgabedatum = new DateField(
      { label: 'Zeugnisausgabedatum', labelShort: 'ZAusgDat', property: 'zeugnisausgabedatum', ignoreReset: true },
      { object: zeugnis }
    );
    this.versetzungsvermerkTyp = new ValueField(
      { label: 'Versetzungsvermerktyp', labelShort: 'VersVermTyp', property: 'versetzungsvermerkTyp' },
      { object: zeugnis }
    );
    this.versetzungsvermerkText = new TextField(
      { label: 'Versetzungsvermerktext', labelShort: 'VersVermTxt', property: 'versetzungsvermerkText' },
      { object: zeugnis }
    );
    this.headerLabels = this.zeugnis.zeugnissatz.raster.headers?.map((h) => h.split('\n')) ?? [];
    if (this.headerLabels.length === 0) {
      this.headerLabels = FormularFactory.getFormularsatz(this.zeugnis).getDefaultKPHeaderLabels(
        this.zeugnis.zeugnissatz
      );
    }
  }

  get showBorder() {
    return !this.zeugnis.zeugnissatz.zeugniskopflizenz.versteckeTextRahmen;
  }

  get verbalWidthWithBorder() {
    return 668;
  }

  get verbalWidthWithoutBorder() {
    return 680;
  }

  get isAbgang(): boolean {
    return this.zeugnis.zeugnisTyp === 'Abgang';
  }
  set isAbgang(value: boolean) {
    if (this.isAbgang !== value) {
      this.zeugnis.zeugnisTyp = value ? 'Abgang' : null;
    }
  }

  get fontSizeFactor(): number | null {
    return this.zeugnis.fontSizeFactor;
  }
  set fontSizeFactor(value: number | null) {
    this.zeugnis.fontSizeFactor = value;
  }

  abstract get viewType(): FormularViewType;

  get areKPItemsOptional(): boolean {
    return false;
  }
  get warnKPItemsIfOptional(): boolean {
    return false;
  }

  get noteFields(): NoteField[] {
    return [];
  }

  get isValid(): boolean {
    if (this.viewType == null) return true;
    const fields = this.getFieldsForViewModel(this.viewType.createModel(this));
    return fields.every((f) => f.error == null);
  }

  get summary(): FormularSummary | undefined {
    if (this.viewType == null) return undefined;
    const viewModel = this.viewType.createModel(this);
    if (viewModel == null) return undefined;
    const fields = this.getFieldsForViewModel(viewModel);
    const errors = fields.filter((f) => f.error != null);
    const warnings = fields.filter((f) => f.warning != null);
    return {
      errorCount: errors.length,
      warningCount: warnings.length,
    };
  }

  getOverviewRow(): FormularOverviewRow {
    if (this.viewType == null) return {};
    return this.getCells(this.viewType.createModel(this)).reduce(
      (obj, cell) => ({ ...obj, [cell.field.key]: cell }),
      {}
    );
  }

  reset() {
    for (const field of this.getFieldsForViewModel(this.viewType.createModel(this))) {
      if (field.description.ignoreReset !== true) field.reset();
    }
  }

  resetNoten() {
    for (const field of this.getFieldsForViewModel(this.viewType.createModel(this))) {
      if (field.description.ignoreReset !== true && field instanceof NoteField) field.reset();
    }
  }

  getFields() {
    return this.getFieldsForViewModel(this.viewType.createModel(this));
  }

  private getFieldsForViewModel(viewModel: FormularViewModel): Field[] {
    if (viewModel instanceof Field) return [viewModel];
    if (Array.isArray(viewModel)) return viewModel.flatMap((item) => this.getFieldsForViewModel(item));
    if (typeof viewModel === 'object')
      return Object.values(viewModel).flatMap((item) => this.getFieldsForViewModel(item));
    return [];
  }

  private getCells(viewModel: FormularViewModel, label: string | undefined = undefined): FormularOverviewCell[] {
    if (viewModel instanceof Field) return [{ field: viewModel, formularLabel: label ?? viewModel.description.label }];
    if (Array.isArray(viewModel)) return viewModel.flatMap((item) => this.getCells(item, label));
    if (typeof viewModel === 'object') {
      const innerLabel: string | undefined = (viewModel as any)?.header?.label ?? (viewModel as any)?.label;
      return Object.values(viewModel).flatMap((item) => this.getCells(item, innerLabel ?? label));
    }
    return [];
  }
}
