import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BlockModule } from '@modules/blocks/block.module';
import { ProjectableModule } from '@modules/projectables/projectable.module';
import { RichTextModule } from '@modules/rich-text/rich-text.module';
import { SharedModule } from '@modules/shared/shared.module';
import { FormularItemDirective } from './formular-item.directive';
import { FormularViewerComponent } from './formular-viewer.component';
import { FormularComponent } from './formular.component';
import { HeaderFooterDirective } from './header-footer.directive';
import { NullFormularComponent } from './null-formular.component';
import { PageComponent } from './page.component';
import { UnavailableComponent } from './unavailable.component';
import { FormularItemComponent } from './formular-item.component';
import { KPModule } from '@modules/kp/kp.module';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { PageMarginComponent } from './page-margin.component';
import { PanelModule } from 'primeng/panel';
import { ApplyKPComponent } from './apply-kp.component';
import { MessagesModule } from 'primeng/messages';
import { FormularSidebarComponent } from './formular-sidebar/formular-sidebar.component';
import { FormularLockComponent } from './formular-lock/formular-lock.component';
import { HelpInfoModule } from '../../shared/components/help-info/help-info.module';
import { KorrekturComponent } from './korrektur/korrektur.component';
import { ErsetzenComponent } from './ersetzen/ersetzen.component';

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    ProjectableModule,
    BlockModule,
    RichTextModule,
    KPModule,
    SliderModule,
    FormsModule,
    DialogModule,
    CheckboxModule,
    ButtonModule,
    InputNumberModule,
    PanelModule,
    MessagesModule,
    HelpInfoModule,
    KorrekturComponent,
    ErsetzenComponent,
  ],
  exports: [
    SharedModule,
    ProjectableModule,
    BlockModule,
    RichTextModule,
    KPModule,
    FormularComponent,
    UnavailableComponent,
    FormularViewerComponent,
    FormularItemDirective,
    ApplyKPComponent,
    FormularLockComponent,
  ],
  declarations: [
    FormularComponent,
    FormularItemDirective,
    UnavailableComponent,
    FormularViewerComponent,
    PageComponent,
    NullFormularComponent,
    HeaderFooterDirective,
    FormularItemComponent,
    PageMarginComponent,
    ApplyKPComponent,
    FormularSidebarComponent,
    FormularLockComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormularModule {}
