import {
  Directive,
  ElementRef,
  OnDestroy,
  Optional,
  QueryList,
  SkipSelf,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FocusableDirective } from '@modules/shared/focusable.directive';
import { InterfaceProvider } from '@modules/shared/interface-provider';
import { PortalDirective } from '@modules/shared/portal.directive';

export interface Projectable {
  sourceElement: HTMLElement;
  parent: Projectable | undefined;
  children: Projectable[];
  content?: PortalDirective;
  backDeco?: PortalDirective;
  frontDeco?: PortalDirective;
  height?: number;
  hasFocus: boolean;
  projectPosition(parentRect: DOMRect): void;
  project(): void;
  log(indent?: number): void;
}

export class ProjectableProvider implements InterfaceProvider<Projectable> {
  constructor(public provided: Projectable) {}
}

@Directive({
  //  providers: [provideInterfaceBy(ProjectableProvider, ProjectableBase)],
})
export abstract class ProjectableBase implements OnDestroy {
  @ViewChild('content') contentDirective?: PortalDirective;
  @ViewChild('backDeco') backDecoDirective?: PortalDirective;
  @ViewChild('frontDeco') frontDecoDirective?: PortalDirective;
  @ViewChildren(FocusableDirective) focusables = new QueryList<FocusableDirective>();
  children: Projectable[] = [];

  constructor(
    private elementRef: ElementRef,
    @SkipSelf() @Optional() private parentProvider: ProjectableProvider | null
  ) {
    if (this.parent != null) {
      this.parent.children.push(this);
    }
  }

  ngOnDestroy(): void {
    this.parent?.children.splice(this.parent.children.indexOf(this), 1);
  }

  get sourceElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
  get parent(): Projectable | undefined {
    return this.parentProvider?.provided;
  }

  get content(): PortalDirective | undefined {
    return this.contentDirective;
  }
  get backDeco(): PortalDirective | undefined {
    return this.backDecoDirective;
  }
  get frontDeco(): PortalDirective | undefined {
    return this.frontDecoDirective;
  }

  get hasFocus(): boolean {
    return this.focusables.some((f) => f.hasFocus);
  }

  project(): void {
    //
  }

  public log(indent: number = 0): void {
    const prefix = ''.padStart(indent * 2, '  ');

    console.log(prefix + this.sourceElement.tagName);
    for (const child of this.children) {
      child.log(indent + 1);
    }
  }

  public sortChildren(): void {
    this.children.sort((a: Projectable, b: Projectable): number => {
      const compareMask = a.sourceElement.compareDocumentPosition(b.sourceElement);
      if (compareMask & Node.DOCUMENT_POSITION_PRECEDING) return 1;
      if (compareMask & Node.DOCUMENT_POSITION_FOLLOWING) return -1;
      return 0;
    });
  }

  abstract projectPosition(parentRect: DOMRect): void;
}

@Directive()
export class ProjectableDecoratorBase extends ProjectableBase {
  @ViewChild(ProjectableProvider) projectableProvider?: ProjectableProvider;

  get projectable(): Projectable | undefined {
    return this.projectableProvider?.provided;
  }

  get height(): number | undefined {
    return this.projectable?.height;
  }

  override get content(): PortalDirective | undefined {
    return this.contentDirective ?? this.projectable?.content;
  }
  override get backDeco(): PortalDirective | undefined {
    return this.backDecoDirective ?? this.projectable?.backDeco;
  }
  override get frontDeco(): PortalDirective | undefined {
    return this.frontDecoDirective ?? this.projectable?.frontDeco;
  }

  override get hasFocus(): boolean {
    return this.projectable?.hasFocus || super.hasFocus;
  }

  projectPosition(parentRect: DOMRect): void {
    this.projectable?.projectPosition(parentRect);
  }
  override project(): void {
    this.projectable?.project();
  }
}

export interface ProjectableOwner {
  projectableContentChange(projectable: Projectable): void;
  project(): void;
}

export class ProjectableOwnerProvider implements InterfaceProvider<ProjectableOwner> {
  constructor(public provided: ProjectableOwner) {}
}
