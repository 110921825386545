import { SchuelerlisteService } from './../../schuelerliste.service';
import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fz-schuelerliste-import-preview',
  templateUrl: './schuelerliste-import-preview.component.html',
  providers: [DatePipe],
})
export class SchuelerlisteImportPreviewComponent implements OnChanges {
  @Input() lines: any[] = [];
  @Output() next: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() back = new EventEmitter<null>();
  Object = Object;
  ignoreRows: number = 0;
  selectedLines: any[] = [];

  constructor(public schuelerlisteService: SchuelerlisteService) {}

  ngOnChanges(): void {
    this.ignoredRowsChanged();
  }

  ignoredRowsChanged(): void {
    this.selectedLines = this.lines.slice(this.ignoreRows);
  }

  reset(): void {
    this.lines = [];
  }
}
