import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { BWFormular } from './bw-formular';
import { BWZeugnisFormular } from './bw-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { BWZeugnistypComponent } from '../projectables/bw-zeugnistyp.component';
import { BWSchuelerComponent } from '../projectables/bw-schueler.component';
import { BWUnterschriftHalbjahrComponent } from '../projectables/bw-unterschrift-halbjahr.component';
import { BWLeistungenComponent } from '../projectables/bw-leistungen.component';
import { BWGespraechComponent } from '../projectables/bw-gespraech.component';

export class BW34hStandardFormular extends BWZeugnisFormular {
  get viewType(): FormularViewType {
    return BW34hStandardFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    noteFields.push(
      this.religion.gesamt.note,
      this.deutsch.gesamt.note,
      this.mathematik.gesamt.note,
      this.fremdsprache.gesamt.note,
      this.sachunterricht.gesamt.note,
      this.bildendeKunst.gesamt.note,
      this.musik.gesamt.note,
      this.sport.gesamt.note
    );
    return noteFields;
  }
}

@Component({
  selector: 'fz-bw-34h-standard-formular',
  templateUrl: 'bw-34h-standard-formular.component.html',
})
export class BW34hStandardFormularComponent extends FormularComponentBase<BW34hStandardFormular> {
  static createModel(formular: BWFormular) {
    return {
      zeugnistyp: BWZeugnistypComponent.createModel(formular),
      schueler: BWSchuelerComponent.createModel(formular),
      leistungen: BWLeistungenComponent.createModel(formular),
      schrift: {
        label: formular.schrift.gesamt.description.label,
        textField: formular.schrift.gesamt.text,
      },
      bemerkungen: {
        label: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      gespraech: BWGespraechComponent.createModel(formular),
      unterschrift: BWUnterschriftHalbjahrComponent.createModel(formular),
    };
  }

  get model() {
    return BW34hStandardFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<BW34hStandardFormular>) {
    super(formularService);
  }
}
