/* generated file - do not touch! */

import FachKey from './fach-key';

class NIFachKey extends FachKey {
  public static readonly fremdsprache: string = 'fremdsprache';
  public static readonly werken: string = 'werken';
  public static readonly gestalten: string = 'gestalten';
  public static readonly herkunftssprache: string = 'herkunftssprache';
  public static readonly werteNormen: string = 'werteNormen';
  public static readonly personaleBildung: string = 'personaleBildung';
  public static readonly lernFach: string = 'lernFach';
  public static readonly lernstandDeutsch: string = 'lernstandDeutsch';
  public static readonly lernstandEnglisch: string = 'lernstandEnglisch';
  public static readonly lernstandMathematik: string = 'lernstandMathematik';
  public static readonly lernstandWeitereFaecher: string = 'lernstandWeitereFaecher';
  public static readonly lernstandMehrsprachigkeit: string = 'lernstandMehrsprachigkeit';
  public static readonly lernstandMethodenkompetenzen: string = 'lernstandMethodenkompetenzen';
  public static readonly bericht: string = 'bericht';
}
export default NIFachKey;
