import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { BWZeugnis } from '../../../models/bw-zeugnis';
import { NoteField } from '../../../models/fields/note-field';
import { FormularBereich } from '../../../models/formular-bereich';
import { FormularFach } from '../../../models/formular-fach';
import { WorkspaceService } from '../../../shared/services';
import { BWBeilageFormular } from './bw-beilage-formular';
import { FormularViewType } from '../../formular/formular';
import { BWZeugnistypComponent } from '../projectables/bw-zeugnistyp.component';
import { BWSchuelerComponent } from '../projectables/bw-schueler.component';
import { BWUnterschriftComponent } from '../projectables/bw-unterschrift.component';
import { KPBlockFactoryComponent } from '../../kp/kp-block-factory.component';
import { BWUnterschriftHalbjahrComponent } from '../projectables/bw-unterschrift-halbjahr.component';

interface Option<T> {
  label: string;
  value: T;
}
interface Ref<T> {
  ref: T;
}
interface Hauptfach {
  key: string;
  label?: string;
  hauptBereich: FormularBereich;
  bereiche: FormularBereich[];
}

export class BW1234hjBeilageFormular extends BWBeilageFormular {
  hauptFaecher: Hauptfach[] = [
    {
      key: 'lernArbeitsverhalten',
      label: 'Arbeits- und Lernverhalten',
      hauptBereich: this.sozialLernArbeitsverhalten.lernArbeitsverhalten,
      bereiche: [
        this.sozialLernArbeitsverhalten.zusammenarbeit,
        this.sozialLernArbeitsverhalten.eigenstaendigesArbeiten,
        this.sozialLernArbeitsverhalten.sorgfalt,
      ],
    },
    {
      key: 'deutsch',
      hauptBereich: this.deutsch.gesamt,
      bereiche: [
        this.deutsch.sprechenZuhoeren,
        this.deutsch.lesen,
        this.deutsch.texteVerfassen,
        this.deutsch.rechtschreiben,
        this.deutsch.spracheUntersuchen,
      ],
    },
    {
      key: 'mathematik',
      hauptBereich: this.mathematik.gesamt,
      bereiche: [this.mathematik.zahlen, this.mathematik.groessen, this.mathematik.raum, this.mathematik.daten],
    },
  ];

  private _visibleOptions: Option<Ref<string>>[] = [];
  private _visibleSelected: Ref<string>[] = [];
  get viewType(): FormularViewType {
    return BW1234hjBeilageFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    faecher.push(
      this.deutsch,
      this.mathematik,
      this.fremdsprache,
      this.sachunterricht,
      this.musik,
      this.bildendeKunst,
      this.sport,
      this.religion
    );
    return faecher;
  }

  get restFaecher(): FormularFach[] {
    return [this.sachunterricht, this.musik, this.bildendeKunst, this.sport, this.religion];
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    return noteFields;
  }

  get visibleOptions(): Option<Ref<string>>[] {
    const options: Option<Ref<string>>[] = [];
    for (const fach of this.faecher) {
      options.push({ label: fach.description.label, value: { ref: fach.key } });
    }
    if (
      this._visibleOptions.length !== options.length ||
      this._visibleOptions.some((v, index) => v.value.ref !== options[index].value.ref)
    ) {
      this._visibleOptions.splice(0, this._visibleOptions.length);
      this._visibleOptions.push(...options);
    }
    return this._visibleOptions;
  }
  get visibleSelected(): Ref<string>[] {
    const selected: Ref<string>[] = [];
    for (const fach of this.faecher) {
      if (fach.gesamt.isVisibleOverrideZusatztextDefaultTrue) selected.push({ ref: fach.key });
    }
    if (
      this._visibleSelected.length !== selected.length ||
      this._visibleSelected.some((v, index) => v.ref !== selected[index].ref)
    ) {
      this._visibleSelected.splice(0, this._visibleSelected.length);
      this._visibleSelected.push(...selected);
    }
    return this._visibleSelected;
  }

  visibleChange(value: Option<Ref<string>> | undefined): void {
    if (value != null) {
      const ref = value.value.ref;
      const fachGesamt = this.zeugnis.faecher.getItem(ref).bereiche.getItem(null);
      fachGesamt.isVisibleOverrideZusatztextDefaultTrue = !fachGesamt.isVisibleOverrideZusatztextDefaultTrue;
    } else {
      const isVisible = this.faecher.every((f) => f.gesamt.isVisibleOverrideZusatztextDefaultTrue);
      for (const fach of this.faecher) {
        fach.gesamt.isVisibleOverrideZusatztextDefaultTrue = !isVisible;
      }
    }
  }

  copyVisible(targetZeugnisse: BWZeugnis[]): void {
    for (const targetZeugnis of targetZeugnisse) {
      const targetFormular = new BW1234hjBeilageFormular(targetZeugnis);
      for (const fach of this.faecher) {
        const targetFach = targetFormular.faecher.find((f) => f.key === fach.key);
        if (targetFach != null) {
          targetFach.gesamt.bereich.isVisibleOverrideZusatztext = fach.gesamt.bereich.isVisibleOverrideZusatztext;
        }
      }
    }
  }
}

@Component({
  selector: 'fz-bw-1234hj-beilage-formular',
  templateUrl: 'bw-1234hj-beilage-formular.component.html',
})
export class BW1234hjBeilageFormularComponent extends FormularComponentBase<BW1234hjBeilageFormular> {
  static createModel(formular: BW1234hjBeilageFormular) {
    return formular.zeugnis.beilageTyp === 'KP'
      ? {
          zeugnistyp: BWZeugnistypComponent.createModel(formular),
          schueler: BWSchuelerComponent.createModel(formular),
          sozialverhalten: {
            label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
            kp: KPBlockFactoryComponent.createModelFromBereich(formular.sozialLernArbeitsverhalten.sozialverhalten),
            textField: formular.sozialLernArbeitsverhalten.sozialverhalten.zusatztext,
          },
          hauptFaecher: formular.hauptFaecher
            .filter(
              (fach) => fach.key === 'lernArbeitsverhalten' || fach.hauptBereich.isVisibleOverrideZusatztextDefaultTrue
            )
            .map((fach) => ({
              trackBy: fach.key,
              key: fach.key,
              label: fach.label,
              hauptBereich: {
                label: fach.hauptBereich.description.label,
                textField: fach.hauptBereich.zusatztext,
              },
              bereiche: fach.bereiche.map((bereich) => ({
                trackBy: bereich.key ?? 'gesamt',
                key: bereich.key ?? 'gesamt',
                label: bereich.description.label,
                kp: KPBlockFactoryComponent.createModelFromBereich(bereich),
              })),
            })),
          fremdsprache: formular.fremdsprache.gesamt.isVisibleOverrideZusatztextDefaultTrue
            ? {
                label: formular.fremdspracheBezeichnung.value ?? 'Fremdsprache',
                kp: KPBlockFactoryComponent.createModelFromBereich(formular.fremdsprache.gesamt),
                textField: formular.fremdsprache.gesamt.zusatztext,
              }
            : undefined,
          restFaecher: formular.restFaecher
            .filter((fach) => fach.gesamt.isVisibleOverrideZusatztextDefaultTrue)
            .map((fach) => ({
              trackBy: fach.key,
              key: fach.key,
              label: fach.description.label,
              kp: KPBlockFactoryComponent.createModelFromBereich(fach.gesamt),
              textField: fach.gesamt.zusatztext,
            })),
          unterschrift:
            formular.zeugnis.zeugnissatz.klassenstufe < 3 || formular.zeugnis.zeugnissatz.halbjahr === 1
              ? BWUnterschriftComponent.createModel(formular)
              : undefined,
          unterschriftHalbjahr:
            formular.zeugnis.zeugnissatz.klassenstufe >= 3 && formular.zeugnis.zeugnissatz.halbjahr === 0
              ? BWUnterschriftHalbjahrComponent.createModel(formular)
              : undefined,
        }
      : undefined;
  }

  get model() {
    return BW1234hjBeilageFormularComponent.createModel(this.formular);
  }
  loading: boolean = false;
  constructor(
    formularService: FormularService<BW1234hjBeilageFormular>,
    private workspaceService: WorkspaceService
  ) {
    super(formularService);
  }

  getBereichFromHauptFach(fachKey: string, bereichKey: string): FormularBereich | undefined {
    return this.formular.hauptFaecher
      .find((f) => f.key === fachKey)
      ?.bereiche?.find((b) => (b.key ?? 'gesamt') === bereichKey);
  }
  getBereichFromRestFach(fachKey: string): FormularBereich | undefined {
    return this.formular.restFaecher.find((f) => f.key === fachKey)?.gesamt;
  }

  async applyVisibleToAll() {
    try {
      this.loading = true;
      this.formular.copyVisible(this.workspaceService.selectedZeugnisse as BWZeugnis[]);
      await this.workspaceService.saveSelectedZeugnissatz();
    } finally {
      this.loading = false;
    }
  }
}
