import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

export type FontWeight = 'normal' | 'bold';
export interface Font {
  family: string;
  weight: FontWeight;
  data: string;
}

@Injectable({ providedIn: 'root' })
export class FontService {
  constructor(private http: HttpClient) {}

  async getEmbeddedFonts(): Promise<Font[]> {
    const fonts: Font[] = [];
    const dataPattern = /url\("data:font\/ttf;.*?base64,(.*?)"\)/;
    const familyPattern = /font-family: (.*?);/;
    const weightPattern = /font-weight: (.*?);/;
    for (const styleSheet of Array.from(document.styleSheets)) {
      for (const rule of Array.from(styleSheet.cssRules).filter((r) => r instanceof CSSFontFaceRule)) {
        // console.log(rule.cssText);
        const dataMatch = rule.cssText.match(dataPattern);
        if (dataMatch != null) {
          const familyMatch = rule.cssText.match(familyPattern);
          const weightMatch = rule.cssText.match(weightPattern);
          if (familyMatch != null && weightMatch != null) {
            const family = familyMatch[1].replace(/"/g, '');
            const weight = weightMatch[1] as FontWeight;
            const data = dataMatch[1];
            fonts.push({
              family,
              weight,
              data,
            });
          }
        }
      }
    }
    return fonts;
  }

  async getFonts(): Promise<Font[]> {
    return [
      {
        family: 'Arial',
        weight: 'normal',
        data: await this.getFont('Arial.ttf'),
      },
      {
        family: 'Arial',
        weight: 'bold',
        data: await this.getFont('ARIALBD.TTF'),
      },
      {
        family: 'Arial Narrow',
        weight: 'normal',
        data: await this.getFont('ARIALN.TTF'),
      },
      {
        family: 'Arial Narrow',
        weight: 'bold',
        data: await this.getFont('ARIALNB.TTF'),
      },
      {
        family: 'Calibri',
        weight: 'normal',
        data: await this.getFont('Carlito-Regular.ttf'),
      },
      {
        family: 'Calibri',
        weight: 'bold',
        data: await this.getFont('Carlito-Bold.ttf'),
      },
      {
        family: 'Comic Sans MS',
        weight: 'normal',
        data: await this.getFont('ComicRelief.ttf'),
      },
      {
        family: 'Comic Sans MS',
        weight: 'bold',
        data: await this.getFont('ComicRelief-Bold.ttf'),
      },
      {
        family: 'Microsoft Sans Serif',
        weight: 'normal',
        data: await this.getFont('Arial.ttf'),
      },
      {
        family: 'Microsoft Sans Serif',
        weight: 'bold',
        data: await this.getFont('ARIALBD.TTF'),
      },
      {
        family: 'Saar-Regular',
        weight: 'normal',
        data: await this.getFont('Saar-Regular.ttf'),
      },
      {
        family: 'Saar-Regular',
        weight: 'bold',
        data: await this.getFont('Saar-Bold.ttf'),
      },
      {
        family: 'Segoe Print',
        weight: 'normal',
        data: await this.getFont('selawk.ttf'),
      },
      {
        family: 'Segoe Print',
        weight: 'bold',
        data: await this.getFont('selawkb.ttf'),
      },
      {
        family: 'Tahoma',
        weight: 'normal',
        data: await this.getFont('DejaVuSans.ttf'),
      },
      {
        family: 'Tahoma',
        weight: 'bold',
        data: await this.getFont('DejaVuSans-Bold.ttf'),
      },
      {
        family: 'Times New Roman',
        weight: 'normal',
        data: await this.getFont('LiberationSerif-Regular.ttf'),
      },
      {
        family: 'Times New Roman',
        weight: 'bold',
        data: await this.getFont('LiberationSerif-Bold.ttf'),
      },
    ];
  }

  private async getFont(font: string): Promise<string> {
    const options = { responseType: 'blob' as const, observe: 'response' as const };
    const data = await lastValueFrom(this.http.get(`/assets/fonts/${font}`, options));
    return new Promise<string>((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = function (e) {
        let res = e.target?.result as string;
        res = res.substring(res.indexOf('base64,') + 7);
        resolve(res);
      };
      if (data.body) fileReader.readAsDataURL(data.body);
    });
  }
}
