/* generated file - do not touch! */

import FachKey from './fach-key';

class SLFachKey extends FachKey {
  public static readonly fachbezogeneLernLeistungsentwicklung: string = 'fachbezogeneLernLeistungsentwicklung';
  public static readonly entwicklungSozialverhalten: string = 'entwicklungSozialverhalten';
  public static readonly entwicklungArbeitshaltung: string = 'entwicklungArbeitshaltung';
  public static readonly entwicklungLernLeistungsentwicklung: string = 'entwicklungLernLeistungsentwicklung';
  public static readonly entwicklungDenkvermoegen: string = 'entwicklungDenkvermoegen';
  public static readonly entwicklungAusdrucksfaehigkeit: string = 'entwicklungAusdrucksfaehigkeit';
}
export default SLFachKey;
