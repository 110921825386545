import { WorkspaceService } from '../../../shared/services';
import { Component, OnInit } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { NotificationService } from '../../services/notification/notification.service';
import { Router } from '@angular/router';
import { JahrgangService } from '../../services/jahrgang.service';
import { Jahrgang } from '../../../models/jahrgang';

@Component({
  selector: 'fz-jahrgang-oeffnen',
  templateUrl: './jahrgang-oeffnen.component.html',
  styleUrls: ['./jahrgang-oeffnen.component.scss'],
})
export class JahrgangOeffnenComponent implements OnInit {
  jahrgaenge: Jahrgang[] = [];
  loading: boolean = false;

  constructor(
    private jahrgangService: JahrgangService,
    private schuelerService: WorkspaceService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  async ngOnInit() {
    await this.fetchJahrgaenge();
  }

  openJahrgang(id: string): void {
    try {
      this.loading = true;
      this.schuelerService.openJahrgang(id);
    } finally {
      this.loading = false;
    }
  }

  editJahrgang(id: string): void {
    this.router.navigate(['jahrgang-aendern', id]);
  }

  deleteJahrgang(id: string): void {
    this.confirmationService.confirm({
      header: 'Jahrgang löschen?',
      message: 'Wollen Sie diesen Jahrgang wirklich löschen?',
      accept: async () => {
        try {
          this.loading = true;
          if (this.schuelerService.jahrgang?.id === id) await this.schuelerService.close();
          await this.jahrgangService.deleteJahrgang(id);
          await this.fetchJahrgaenge();
        } catch (_ex) {
          this.notificationService.showPermanentError(
            'Der Jahrgang konnte nicht gelöscht werden. Bitte versuchen Sie es später noch einmal.',
            'Es ist ein Fehler aufgetreten'
          );
        } finally {
          this.loading = false;
        }
      },
    });
  }

  private async fetchJahrgaenge() {
    try {
      this.loading = true;
      this.jahrgaenge = await this.jahrgangService.getJahrgaenge();
    } finally {
      this.loading = false;
    }
  }
}
