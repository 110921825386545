import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  Optional,
  SkipSelf,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Projectable, ProjectableBase, ProjectableProvider } from './projectable';

export type InputObject = Record<string, unknown>;

@Component({
  selector: 'fz-projectable-outlet',
  templateUrl: 'projectable-outlet.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, ProjectableOutletComponent)],
})
export class ProjectableOutletComponent extends ProjectableBase implements AfterViewInit {
  @Input() component!: Type<unknown>;
  @Input() inputs?: InputObject;
  @ViewChild('container', { read: ViewContainerRef }) viewContainerRef?: ViewContainerRef;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>,
    @Optional() @SkipSelf() parentProvider: ProjectableProvider | null
  ) {
    super(elementRef, parentProvider);
  }

  get projectable(): Projectable | undefined {
    return this.children[0];
  }

  get height(): number | undefined {
    return this.projectable?.height;
  }

  ngAfterViewInit(): void {
    if (this.component != null && this.viewContainerRef != null) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
      const componentRef = this.viewContainerRef.createComponent(componentFactory);
      if (this.inputs != null) {
        for (const key in this.inputs) {
          if (Object.prototype.hasOwnProperty.call(this.inputs, key)) {
            const value = this.inputs[key];
            (componentRef.instance as InputObject)[key] = value;
          }
        }
      }
      this.changeDetector.detectChanges();
    }
  }

  override project(): void {
    this.projectable?.project();
  }
  projectPosition(_parentRect: DOMRect): void {
    //
  }
}
