import { Bereich } from './bereich';
import { ItemListIndex, ListIndexable } from './item-list-index';
import { KPFach } from './kp-fach';
import { Zeugnis } from './zeugnis';

export class Fach implements ListIndexable {
  readonly bereiche = new ItemListIndex<Bereich, this>(Bereich, this);

  index: number = 0;
  teilnahmeBereichKey: string | null = null;

  get rasterFach(): KPFach {
    return this.zeugnis.zeugnissatz.raster.faecher.getItemList(this.key).getIndex(this.index);
  }

  constructor(
    public readonly key: string | null,
    public readonly zeugnis: Zeugnis
  ) {}

  getRasterFach(key: string) {
    return this.zeugnis.zeugnissatz.raster.faecher.getItemList(key).getIndex(this.index);
  }

  merge(source: Fach, appendBeurteilungen: boolean = false, appendText: string = '') {
    for (const bereichNeu of source.bereiche) {
      const bereichAlt = this.bereiche.getItemList(bereichNeu.key).getIndex(bereichNeu.index);
      bereichAlt.merge(bereichNeu, appendBeurteilungen, appendText);
      this.teilnahmeBereichKey = source.teilnahmeBereichKey ?? this.teilnahmeBereichKey;
      // Sonderfall BW: Religionsunterricht
      if (
        source.key === 'religion' &&
        (source.zeugnis as any).religionsunterricht != null &&
        (source.zeugnis as any).religionsunterricht !== ''
      ) {
        (this.zeugnis as any).religionsunterricht = (source.zeugnis as any).religionsunterricht;
      }
    }
  }

  static cleanDto(dto: any): any {
    const { key, zeugnis, bereiche, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(fach: Fach): unknown {
    return { ...this.cleanDto(fach), key: fach.key, bereiche: ItemListIndex.toDto(Bereich, fach.bereiche) };
  }

  static fromDto(dto: any, zeugnis: Zeugnis): Fach {
    const fach = new Fach(dto.key ?? null, zeugnis);
    return Object.assign(fach, {
      ...this.cleanDto(dto),
      bereiche: ItemListIndex.fromDto(Bereich, dto.bereiche, fach),
    });
  }
}
