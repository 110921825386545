import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Formular } from '@modules/formular/formular';
import { FormularFactory } from '@modules/formular/formular-factory';
import { Formularsatz } from '@modules/formular/formularsatz';
import { Guid } from 'guid-typescript';
import _ from 'lodash-es';
import { Papa, ParseConfig, ParseResult } from 'ngx-papaparse';
import { MenuItem, PrimeTemplate, TreeNode } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { OverlayPanel } from 'primeng/overlaypanel';
import { SplitButton } from 'primeng/splitbutton';
import { BenutzerKPRaster } from '../../../../models/benutzer-kp-raster';
import { FormularFach } from '../../../../models/formular-fach';
import UpdateRasterBereichResult from '../../../../models/generated/update-raster-bereich-result';
import UpdateRasterResultCode from '../../../../models/generated/update-raster-result-code';
import { KPBereich } from '../../../../models/kp-bereich';
import { KPFach } from '../../../../models/kp-fach';
import { KPItem } from '../../../../models/kp-item';
import { Schueler } from '../../../../models/schueler';
import { Zeugnis } from '../../../../models/zeugnis';
import { Zeugnissatz } from '../../../../models/zeugnissatz';
import { AppInfoService } from '../../../../shared/services/app-info.service';
import { FZConfirmationService } from '../../../../shared/services/fz-confirmation.service';
import { JahrgangFactoryService } from '../../../../shared/services/jahrgang-factory.service';
import { SystemActionsService } from '../../system-actions/system-actions.service';
import { FlinkyAuthService } from './../../../services/auth/flinky-auth.service';
import { KoennensprofilService } from './../koennensprofil.service';
import { Page } from './editor-page-options';

interface Option {
  label: string;
  typ: string;
  sort: number;
}

interface KopfSelectionItem {
  key: 'Kopfbereich';
}

interface KopfItem {
  label: string;
  line1: string;
  line2: string;
  line3: string;
}
export type IKPItemArray = KPItem[] & Record<string, KPItem[]>;

export type IOptionArray = Option[] & Record<string, Option[]>;

export type ILoadingArray = boolean[] & Record<string, boolean>;
@Component({
  selector: 'fz-kp-editor',
  templateUrl: './kp-editor.component.html',
  styleUrls: ['./kp-editor.component.scss'],
})
export class KpEditorComponent implements OnInit, AfterViewInit {
  @ViewChild('editOp') editOp?: OverlayPanel;
  @ViewChild('addOp') addOp?: OverlayPanel;
  @ViewChild('selectOp') selectOp?: OverlayPanel;
  @ViewChild('cdResult') cdResult?: ConfirmDialog;
  @ViewChild('cdError') cdError?: ConfirmDialog;
  @ViewChild('cdBereiche') cdBereiche?: ConfirmDialog;
  @ViewChildren(PrimeTemplate) templates = new QueryList<PrimeTemplate>();
  @ViewChild('copyButton') copyButton?: SplitButton;

  Array = Array;
  isDirty = false;
  items: Page[] = [];
  selectedPage?: Page;
  benutzerRaster: BenutzerKPRaster = new BenutzerKPRaster();
  allAvailableItems: IKPItemArray = [] as unknown as IKPItemArray;
  availableItems: IKPItemArray = [] as unknown as IKPItemArray;
  sourceOptions: IOptionArray = [] as unknown as IOptionArray;
  selectedSourceTypes: IOptionArray = [] as unknown as IOptionArray;
  loadingItems: ILoadingArray = [] as unknown as ILoadingArray;
  bula: string = '';
  dataLoading: boolean = false;
  editItem?: KPItem;
  editItemText: string = '';
  editBereichName: string = '';
  newer: UpdateRasterBereichResult[] = [];
  uploaded: UpdateRasterBereichResult[] = [];
  overwritten: UpdateRasterBereichResult[] = [];
  uploadError: UpdateRasterBereichResult[] = [];
  overwriteError: UpdateRasterBereichResult[] = [];
  cdResultVisible: boolean = false;
  cdNoResultVisible: boolean = false;
  cdErrorVisible: boolean = false;
  cdKonfliktVisible: boolean = false;
  formular?: Formular;
  zeugnissatz?: Zeugnissatz;
  zeugnis?: Zeugnis;
  addItem: KPItem = {} as KPItem;
  itemAddMode: 'append' | 'replace';

  kopfItems: KopfItem[] = [];

  previewDialogVisible: boolean = false;
  previewZeugnis?: Zeugnis;
  formularsatz?: Formularsatz;
  // mustUpdate: boolean = false;

  selectedFach: KPFach | undefined;
  selectedBereich: KPBereich | undefined;

  sortedFaecher: (KPFach | KopfSelectionItem)[] = [];
  copyButtonItems: MenuItem[] = [];
  faecherTree: TreeNode[] = [];
  selectedFachNode: any | null = null;
  selectedItems: any[] = [];
  loading: boolean = false;

  constructor(
    private flinkyAuth: FlinkyAuthService,
    private kpService: KoennensprofilService,
    private confirmationService: FZConfirmationService,
    private papa: Papa,
    private jahrgangFactory: JahrgangFactoryService,
    public changeDetectorRef: ChangeDetectorRef,
    public systemActionService: SystemActionsService,
    private appInfoService: AppInfoService
  ) {
    this.bula = this.flinkyAuth.lizenz?.bundesland?.toLowerCase() ?? '';
    this.itemAddMode = 'append';
  }

  async ngOnInit(): Promise<void> {
    switch (this.flinkyAuth.lizenz?.bundesland) {
      case 'RP':
        this.items = this.getRPItems();
        break;
      case 'HE':
        this.items = this.getHEItems();
        break;
      case 'BW':
        this.items = this.getBWItems();
        break;
      case 'NI':
        this.items = this.getNIItems();
        break;
      default:
        this.items = [];
    }

    await this.getRaster();
  }

  ngAfterViewInit(): void {
    this.appInfoService.currentPageTitleTemplate = this.templates.find((t) => t.name === 'currentPageTitle')?.template;
    this.changeDetectorRef.detectChanges();
  }

  async canDeactivate() {
    if (this.flinkyAuth.getToken() === '') return true;

    if (!this.isRasterUpdated || this.selectedPage == null) return true;

    if (this.bula !== 'rp') {
      if (
        await this.confirmationService.confirm({
          header: 'Frage',
          message: 'Wollen Sie die Änderungen speichern?',
        })
      ) {
        await this.kpService.saveRaster(this.benutzerRaster);
        return true;
      } else return true;
    } else {
      if (
        await this.confirmationService.confirm({
          header: 'Frage',
          message: 'Wollen Sie die Änderungen hochladen und ALLEN Lehrkräften Ihrer Schule bereitstellen?',
        })
      ) {
        return await this.uploadRaster();
      } else return true;
    }
  }

  async askForUpload(): Promise<void> {
    this.copyButtonItems = this.getCopyButtonItems();
    this.selectedFachNode = null;

    if (this.isRasterUpdated && this.bula === 'rp') {
      if (
        await this.confirmationService.confirm({
          header: 'Frage',
          message: 'Wollen Sie die Änderungen hochladen und ALLEN Lehrkräften Ihrer Schule bereitstellen?',
        })
      ) {
        await this.uploadRaster(false, true);
      } else {
        await this.getRaster();
      }
    } else if (this.isRasterUpdated && this.bula !== 'rp') {
      if (
        await this.confirmationService.confirm({
          header: 'Frage',
          message: 'Wollen Sie die Änderungen speichern?',
        })
      ) {
        await this.saveRaster();
      } else {
        await this.getRaster();
      }
    } else {
      await this.getRaster();
    }
    this.selectedFach = undefined;
    this.selectedBereich = undefined;
  }

  async saveOrUpload() {
    if (this.isRasterUpdated) {
      if (this.bula === 'rp') {
        await this.uploadRaster(false, true);
      } else {
        await this.saveRaster();
      }
    }
  }

  createKopfTable(headers: string[]) {
    this.kopfItems = [];
    if (headers.length > 0) {
      this.kopfItems = headers.map(
        (h) => ({ line1: h.split('\n')[0], line2: h.split('\n')[1], line3: h.split('\n')[2] }) as KopfItem
      );
    }
    if (this.kopfItems.length === 0 && this.zeugnissatz && this.formularsatz) {
      this.kopfItems = this.formularsatz
        .getDefaultKPHeaderLabels(this.zeugnissatz)
        .map((h) => ({ line1: h[0], line2: h[1], line3: h[2] }) as KopfItem);
    }
  }

  async addKopfSpalte() {
    this.kopfItems.push({ label: '', line1: '', line2: '', line3: '' });
    try {
      this.loading = true;
      await this.updateBenutzerRaster();
    } finally {
      this.loading = false;
    }
  }

  async removeKopfSpalte(item: KopfItem) {
    this.kopfItems = this.kopfItems.filter((ki) => ki !== item);
    await this.updateBenutzerRaster();
  }

  getBereichLabel(key: string): string {
    const splitted = key.split('_');
    if (splitted.length === 2) {
      let label = ((this.formular as any)[splitted[0]] as FormularFach)?.bereiche?.find((b) => b.key === splitted[1])
        ?.description.label;

      if (label == null) {
        label = splitted[1].charAt(0).toUpperCase() + splitted[1].substring(1);
      }
      return label;
    }

    return key;
  }

  getFachLabel(key: string): string {
    const viewModel = (this.formular as any)[key] as FormularFach;
    let label = viewModel?.description?.label ?? key;
    if (label === '1. Herkunftssprachenunterricht') {
      label = 'Herkunftssprachenunterricht';
    }

    return label;
  }

  getFachBereichLabel(key: string) {
    let label = key;
    if (key !== '') {
      const splitted = key.split('_');
      label = this.getFachLabel(splitted[0]);
      if (splitted.length === 2) {
        label = (label ?? '') + ' ' + this.getBereichLabel(key);
      }
    }
    return label;
  }

  async getRaster(page: Page | null = null): Promise<BenutzerKPRaster | null> {
    // ansonsten knallts mit maximun call stack size exeeded - irgendwo ...
    this.selectedFach = undefined;

    const pageToLoad = page ?? this.selectedPage;
    if (pageToLoad) {
      if (this.flinkyAuth.lizenz != null) {
        this.formularsatz = FormularFactory.getFormularsatz(this.flinkyAuth.lizenz.bundesland);
      }
      let benutzerRaster = null;
      try {
        this.dataLoading = true;
        this.sourceOptions = {} as IOptionArray;
        this.allAvailableItems = {} as IKPItemArray;
        this.selectedSourceTypes = {} as IOptionArray;

        benutzerRaster = await this.kpService.loadRaster(this.flinkyAuth.lizenz?.kundennummer ?? '', pageToLoad.code);
        this.benutzerRaster = benutzerRaster;
        this.isDirty = false;

        if (this.flinkyAuth.lizenz) {
          const jahrgang = await this.jahrgangFactory.createJahrgang();
          const schuljahr = new Date().getFullYear();
          const halbjahr = pageToLoad.halbjahr;
          const klassenstufe = pageToLoad.klassenstufe;
          this.zeugnissatz = new Zeugnissatz('', jahrgang, schuljahr, halbjahr, klassenstufe);
          const schueler = new Schueler('', jahrgang);

          this.zeugnis = new Zeugnis('', schueler, this.zeugnissatz);
          this.formular = FormularFactory.getFormularsatz(this.flinkyAuth.lizenz.bundesland).createFormular(
            this.zeugnis,
            pageToLoad.category
          );
        }

        // Headers auslesen, falls sowas vorhanden
        this.createKopfTable(benutzerRaster.raster.headers);

        this.sortedFaecher = Array.from(this.benutzerRaster.raster.faecher).sort((a, b) =>
          (a?.key ?? '') < (b?.key ?? '') ? -1 : 1
        );
        if (this.bula !== 'rp')
          this.sortedFaecher = [{ key: 'Kopfbereich' } as KopfSelectionItem, ...this.sortedFaecher];
        // vorgegebene Bausteine laden gehen
        this.faecherTree = [];
        if (this.bula !== 'rp') {
          const kopfNode = { label: 'Kopfbereich', key: 'kopfbereich', data: {} as KopfSelectionItem } as TreeNode;
          this.faecherTree.push(kopfNode);
        }

        for (const fach of this.sortedFaecher.filter((f) => f instanceof KPFach) as KPFach[]) {
          const fachNode = { label: fach.key, data: fach, children: [], key: fach.key } as TreeNode;
          this.faecherTree.push(fachNode);

          const sortedBereiche = Array.from(fach.bereiche).sort((a, b) => ((a?.key ?? '') < (b?.key ?? '') ? -1 : 1));
          for (const bereich of sortedBereiche) {
            this.loadKPItems(fach.key, bereich.key ?? '');

            const bereichNode = {
              label: fach.key + '_' + bereich.key,
              data: bereich,
              key: fach.key + '_' + bereich.key,
            } as TreeNode;
            if (sortedBereiche.length === 1) {
              bereichNode.label = 'Gesamt';
              bereichNode.key = fach.key ?? '';
            }
            fachNode.children?.push(bereichNode);

            bereich.items.forEach((i) => {
              // leere Elemente entfernen
              if (i.text.trim() === '') {
                bereich.items.splice(bereich.items.indexOf(i), 1);
              }
            });
          }
        }

        // leere Items finden?
      } finally {
        this.dataLoading = false;
      }
      return benutzerRaster;
    } else {
      return null;
    }
  }

  isKpFach(fach: KPFach | KopfSelectionItem | KPBereich) {
    return fach instanceof KPFach || fach instanceof KPBereich;
  }

  onSourceChanged(fachBereich: string, event: MultiSelectChangeEvent): void {
    if (event.itemValue && event.itemValue.typ === event.itemValue.label) {
      if (event.value.find((e: any) => e.label === event.itemValue.label && e.typ === event.itemValue.typ)) {
        // alle kinder setzen
        this.sourceOptions[fachBereich]
          .filter((s) => s.typ === event.itemValue.typ)
          .forEach((x) => {
            if (this.selectedSourceTypes[fachBereich].find((y) => y.label === x.label) == null) {
              this.selectedSourceTypes[fachBereich].push(x);
            }
          });
      } else {
        this.selectedSourceTypes[fachBereich] = this.selectedSourceTypes[fachBereich].filter(
          (y) => y.typ !== event.itemValue.typ
        );
      }
    }
    this.filterAvailableItems(fachBereich);
  }

  reorder(bereichName: string) {
    this.setRasterBereichVersionUpdated(bereichName);
  }

  filterAvailableItems(bereichName: string) {
    this.availableItems[bereichName] = [];
    this.selectedSourceTypes[bereichName].forEach((selectedValue) => {
      this.availableItems[bereichName].push(
        ...this.allAvailableItems[bereichName].filter(
          (item) => selectedValue.typ === item.typ && (item.gruppe === selectedValue.label || !item.gruppe)
        )
      );
    });
  }

  async loadVorgegebene(bereichName: string) {
    if (this.selectedPage && this.selectedPage.codeVorg) {
      this.loadingItems[bereichName] = true;

      const name = `kl_${this.selectedPage?.codeVorg}_${bereichName}.csv`;
      try {
        const data = await this.kpService.getVorgegebeneItems(name, this.bula);
        let obj: KPItem[] = [];
        const options: ParseConfig = {
          skipEmptyLines: true,
          delimiter: ';',
          complete: (results: ParseResult, _file: File | undefined) => {
            obj = (results.data as string[][]).map((i) =>
              KPItem.fromDto({
                guid: i[0],
                gruppe: i[1],
                text: i[2],
                typ: 'Vorgegebene',
                sort: 2,
              })
            );
          },
        };
        this.papa.parse(data, options);
        const gruppen: Option[] = [];
        obj.forEach((item) => {
          if (!gruppen.find((x) => x.label === item.gruppe) && item.gruppe != null) {
            gruppen.push({ label: item.gruppe, typ: item.typ!, sort: item.sort! });
          }
        });
        if (!this.allAvailableItems[bereichName]) {
          this.allAvailableItems[bereichName] = [];
        }
        obj.forEach((o) => {
          if (o.text.trim() !== '') this.allAvailableItems[bereichName].push(o);
        });
        if (this.sourceOptions[bereichName] == null) {
          this.sourceOptions[bereichName] = [];
        }
        this.sourceOptions[bereichName].push(...gruppen);
        this.selectedSourceTypes[bereichName] = this.sourceOptions[bereichName].filter(
          (x) => x.typ === 'Vorgegebene' || x.label === 'Vorgegebene'
        );
        this.sourceOptions[bereichName].sort((a, b) => (a.sort > b.sort && 1) || -1);
        this.filterAvailableItems(bereichName);
      } catch (ex) {
        if (ex instanceof HttpErrorResponse && ex.status === 404) {
          // ignorieren
        } else {
          throw ex;
        }
      } finally {
        this.loadingItems[bereichName] = false;
      }
    }
  }

  hasAnyLoadingItem(): boolean {
    let result = false;

    if (this.dataLoading === true) {
      return true;
    }

    const li = this.loadingItems;
    Object.keys(li).forEach(function (key) {
      const val = li[key];
      if (val === true) {
        result = true;
      }
    });
    return result;
  }

  async loadEigene(fach: string, bereich: string, bereichName: string) {
    this.loadingItems['eigen_' + bereichName] = true;
    const data = await this.kpService.getEigeneItems(
      this.selectedPage?.code ?? '',
      fach ?? '',
      bereich,
      this.bula,
      this.flinkyAuth.lizenz?.kundennummer ?? ''
    );
    data.forEach((item) => {
      item.typ = 'Eigene';
      item.sort = 3;
    });
    if (!this.allAvailableItems[bereichName]) {
      this.allAvailableItems[bereichName] = [];
    }
    data.forEach((o) => {
      if (o.text.trim() !== '') this.allAvailableItems[bereichName].push(o);
    });
    this.allAvailableItems[bereichName] = _.uniqBy(this.allAvailableItems[bereichName], (kpi) => kpi.text);
    this.loadingItems['eigen_' + bereichName] = false;
  }

  async loadAndere(fach: string, bereich: string, bereichName: string) {
    this.loadingItems['andere_' + bereichName] = false;
    const data = await this.kpService.getCommonItems(
      this.selectedPage?.code ?? '',
      fach ?? '',
      bereich,
      this.bula,
      this.flinkyAuth.lizenz?.kundennummer ?? ''
    );
    data.forEach((item) => {
      item.typ = 'Andere Schulen';
      item.sort = 4;
    });
    if (!this.allAvailableItems[bereichName]) {
      this.allAvailableItems[bereichName] = [];
    }
    data.forEach((o) => {
      if (o.text.trim() !== '') this.allAvailableItems[bereichName].push(o);
    });
    this.allAvailableItems[bereichName] = _.uniqBy(this.allAvailableItems[bereichName], (kpi) => kpi.text);
    this.loadingItems[bereichName] = false;
  }

  loadKPItems(fach: string | null, bereich: string): void {
    const bereichName = bereich !== '' ? fach + '_' + bereich : (fach ?? '');
    this.allAvailableItems[bereichName] = [];
    this.availableItems[bereichName] = [];

    this.sourceOptions[bereichName] = [{ label: 'Vorgegebene', typ: 'Vorgegebene', sort: 1 }];
    this.sourceOptions[bereichName].push({ label: 'Eigene', typ: 'Eigene', sort: 3 });
    this.sourceOptions[bereichName].push({ label: 'Andere Schulen', typ: 'Andere Schulen', sort: 4 });

    this.loadVorgegebene(bereichName);
    this.loadEigene(fach ?? '', bereich, bereichName);
    this.loadAndere(fach ?? '', bereich, bereichName);
  }

  edit(item: KPItem, bereichName: string, event: any) {
    this.editItem = item;
    this.editItemText = item.text;
    this.editBereichName = bereichName;

    this.editOp?.show(event);
  }

  async remove(item: KPItem, _bereichName: string, _event: any) {
    if (this.selectedFachNode?.data?.items != null) {
      const indx = this.selectedFachNode?.data.items.indexOf(item);
      this.selectedFachNode?.data.items.splice(indx, 1);
      await this.setRasterBereichVersionUpdated(this.selectedFachNode?.key ?? '');
    }
  }

  add(bereichName: string, event: any) {
    this.addItem = {} as KPItem;
    this.addItem.guid = Guid.create().toString();
    this.addItem.key = bereichName;
    this.addItem.typ = 'Eigene';
    this.addItem.gruppe = '';
    this.addItem.text = '';

    this.addOp?.show(event);
  }

  select(_bereichName: string, event: any) {
    this.selectedItems = [];
    this.selectOp?.show(event);
  }

  async applySelect() {
    this.selectedItems.forEach((item) => {
      if (this.selectedFachNode?.data != null) {
        this.selectedFachNode.data.items.push(item);
        this.selectedFachNode.data.items = this.selectedFachNode?.data.items.slice();
      }
    });
    try {
      this.loading = true;
      await this.setRasterBereichVersionUpdated(this.selectedFachNode?.key ?? '');
      this.selectOp?.hide();
    } finally {
      this.loading = false;
    }
  }

  cancel(): void {
    this.editOp?.hide();
  }

  async applyText() {
    if (this.editItem) {
      this.editItem.text = this.editItemText;

      const existing = this.allAvailableItems[this.editBereichName]?.find((x) => x.guid === this.editItem?.guid);
      if (existing && existing.typ !== 'Eigene') {
        this.editItem.guid = Guid.create().toString();
        this.editItem.typ = 'Eigene';
      }
      try {
        this.loading = true;
        await this.setRasterBereichVersionUpdated(this.editBereichName);
        this.editOp?.hide();
      } finally {
        this.loading = false;
      }
    }
  }

  async applyAdd() {
    if (this.addItem && this.addItem.key) {
      const splitted = this.addItem.key.split('_');
      const bereich = this.findRasterBereich(splitted[0], splitted.length === 2 ? splitted[1] : null);
      let newItems: KPItem[] = [];
      if (bereich) {
        if (this.itemAddMode === 'append') {
          newItems = bereich.items;
        }
        const splittedText = this.addItem.text.split('\n');
        splittedText.forEach((text) => {
          if (!newItems.find((i) => i.text === text) && text !== '') {
            newItems.push(
              Object.assign(new KPItem(), {
                text: text,
                guid: Guid.create().toString(),
                gruppe: '',
                key: this.addItem.key,
                typ: 'Eigene',
              })
            );
          }
        });
        bereich.items = newItems;
        bereich.items = _.uniqBy(bereich.items, 'text');
        bereich.items = bereich.items.slice();
        try {
          this.loading = true;
          await this.setRasterBereichVersionUpdated(this.addItem.key!);
          this.addOp?.hide();
        } finally {
          this.loading = false;
        }
      }
    }
  }

  findRasterBereich(fach: string, bereich: string | null): KPBereich {
    return this.benutzerRaster?.raster.faecher.getItem(fach).bereiche.getItem(bereich);
  }

  async setRasterBereichVersionUpdated(bereichName: string) {
    const splitted = bereichName.split('_');
    const bereich = this.findRasterBereich(splitted[0], splitted.length === 2 ? splitted[1] : null);
    if (bereich && (bereich.version === 0 || bereich.version == null)) {
      bereich.version = 999999999;
    }
    if (bereich && bereich.version) {
      bereich.version = bereich.version > 0 ? bereich.version * -1 : bereich.version;
    }

    await this.updateBenutzerRaster();
  }

  async changeKopfbereich() {
    if (
      this.benutzerRaster &&
      (this.benutzerRaster?.raster.headersVersion === 0 || this.benutzerRaster?.raster.headersVersion == null)
    ) {
      this.benutzerRaster.raster.headersVersion = 999999999;
    }
    if (this.benutzerRaster && this.benutzerRaster.raster.headersVersion) {
      this.benutzerRaster.raster.headersVersion =
        this.benutzerRaster.raster.headersVersion > 0
          ? this.benutzerRaster.raster.headersVersion * -1
          : this.benutzerRaster.raster.headersVersion;
    }
    await this.updateBenutzerRaster();
  }

  async updateBenutzerRaster(): Promise<void> {
    if (this.selectedPage) {
      // header richtig machen
      this.benutzerRaster.raster.headers = this.kopfItems.map((ki) => ki.line1 + '\n' + ki.line2 + '\n' + ki.line3);
      if (this.zeugnissatz != null) this.zeugnissatz.raster = this.benutzerRaster.raster;
      this.isDirty = true;
    }
  }

  async download(): Promise<void> {
    if (this.selectedPage) {
      await this.kpService.downloadRaster(
        this.selectedPage.code ?? '',
        this.bula,
        this.flinkyAuth.lizenz?.kundennummer ?? ''
      );
      await this.getRaster();
    }
  }

  async onDownload(): Promise<void> {
    if (this.selectedPage) {
      if (
        !this.isRasterUpdated ||
        (await this.confirmationService.confirm({
          header: 'Frage',
          message: 'Ihre Änderungen der Items werden überschrieben. Wirklich herunterladen?',
        }))
      ) {
        await this.download();
      }
    }
  }

  get isRasterUpdated(): boolean {
    if (this.bula === 'rp') {
      const raster = this.benutzerRaster.raster;
      return (
        // TODO: Prüfen: Versionsvergleich
        Array.from(raster.faecher).find(
          (f) => Array.from(f.bereiche).find((b) => b.version && b.version < 0) != null
        ) != null
      );
    } else {
      return this.isDirty;
    }
  }

  async saveRaster(): Promise<boolean> {
    if (this.selectedPage && this.benutzerRaster && this.isRasterUpdated) {
      try {
        this.dataLoading = true;
        await this.kpService.saveRaster(this.benutzerRaster);
        this.isDirty = false;
      } finally {
        this.dataLoading = false;
      }
    }
    return true;
  }

  async uploadRaster(overwrite: boolean = false, suppressSuccess: boolean = false): Promise<boolean> {
    if (this.bula !== 'rp') {
      overwrite = true;
    }
    if (this.selectedPage) {
      try {
        this.dataLoading = true;
        const updateResults = await this.kpService.uploadRaster(this.benutzerRaster, overwrite, this.bula);
        this.newer = updateResults.filter((r) => r.result === UpdateRasterResultCode.serverResultNewer);
        this.uploaded = updateResults.filter((r) => r.result === UpdateRasterResultCode.updated);
        this.overwritten = updateResults.filter((r) => r.result === UpdateRasterResultCode.overwritten);
        this.uploadError = updateResults.filter((r) => r.result === UpdateRasterResultCode.updateError);
        this.overwriteError = updateResults.filter((r) => r.result === UpdateRasterResultCode.overwriteError);

        if (this.newer.length > 0) {
          this.cdKonfliktVisible = true;
          return false;
        } else if (this.uploadError.length > 0 || this.overwriteError.length > 0) {
          this.cdErrorVisible = true;
          return false;
        } else if (this.uploaded.length > 0 || this.overwritten.length > 0) {
          this.cdResultVisible = true;
          return true;
        } else if (!suppressSuccess) {
          this.cdNoResultVisible = true;
          return true;
        } else {
          await this.getRaster();
          return true;
        }
      } finally {
        this.dataLoading = false;
      }
    } else {
      await this.getRaster();
      return true;
    }
  }

  getCopyItems(bundesland: string, selectedPage: Page): Page[] {
    switch (bundesland) {
      case 'rp':
        return this.getRPItems().filter((i) => i.label !== selectedPage.label);
      case 'he':
        return this.getHEItems().filter((i) => i.label !== selectedPage.label);
      case 'bw':
        return this.getBWItems().filter((i) => i.label !== selectedPage.label);
      case 'ni':
        return this.getBWItems().filter((i) => i.label !== selectedPage.label);
    }
    return [];
  }

  getCopyButtonItems(): MenuItem[] {
    const menuItems: MenuItem[] = [];
    if (this.selectedPage) {
      const copyItems = this.getCopyItems(this.bula, this.selectedPage);

      for (const ci of copyItems) {
        menuItems.push({
          label: ci.label,
          command: () => {
            this.copyFrom(ci);
          },
          // disabled: this.dataLoading || this.hasAnyLoadingItem(),
        });
      }

      // menuItems = copyItems.map((ci) => ({
      //   label: ci.label,
      //   command: () => {
      //     this.copyFrom(ci);
      //   },
      //   // disabled: ci.disabled,
      // }));
    }
    return menuItems;
  }

  async copyFrom(page: Page) {
    if (
      await this.confirmationService.confirm({
        message: `Wollen Sie die Items aus ${page.label} wirklich übernehmen? Alle bestehenden Items werden überschrieben!`,
        header: 'Frage',
      })
    ) {
      const benutzerRaster = await this.getRaster(page);
      if (benutzerRaster) {
        benutzerRaster.klassenstufe = this.selectedPage?.code ?? '';
        // version auf definitiv zu updatende setzen
        Array.from(benutzerRaster.raster.faecher).forEach((fach) =>
          Array.from(fach.bereiche).forEach((bereich) => (bereich.version = -888888888))
        );
        await this.updateBenutzerRaster();
        // if (this.bula === 'rp') this.uploadToServerAndGetRaster(true);
      }
    }
  }

  showPreview(): void {
    this.previewZeugnis = undefined;
    this.previewDialogVisible = true;
    // }

    // onShowPreview(dlg: Dialog): void {
    // dlg.maximize();

    if (this.zeugnissatz != null) {
      this.zeugnissatz.raster = this.benutzerRaster.raster;
      this.zeugnissatz.raster.headers = this.kopfItems.map((ki) => ki.line1 + '\n' + ki.line2 + '\n' + ki.line3);
    }
    this.previewZeugnis = this.zeugnis;
  }

  getHEItems(): Page[] {
    return [
      {
        label: 'Klasse 2\n2.Halbjahr',
        klassenstufe: 2,
        halbjahr: 1,
        code: '2_2',
        category: 'anlage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '2',
      },
      {
        label: 'Klasse 3\n1.Halbjahr',
        klassenstufe: 3,
        halbjahr: 0,
        code: '3_1',
        category: 'anlage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '3',
      },
      {
        label: 'Klasse 3\n2.Halbjahr',
        klassenstufe: 3,
        halbjahr: 1,
        code: '3_2',
        category: 'anlage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '3',
      },
      {
        label: 'Klasse 4\n1.Halbjahr',
        klassenstufe: 4,
        halbjahr: 0,
        code: '4_1',
        category: 'anlage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '4',
      },
      {
        label: 'Klasse 4\n2.Halbjahr',
        klassenstufe: 4,
        halbjahr: 1,
        code: '4_2',
        category: 'anlage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '4',
      },
    ];
  }

  getRPItems(): Page[] {
    return [
      {
        label: 'Klasse 1\nZeugnis',
        klassenstufe: 1,
        halbjahr: 1,
        code: '1',
        codeVorg: '1',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
      },
      {
        label: 'Klasse 2\nProtokoll',
        klassenstufe: 2,
        halbjahr: 1,
        code: '2_p',
        codeVorg: '2',
        category: 'protokoll',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
      },
      {
        label: 'Klasse 2\nZeugnis',
        klassenstufe: 2,
        halbjahr: 1,
        code: '2',
        codeVorg: '2',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
      },
      {
        label: 'Klasse 3\nProtokoll',
        klassenstufe: 3,
        halbjahr: 0,
        code: '3_p',
        codeVorg: '3',
        category: 'protokoll',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
      },
      {
        label: 'Klasse 3\nZeugnis',
        klassenstufe: 3,
        halbjahr: 1,
        code: '3',
        codeVorg: '3',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
      },
      {
        label: 'Klasse 4\nProtokoll',
        klassenstufe: 4,
        halbjahr: 0,
        code: '4_p',
        codeVorg: '4',
        category: 'protokoll',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
      },
      {
        label: 'Klasse 4\nZeugnis',
        klassenstufe: 4,
        halbjahr: 1,
        code: '4',
        codeVorg: '4',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
      },
    ];
  }

  getBWItems(): Page[] {
    return [
      {
        label: 'Klasse 1\n1.Halbjahr',
        klassenstufe: 1,
        halbjahr: 0,
        code: '1_1',
        category: 'beilage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '1',
      },
      {
        label: 'Klasse 1\n2.Halbjahr',
        klassenstufe: 1,
        halbjahr: 1,
        code: '1_2',
        category: 'beilage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '1',
      },
      {
        label: 'Klasse 2\n1.Halbjahr',
        klassenstufe: 2,
        halbjahr: 0,
        code: '2_1',
        category: 'beilage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '2',
      },
      {
        label: 'Klasse 2\n2.Halbjahr',
        klassenstufe: 2,
        halbjahr: 1,
        code: '2_2',
        category: 'beilage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '2',
      },
      {
        label: 'Klasse 3\n1.Halbjahr',
        klassenstufe: 3,
        halbjahr: 0,
        code: '3_1',
        category: 'beilage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '3',
      },
      {
        label: 'Klasse 3\n2.Halbjahr',
        klassenstufe: 3,
        halbjahr: 1,
        code: '3_2',
        category: 'beilage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '3',
      },
      {
        label: 'Klasse 4\n1.Halbjahr',
        klassenstufe: 4,
        halbjahr: 0,
        code: '4_1',
        category: 'beilage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '4',
      },
      {
        label: 'Klasse 4\n2.Halbjahr',
        klassenstufe: 4,
        halbjahr: 1,
        code: '4_2',
        category: 'beilage',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '4',
      },
    ];
  }

  getNIItems(): Page[] {
    return [
      {
        label: 'Klasse 1\n2.Halbjahr',
        klassenstufe: 1,
        halbjahr: 1,
        code: '1_2',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '1',
      },
      {
        label: 'Klasse 2\n1.Halbjahr',
        klassenstufe: 2,
        halbjahr: 0,
        code: '2_1',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '2',
      },
      {
        label: 'Klasse 2\n2.Halbjahr',
        klassenstufe: 2,
        halbjahr: 1,
        code: '2_2',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '2',
      },
      {
        label: 'Klasse 3\n1.Halbjahr',
        klassenstufe: 3,
        halbjahr: 0,
        code: '3_1',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '3',
      },
      {
        label: 'Klasse 3\n2.Halbjahr',
        klassenstufe: 3,
        halbjahr: 1,
        code: '3_2',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '3',
      },
      {
        label: 'Klasse 4\n1.Halbjahr',
        klassenstufe: 4,
        halbjahr: 0,
        code: '4_1',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '4',
      },
      {
        label: 'Klasse 4\n2.Halbjahr',
        klassenstufe: 4,
        halbjahr: 1,
        code: '4_2',
        category: 'zeugnis',
        disabled: this.dataLoading || this.hasAnyLoadingItem(),
        codeVorg: '4',
      },
    ];
  }

  getSortedBereiche(fach: KPFach | undefined) {
    if (fach == null) return [];
    return Array.from(fach.bereiche).sort((a, b) => ((a?.key ?? '') < (b?.key ?? '') ? -1 : 1));
  }

  expandAll() {
    this.faecherTree.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  collapseAll() {
    this.faecherTree.forEach((node) => {
      this.expandRecursive(node, false);
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  onFachNodeSelect(_event: any) {
    if (this.selectedFachNode?.children != null && this.selectedFachNode.children.length >= 1) {
      this.selectedFachNode.expanded = true;
      this.selectedFachNode = this.selectedFachNode.children[0];
    }
  }

  getAvailableItems() {
    let key = this.selectedFachNode?.key;
    if (key == null) {
      key = this.selectedFachNode?.parent?.key;
    }
    const items = this.availableItems[key ?? ''] as unknown as any[];
    const res: any[] = [];
    items?.forEach((i: KPItem) => {
      if ((this.selectedFachNode?.data?.items ?? []).find((item: KPItem) => item.text === i.text) == null) {
        res.push(i);
      }
    });
    return res;
  }

  getSourceOptions() {
    let key = this.selectedFachNode?.key;
    if (key == null) {
      key = this.selectedFachNode?.parent?.key;
    }
    const items = this.sourceOptions[key ?? ''] as unknown as any[];
    return items;
  }

  toggleCopyButton(event: any) {
    if (this.copyButton?.menu?.visible === false) {
      queueMicrotask(() => this.copyButton?.menu?.show(event));
    } else {
      this.copyButton?.menu?.hide(event);
    }
  }
}
