import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

export interface Size {
  width: number;
  height: number;
}

@Directive({ selector: '[fzSize]' })
export class SizeDirective implements OnChanges {
  @Input('fzSize') size: Size | undefined;
  constructor(private elementRef: ElementRef<HTMLElement>) {}
  ngOnChanges(): void {
    if (this.size != null) {
      this.elementRef.nativeElement.style.width = `${this.size.width}px`;
      this.elementRef.nativeElement.style.height = `${this.size.height}px`;
    }
  }
}
