import { AbstractControl, ValidatorFn } from '@angular/forms';

export function matchingValidator(controlName1: string, controlName2: string): ValidatorFn {
  return (control: AbstractControl) => {
    const control1 = control.get(controlName1);
    const control2 = control.get(controlName2);
    if (control1 == null) throw new Error(`missing control ${controlName1} in matchingValidator`);
    if (control2 == null) throw new Error(`missing control ${controlName2} in matchingValidator`);
    return control1?.value === control2?.value ? null : { notmatched: true };
  };
}
