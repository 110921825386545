<ng-template fzTemplate="sidebar">
  <fz-apply-kp></fz-apply-kp>
  <fz-ni-schueler-typ></fz-ni-schueler-typ>
  <div>
    <button
      class="mt-2"
      pButton
      type="button"
      [text]="true"
      label="Einrichtung des Kompetenzrasters"
      routerLink="/koennensprofil-editor"
    ></button>
  </div>
</ng-template>
<fz-formular
  name="NI12hjStandard"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
>
  <fz-ni-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-ni-zeugniskopf>
  <fz-ni-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-ni-zeugnistyp>
  <fz-ni-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-ni-zeugnissatz>
  <fz-ni-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-ni-schueler>
  <fz-ni-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-ni-versaeumnis>
  <fz-deco
    fzFormularItem="interessen"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.interessen.label" headerPaddingBottom="normal">
      <fz-intelli
        [field]="model.interessen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-section>
  </fz-deco>
  @for (fach of model.faecherMitBereichen; track fach.key) {
    <fz-deco
      [fzFormularItem]="fach.key"
      [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
      [padding]="formular.showBorder ? 'normal' : 'none'"
    >
      <fz-section [label]="fach.label" headerClass="Ueberschriften" headerPaddingBottom="normal">
        <fz-sequence gap="normal">
          @for (bereich of fach.bereiche; track bereich.key) {
            <fz-section [label]="bereich.label" headerPaddingBottom="normal">
              <fz-sequence>
                @if (bereich.kp != null) {
                  <fz-deco
                    [paddingTop]="bereich.kp.length > 0 ? 'normal' : 'none'"
                    [paddingBottom]="bereich.kp.length > 0 && bereich.textField != null ? 'normal' : 'none'"
                  >
                    <fz-kp [model]="bereich.kp" [bereich]="formular.getBereich(fach.key, bereich.key)"></fz-kp>
                  </fz-deco>
                }
                <fz-optional [field]="bereich.verbalVisibleField" label="Verbale Beurteilung">
                  <fz-verbal
                    *ngIf="bereich.textField != null"
                    [field]="bereich.textField"
                    [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
                  ></fz-verbal>
                </fz-optional>
              </fz-sequence>
            </fz-section>
          }
        </fz-sequence>
      </fz-section>
    </fz-deco>
  }
  <fz-deco
    fzFormularItem="arbeitsverhalten"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.lernArbeitsverhalten.label" headerPaddingBottom="normal">
      <fz-verbal
        [field]="model.lernArbeitsverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="sozialverhalten"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.sozialverhalten.label" headerPaddingBottom="normal">
      <fz-verbal
        [field]="model.sozialverhalten.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-verbal>
    </fz-section>
  </fz-deco>
  <fz-deco
    fzFormularItem="bemerkungen"
    [border]="formular.showBorder ? borderDirection.all : borderDirection.none"
    [padding]="formular.showBorder ? 'normal' : 'none'"
  >
    <fz-section [label]="model.bemerkungen.label" headerPaddingBottom="normal">
      <fz-intelli
        [field]="model.bemerkungen.textField"
        [width]="formular.showBorder ? formular.verbalWidthWithBorder : formular.verbalWidthWithoutBorder"
      ></fz-intelli>
    </fz-section>
  </fz-deco>
  <fz-ni-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-ni-unterschrift>
  <fz-ni-seitenfuss-last fzFormularItem="legende" fzContainer></fz-ni-seitenfuss-last>
</fz-formular>
