import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  Input,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  NgZone,
  Renderer2,
} from '@angular/core';
import { Content, ParseNewLineMode } from './content';
import { ContentProperties } from './content-properties';
import { NodeHelper, NodeWalker } from '../dom';
import { RichTextSelection } from '../projectables/rich-text-selection';
import { RichTextEditor } from './rich-text-editor';
import {
  Block,
  BlockFactory,
  BlockFactoryOwnerProvider,
  BlockFactoryProvider,
  BlockRange,
} from '../blocks/block-factory';
import { Line } from './line';
import { BlockDirective } from '../blocks/block.directive';
import { LineElement } from '../pdl/line-element';
import { MenuItem } from 'primeng/api';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { FocusableDirective } from '@modules/shared/focusable.directive';
import { StopWatch } from '@modules/shared/stop-watch';
import { CaretDirection, PointTarget } from '@modules/projectables/text-input.directive';
import { TextViewAccessor } from '@modules/projectables/text-view-accessor';
import { WorkspaceService } from '../../shared/services';
import { Subscription } from 'rxjs';
import { Option } from '@modules/shared/option';
import { FormularService } from '@modules/formular/formular.service';
import { Formular } from '@modules/formular/formular';
import { Field } from '../../models/fields/field';
import { ContextMenu } from 'primeng/contextmenu';
import { StringHelper } from '@modules/dom/string-helper';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { TextInputReplaceEvent } from '../projectables/text-input-replace-event';
import { getPlatformShortcut } from '../projectables/platform';
import { Zeugnis } from '../../models/zeugnis';

export interface Part {
  range: BlockRange;
  lines: Line[];
  rect: DOMRect;
}

export interface UndoRedoState {
  html: string | null;
  focus: number;
  anchor: number;
}

export interface UndoRedoStack {
  undoStack: UndoRedoState[];
  redoStack: UndoRedoState[];
}

@Component({
  selector: 'fz-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: [],
  providers: [provideInterfaceBy(BlockFactoryProvider, RichTextComponent)],
})
export class RichTextComponent implements OnInit, OnDestroy, AfterViewInit, BlockFactory {
  static readonly undoRedoStacks = new WeakMap<Zeugnis, Map<string, UndoRedoStack>>();

  @Input() placeholder = ' Kein Inhalt ';
  @Input() width = 0;
  @Input() isBlocksatz = false;
  @Input() field: Field | undefined;
  @Output() modelChange = new EventEmitter<string | null>();
  @Output() selectedHtmlChange = new EventEmitter<string>();
  @Output() hasFocusChange = new EventEmitter<boolean>();
  @ViewChildren(BlockDirective) blocks = new QueryList<BlockDirective>();
  @ViewChildren('lineElement') lineElementRef = new QueryList<ElementRef<LineElement>>();
  @ViewChildren(FocusableDirective) focusables = new QueryList<FocusableDirective>();
  @ViewChild('cnt') modelRef!: ElementRef<HTMLElement>;
  @ViewChild(ContextMenu) contextMenu: ContextMenu | undefined;

  private readonly eventRemovers: (() => void)[] = [];
  readonly contentProperties: ContentProperties = new ContentProperties();
  #content?: Content;
  parts: Part[] = [];
  readonly selection: RichTextSelection = new RichTextSelection();
  #fragment: DocumentFragment;
  #fragmentLength;
  // #subscriptionStyleChanged: Subscription;
  readonly #subscriptionRecalculateHeight: Subscription;
  afterInit = false;
  fontSizeFactorOptions: Option<number>[] = [];
  isMenuExpanded = false;
  menuItemToggle: MenuItem;
  menuItemsExpanded: MenuItem[] = [
    {
      icon: 'pi pi-undo',
      command: (): void => {
        this.isMenuExpanded = false;
        this.undo();
      },
      // disabled: this.undoStack.length === 0,
    },
    {
      icon: 'pi pi-refresh',
      command: (): void => {
        this.isMenuExpanded = false;
        this.redo();
      },
      // disabled: this.redoStack.length === 0,
    },
    {
      label: 'Zwischenablage',
      items: [
        {
          icon: 'fa fa-scissors',
          label: 'Ausschneiden',
          command: (): void => {
            this.isMenuExpanded = false;
            this.cut();
          },
        },
        {
          icon: 'fa fa-copy',
          label: 'Kopieren',
          command: (): void => {
            this.isMenuExpanded = false;
            this.copy();
          },
        },
        {
          icon: 'fa fa-clipboard',
          label: 'Einfügen',
          command: (): void => {
            this.isMenuExpanded = false;
            this.paste();
          },
        },
      ],
    },
    {
      label: 'Sonderzeichen',
      items: [
        {
          label: '¹',
          command: (): void => {
            this.isMenuExpanded = false;
            this.insertText('¹');
          },
        },
        {
          label: '²',
          command: (): void => {
            this.isMenuExpanded = false;
            this.insertText('²');
          },
        },
        {
          label: '³',
          command: (): void => {
            this.isMenuExpanded = false;
            this.insertText('³');
          },
        },
      ],
    },
    {
      label: 'Einpassen',
      items: [
        {
          label: 'Auf 1 Seite',
          command: (): void => {
            this.isMenuExpanded = false;
            this.formularService.requestFit(1);
            this.updateSelection();
          },
        },
        {
          label: 'Auf 2 Seiten',
          command: (): void => {
            this.isMenuExpanded = false;
            this.formularService.requestFit(2);
            this.updateSelection();
          },
        },
        {
          label: 'Auf 3 Seiten',
          command: (): void => {
            this.isMenuExpanded = false;
            this.formularService.requestFit(3);
            this.updateSelection();
          },
        },
        {
          label: 'Auf 4 Seiten',
          command: (): void => {
            this.isMenuExpanded = false;
            this.formularService.requestFit(4);
            this.updateSelection();
          },
        },
      ],
    },
  ];
  contextMenuItems = [
    {
      label: `Rückgängig${getPlatformShortcut('z')}`,
      command: (): void => {
        this.undo();
      },
    },
    {
      label: `Wiederholen${getPlatformShortcut('y')}`,
      command: (): void => {
        this.redo();
      },
    },
    { separator: true },
    {
      label: `Ausschneiden${getPlatformShortcut('x')}`,
      command: (): void => {
        this.cut();
      },
    },
    {
      label: `Kopieren${getPlatformShortcut('c')}`,
      command: (): void => {
        this.copy();
      },
    },
    {
      label: `Einfügen${getPlatformShortcut('v')}`,
      command: (): void => {
        this.paste();
      },
    },
    { separator: true },
    {
      label: `Alles markieren${getPlatformShortcut('a')}`,
      command: (): void => {
        this.onSelectAll();
      },
    },
  ];

  constructor(
    public changeDetector: ChangeDetectorRef,
    private readonly zone: NgZone,
    private readonly renderer: Renderer2,
    private readonly ownerProvider: BlockFactoryOwnerProvider,
    private readonly elementRef: ElementRef,
    private readonly workspace: WorkspaceService,
    private readonly formularService: FormularService<Formular>,
    private readonly notificationService: NotificationService
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.menuItemToggle = {
      get icon() {
        return that.isMenuExpanded ? 'pi pi-times' : 'pi pi-ellipsis-v';
      },
      command: (): void => {
        this.isMenuExpanded = !this.isMenuExpanded;
        this.updateSelection();
      },
    };
    for (let factor = 70; factor <= 200; factor += 5) {
      this.fontSizeFactorOptions.push({
        value: factor,
        label: `${factor}%`,
      });
    }
    // this.contentProperties.classCandidates = textOptionsService.classCandidates.map((cc) => cc.cssClass);
    // this.contentProperties.defaultClass = textOptionsService.defaultCssClass;
    const element = document.createElement('div');
    element.innerHTML = '';
    const editor = new RichTextEditor(NodeHelper.cloneContents(element));
    editor.normalize(
      ParseNewLineMode.paragraphTagAndLineFeed,
      this.contentProperties.classCandidates,
      this.contentProperties.defaultClass
    );
    this.#fragment = editor.fragment;
    this.#fragmentLength = NodeHelper.getLength(editor.fragment);
    this.#content = undefined;

    // this.#subscriptionStyleChanged = this.schuelerService.styleChanged.subscribe(() => {
    //   this.#content = undefined;
    //   this.ownerProvider.provided.heightChange(this);
    //   this.changeDetector.detectChanges();
    // });
    this.#subscriptionRecalculateHeight = this.formularService.recalculateHeightRequested.subscribe(() => {
      this.#content = undefined;
      this.changeDetector.detectChanges();
    });
    this.selection.changed.subscribe(() => {
      this.isMenuExpanded = false;
      // this.selectedHtmlChange.next();
      // const style = this.getSelectionStyle();
      // textOptionsService.textOptionsState.next({
      //   bold: style.fontWeight !== RichTextEditor.defaultFontWeight,
      //   italic: style.fontStyle !== RichTextEditor.defaultFontStyle,
      //   strike: style.textDecorationLine?.includes('line-through') ?? false,
      //   underline: style.textDecorationLine?.includes('underline') ?? false,
      //   superscript: style.verticalAlign === 'super',
      //   subscript: style.verticalAlign === 'sub',
      //   cssClass: textOptionsService.defaultCssClass,
      // });
    });
    // textOptionsService.textOptionsAction.subscribe((_textOptions) => {
    // const style: Partial<CSSStyleDeclaration> = {};
    // if (textOptions.bold) style.fontWeight = 'bold';
    // this.setStyle(style);
    // });
  }

  get formular(): Formular {
    return this.formularService.formular;
  }

  @Input() get model(): string | null {
    const element = document.createElement('div');
    element.appendChild(NodeHelper.cloneContents(this.#fragment));
    const html = element.innerHTML;
    return html === '<p></p>' ? null : html;
  }

  set model(html: string | null) {
    if (html !== this.model) {
      const element = document.createElement('div');
      element.innerHTML = StringHelper.removeSpecialCharacters(html) ?? '';
      const editor = new RichTextEditor(NodeHelper.cloneContents(element));
      editor.normalize(
        ParseNewLineMode.paragraphTagAndLineFeed,
        this.contentProperties.classCandidates,
        this.contentProperties.defaultClass
      );
      this.fragment = editor.fragment;
    }
  }

  get text(): string {
    const element = document.createElement('div');
    element.appendChild(NodeHelper.cloneContents(this.#fragment));
    return element.innerText;
  }

  get fragment(): DocumentFragment {
    return this.#fragment;
  }

  set fragment(fragment: DocumentFragment) {
    const oldHeight = this.content.height;
    const oldLength = this.content.lines.length;
    this.#fragment = fragment;
    this.#fragmentLength = NodeHelper.getLength(fragment);
    this.#content = undefined;
    if (this.content.height !== oldHeight || this.content.lines.length !== oldLength)
      this.ownerProvider.provided.invalidate();
    else this.updatePartLines();
    const html = this.model;
    this.modelChange.emit(html);
    this.changeDetector.detectChanges();
  }

  get selectedHtml(): string {
    const editor = new RichTextEditor(this.#fragment);
    return editor.getHtml(this.selection.start, this.selection.length);
  }
  get content(): Content {
    if (this.#content == null) {
      this.#content = Content.from(
        NodeHelper.cloneContents(this.#fragment),
        this.contentProperties,
        this.elementRef.nativeElement
      );
    }
    return this.#content;
  }
  get lineCount(): number {
    return this.content.lines.length;
  }
  get hasFocus(): boolean {
    const hasFocus = this.focusables.some((f) => f.hasFocus);
    // if (!hasFocus) this.isMenuExpanded = false;
    return hasFocus;
  }
  set hasFocus(value: boolean) {
    this.hasFocusChange.emit(value);
    this.changeDetector.detectChanges();
  }

  get fontSizeFactor(): number {
    return this.formular.fontSizeFactor ?? 100;
  }
  set fontSizeFactor(value: number) {
    if (value !== this.fontSizeFactor) {
      this.isMenuExpanded = false;
      this.formular.fontSizeFactor = value;
      this.formularService.raiseStyleChange();
    }
  }

  isPartSelected(part: Part): boolean {
    const selectedLines = part.lines.filter(
      (line) => line.start <= this.selection.focus && this.selection.focus <= line.start + line.length
    );
    return selectedLines.length > 0;
  }

  getSelectedLine(part: Part): Line {
    const selectedLines = part.lines.filter(
      (line) => line.start <= this.selection.focus && this.selection.focus <= line.start + line.length
    );
    return selectedLines[0];
  }

  getSelectionStyle(): Partial<CSSStyleDeclaration> {
    const editor = new RichTextEditor(this.#fragment);
    return editor.getStyle(this.selection.start, this.selection.length);
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.renderer.listen(document, 'selectionchange', (e) => this.onSelectionChange(e));
    });
    this.contentProperties.width = this.width;
  }
  ngOnDestroy(): void {
    for (const eventRemover of this.eventRemovers) {
      eventRemover();
    }
    // this.#subscriptionStyleChanged.unsubscribe();
    this.#subscriptionRecalculateHeight.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
    if (this.modelRef.nativeElement.childNodes.length > 0) {
      const editor = new RichTextEditor(NodeHelper.cloneContents(this.modelRef.nativeElement));
      editor.normalize(
        ParseNewLineMode.paragraphTagAndLineFeed,
        this.contentProperties.classCandidates,
        this.contentProperties.defaultClass
      );
      this.fragment = editor.fragment;
    } else {
      this.#content = undefined;
    }
  }

  project(): void {
    /* */
  }
  getBlockCount(): number {
    if (!this.afterInit) {
      this.afterInit = true;
      this.#content = undefined;
    }
    // console.log('richtext getBlockCount: ' + this.content.lines.length);
    return this.content.lines.length;
  }
  measureHeight(range: BlockRange): number {
    const h = this.content.lines
      .slice(range.start, range.start + range.length)
      .reduce((height, line) => height + line.height, 0);
    // console.log(`richtext measureHeight(${range.start}, ${range.length}): ${h}`);
    return h;
  }
  layout(ranges: BlockRange[]): Block[] {
    this.parts = ranges.map((range) => ({
      range,
      lines: [],
      rect: new DOMRect(),
    }));
    this.updatePartLines();
    this.changeDetector.detectChanges();
    return this.blocks.toArray();
  }

  onCopy(e: DataTransfer): void {
    if (this.selection.length > 0) {
      const editor = new RichTextEditor(this.#fragment);
      const text = editor.getText(this.selection.start, this.selection.length);
      const html = editor.getHtml(this.selection.start, this.selection.length);
      e.clearData();
      e.setData('text/plain', text);
      e.setData('text/html', html);
      e.setData('text/fzhtml', html);
    }
  }

  onPaste(e: DataTransfer): void {
    let data = e.getData('text/fzhtml');
    if (data === '') data = e.getData('text/plain');
    if (data !== '') {
      const div = document.createElement('div');
      div.innerHTML = StringHelper.removeSpecialCharacters(data) ?? '';
      this.insertFragement(NodeHelper.cloneContents(div), div.innerText.length);
    }
  }

  onInsert(e: string): void {
    e = StringHelper.removeSpecialCharacters(e) ?? '';
    // console.log('insert: "' + e + '"');
    StopWatch.resetAll();
    StopWatch.start('insert');
    const editor = new RichTextEditor(this.#fragment);
    StopWatch.start('deleteSelection');
    if (this.selection.length > 0) editor.delete(this.selection.start, this.selection.length);
    StopWatch.stop('deleteSelection');
    StopWatch.start('insertText');
    editor.insertText(this.selection.start, e);
    StopWatch.stop('insertText');
    StopWatch.start('createContent');
    editor.normalize(
      ParseNewLineMode.lineFeedOnly,
      this.contentProperties.classCandidates,
      this.contentProperties.defaultClass
    );
    this.setFragmentAndNotify(editor.fragment);
    this.selection.setPosition(this.selection.start + 1);
    // console.log('Caret: ' + this.selection.start);
    StopWatch.stop('createContent');
    StopWatch.stop('insert');
    this.updateSelection(false);

    console.log(StopWatch.toString());
  }

  onReplace(e: TextInputReplaceEvent): void {
    const text =
      StringHelper.removeSpecialCharacters(
        this.lineElements.map((l) => `${l.textContent}${l.implicitLastChar}`).join('')
      ) ?? '';
    // console.log('replace text: "' + text + '"');
    // console.log('replace moveCaret: ' + e.moveCaret);
    StopWatch.resetAll();
    StopWatch.start('replace');
    const editor = new RichTextEditor(this.#fragment);
    StopWatch.start('replaceText');
    editor.delete(0, this.#fragmentLength);
    editor.insertText(0, text);
    StopWatch.stop('replaceText');
    StopWatch.start('createContent');
    editor.normalize(
      ParseNewLineMode.lineFeedOnly,
      this.contentProperties.classCandidates,
      this.contentProperties.defaultClass
    );
    this.setFragmentAndNotify(editor.fragment);
    if (e.moveCaret != null && this.selection.start + e.moveCaret < text.length) {
      this.selection.setPosition(this.selection.start + e.moveCaret);
    } else {
      this.selection.setPosition(text.length);
    }
    // console.log('Caret: ' + this.selection.start);
    StopWatch.stop('createContent');
    StopWatch.stop('replace');
    this.updateSelection(false);

    console.log(StopWatch.toString());
  }

  onDelete(): void {
    const editor = new RichTextEditor(this.#fragment);
    const selectionStartIndex = this.selection.start;
    if (this.selection.length > 0) {
      editor.delete(this.selection.start, this.selection.length);
    } else if (this.selection.focus < this.#fragmentLength) {
      editor.delete(this.selection.focus, 1);
    } else return;
    editor.normalize(
      ParseNewLineMode.lineFeedOnly,
      this.contentProperties.classCandidates,
      this.contentProperties.defaultClass
    );
    this.setFragmentAndNotify(editor.fragment);
    this.selection.setPosition(selectionStartIndex);
    this.updateSelection(false);
  }

  onBackspace(): void {
    const editor = new RichTextEditor(this.#fragment);
    const selectionStartIndex = this.selection.start;
    let focus = 0;
    if (this.selection.length > 0) {
      editor.delete(this.selection.start, this.selection.length);
      focus = selectionStartIndex;
    } else if (this.selection.focus > 0) {
      editor.delete(this.selection.focus - 1, 1);
      focus = selectionStartIndex - 1;
    } else return;
    editor.normalize(
      ParseNewLineMode.lineFeedOnly,
      this.contentProperties.classCandidates,
      this.contentProperties.defaultClass
    );
    this.setFragmentAndNotify(editor.fragment);
    this.selection.setFocus(focus);
    this.selection.collapseToFocus();
    this.updateSelection(true);
  }

  onSelectAll(): void {
    this.selection.setBaseAndExtend(0, this.#fragmentLength);
    this.updateSelection(false);
  }

  onSelectWord(e: PointTarget): void {
    const textView = new TextViewAccessor(this.lineElements);
    const index = textView.getIndexByPoint(e);
    if (index != null) {
      const walker = new NodeWalker(this.#fragment);
      let it = walker.generateBeforeCharacterPositionsStartingWith(index);
      let result = it.next();
      while (!result.done && ![' ', '\n'].includes(result.value.char)) result = it.next();
      const endIndex = result.done ? this.#fragmentLength : result.value.index;
      it = walker.generateBeforeCharacterPositionsReverseStartingWith(this.#fragmentLength - index);
      result = it.next();
      while (!result.done && ![' ', '\n'].includes(result.value.char)) result = it.next();
      const startIndex = result.done ? 0 : this.#fragmentLength - result.value.index;
      // it = walker.generateBeforeCharacterPositionsStartingWith(index);
      // result = it.next();
      // if (startIndex < endIndex && !result.done && result.value.char === ' ') startIndex++;
      this.selection.setBaseAndExtend(startIndex, endIndex);
      this.updateSelection();
    }
  }

  onMoveCaret(e: { direction: CaretDirection; isSelect: boolean }): void {
    const [index, left] = this.getIndexByDirection(e.direction);
    if (left != null) this.selection.setFocusIndexAndLeft(index, left);
    else this.selection.setFocus(index);
    if (!e.isSelect) this.selection.collapseToFocus();
    this.updateSelection(false);
  }

  onPointCaret(e: PointTarget & { isSelect: boolean }): void {
    const textView = new TextViewAccessor(this.lineElements);
    if (!e.isSelect || this.hasFocus) {
      const index = textView.getIndexByPoint(e);
      if (index != null) {
        this.selection.setFocus(index);
        if (!e.isSelect) this.selection.collapseToFocus();
        this.updateSelection();
      }
    }
  }

  // @HostListener('document:selectionchange') // wird in OnInit registriert
  onSelectionChange(_e: Event) {
    const selection = document.getSelection();
    if (selection != null && selection.focusNode != null && selection.anchorNode != null) {
      const textView = new TextViewAccessor(this.lineElements);
      const focusElement = textView.findTextElement(selection.focusNode);
      const anchorElement = textView.findTextElement(selection.anchorNode);
      if (
        focusElement != null &&
        this.lineElements.includes(focusElement) &&
        anchorElement != null &&
        this.lineElements.includes(anchorElement)
      ) {
        const focusIndex =
          focusElement.getIndexByNodeAndOffset(selection.focusNode, selection.focusOffset) +
          focusElement.textRange.start;
        const anchorIndex =
          anchorElement.getIndexByNodeAndOffset(selection.anchorNode, selection.anchorOffset) +
          anchorElement.textRange.start;
        if (this.selection.focus !== focusIndex || this.selection.anchor !== anchorIndex) {
          console.log('selectionchange');
          this.selection.setBaseAndExtend(anchorIndex, focusIndex);
          this.updateSelection(false);
        }
      }
    }
  }

  onSetClass(_e: number): void {
    // if (this.#selection.isCollapsed) {
    //   this.setParagraphClassName(
    //     this.#selection.focus,
    //     e === 0 ? this.contentProperties.defaultClass : this.contentProperties.classCandidates[e - 1]
    //   );
    // }
  }

  onMoveSelection(e: PointTarget): void {
    const textView = new TextViewAccessor(this.lineElements);
    const textElement = textView.findTextElement(e.target);
    if (textElement != null) {
      let dragIndex = textElement.textRange.start + textElement.getNearestIndexWrtClient(e.clientX);
      if (dragIndex > this.selection.start + this.selection.length) dragIndex -= this.selection.length;
      const editor = new RichTextEditor(this.#fragment);
      const moveFragment = editor.cloneFragment(this.selection.start, this.selection.length);
      editor.delete(this.selection.start, this.selection.length);
      editor.insertFragement(dragIndex, moveFragment);
      editor.normalize(
        ParseNewLineMode.lineFeedOnly,
        this.contentProperties.classCandidates,
        this.contentProperties.defaultClass
      );
      this.setFragmentAndNotify(editor.fragment);
      this.selection.setPosition(dragIndex + this.selection.length);
      this.updateSelection(false);
    }
  }

  onContextmenu(e: MouseEvent): boolean {
    this.updateSelection();
    this.contextMenu?.toggle(e);
    return false;
  }

  onFocusin(_e: FocusEvent): void {
    this.updateSelection();
  }

  onUndoRedo(e: { action: 'undo' | 'redo' }): void {
    if (e.action === 'undo') this.undo();
    if (e.action === 'redo') this.redo();
  }

  cut(): void {
    this.updateSelection();
    document.execCommand('cut');
  }
  copy(): void {
    this.updateSelection();
    document.execCommand('copy');
  }
  paste(): void {
    this.updateSelection();
    if (navigator.clipboard.readText != null) {
      navigator.clipboard.readText().then((text) => {
        text = StringHelper.removeSpecialCharacters(text) ?? '';
        const fragment = document.createDocumentFragment();
        const textNode = document.createTextNode(text);
        fragment.appendChild(textNode);
        this.insertFragement(fragment, text.length);
      });
    } else {
      this.notificationService.showPermanentInfo(
        'Das Einfügen über das Menü wird in Firefox nicht unterstützt. Bitte verwenden Sie zum Einfügen die Tastenkombination Strg-v (Windows) bzw. Cmd-v (Apple).',
        'Hinweis'
      );
      // document.execCommand('paste');
      // if (this.lineElements.length > 0) this.lineElements[0].ownerDocument.execCommand('paste');
    }
  }

  insertText(text: string): void {
    const fragment = document.createDocumentFragment();
    fragment.append(document.createTextNode(text));
    this.insertFragement(fragment, text.length);
  }

  undo(): void {
    const { undoStack, redoStack } = this.undoRedoStack;
    const state = undoStack.pop();
    if (state != null) {
      redoStack.push({
        html: this.model,
        focus: this.selection.focus,
        anchor: this.selection.anchor,
      });
      this.setUndoRedoHtml(state);
    }
  }

  redo(): void {
    const { undoStack, redoStack } = this.undoRedoStack;
    const state = redoStack.pop();
    if (state != null) {
      undoStack.push({
        html: this.model,
        focus: this.selection.focus,
        anchor: this.selection.anchor,
      });
      this.setUndoRedoHtml(state);
    }
  }

  insertSatz(satz?: string): void {
    if (satz != null) {
      const prevChar = this.text[this.selection.start - 1];
      const nextChar = this.text[this.selection.end];
      if (![undefined, ' ', '\n'].includes(prevChar)) this.insertText(' ');
      this.insertText(satz);
      if (![undefined, ' ', '\n'].includes(nextChar)) this.insertText(' ');
    }
  }

  trackByIndex(index: number, _item: unknown): unknown {
    return index;
  }

  private get zeugnis() {
    return this.workspace.selectedZeugnis;
  }

  private setUndoRedoHtml(state: UndoRedoState) {
    const element = document.createElement('div');
    element.innerHTML = state.html ?? '';
    const editor = new RichTextEditor(NodeHelper.cloneContents(element));
    editor.normalize(
      ParseNewLineMode.paragraphTagAndLineFeed,
      this.contentProperties.classCandidates,
      this.contentProperties.defaultClass
    );
    const oldHeight = this.content.height;
    const oldLength = this.content.lines.length;
    this.#fragment = editor.fragment;
    this.#fragmentLength = NodeHelper.getLength(editor.fragment);
    this.#content = undefined;
    if (this.content.height !== oldHeight || this.content.lines.length !== oldLength)
      this.ownerProvider.provided.invalidate();
    else this.updatePartLines();
    this.modelChange.emit(this.model);
    this.changeDetector.detectChanges();
    this.selection.setBaseAndExtend(state.anchor, state.focus);
    this.updateSelection();
  }

  private get undoRedoStack(): UndoRedoStack {
    if (this.zeugnis == null || this.field == null) return { undoStack: [], redoStack: [] };
    if (!RichTextComponent.undoRedoStacks.has(this.zeugnis)) {
      RichTextComponent.undoRedoStacks.set(this.zeugnis, new Map<string, UndoRedoStack>());
    }
    const zeugnisStacks = RichTextComponent.undoRedoStacks.get(this.zeugnis)!;
    if (!zeugnisStacks.has(this.field.key)) {
      zeugnisStacks.set(this.field.key, { undoStack: [], redoStack: [] });
    }
    return zeugnisStacks.get(this.field.key)!;
  }

  private addToUndoStack(): void {
    const { undoStack, redoStack } = this.undoRedoStack;
    undoStack.push({ html: this.model, focus: this.selection.focus, anchor: this.selection.anchor });
    if (undoStack.length > 1000) undoStack.shift();
    redoStack.splice(0, redoStack.length);
  }

  private setFragmentAndNotify(fragment: DocumentFragment): void {
    this.addToUndoStack();
    this.fragment = fragment;
  }

  private get lineElements(): LineElement[] {
    return this.lineElementRef.toArray().map((ref) => ref.nativeElement);
  }

  private getIndexByDirection(direction: CaretDirection): [number, number?] {
    const textView = new TextViewAccessor(this.lineElements);
    switch (direction) {
      case CaretDirection.right:
        return this.selection.focus < this.#fragmentLength ? [this.selection.focus + 1] : [this.selection.focus];
      case CaretDirection.wordRight:
        return [this.getNextWordIndex()];
      case CaretDirection.left:
        return this.selection.focus > 0 ? [this.selection.focus - 1] : [this.selection.focus];
      case CaretDirection.wordLeft:
        return [this.getPrevWordIndex()];
      case CaretDirection.down: {
        const textElement = textView.getTextElementByIndex(this.selection.focus);
        if (textElement != null) {
          const nextTextElement = textView.getNextTextElement(textElement);
          if (nextTextElement != null) {
            const left =
              this.selection.focusLeft ??
              textElement.getLeftByIndex(this.selection.focus - textElement.textRange.start);
            return [nextTextElement.textRange.start + nextTextElement.getNearestIndex(left), left];
          }
        }
        return [this.selection.focus];
      }
      case CaretDirection.up: {
        const textElement = textView.getTextElementByIndex(this.selection.focus);
        if (textElement != null) {
          const prevTextElement = textView.getPrevTextElement(textElement);
          if (prevTextElement != null) {
            const left =
              this.selection.focusLeft ??
              textElement.getLeftByIndex(this.selection.focus - textElement.textRange.start);
            return [prevTextElement.textRange.start + prevTextElement.getNearestIndex(left), left];
          }
        }
        return [this.selection.focus];
      }
      case CaretDirection.contentHome:
        return [0];
      case CaretDirection.lineHome: {
        const textElement = textView.getTextElementByIndex(this.selection.focus);
        return textElement != null ? [textElement.textRange.start] : [this.selection.focus];
      }
      case CaretDirection.contentEnd:
        return [this.#fragmentLength];
      case CaretDirection.lineEnd: {
        const textElement = textView.getTextElementByIndex(this.selection.focus);
        return textElement != null
          ? [textElement.textRange.start + textElement.textRange.length]
          : [this.selection.focus];
      }
      default:
        return [this.selection.focus];
    }
  }

  private isLetterOrDigit(char: string) {
    return char.match(/^[0-9a-zA-ZäöüßÄÖÜ]$/) != null;
  }

  private getNextWordIndex() {
    const walker = new NodeWalker(this.#fragment);
    const it = walker.generateBeforeCharacterPositionsStartingWith(this.selection.focus);
    let result = it.next();
    if (!result.done) {
      if (result.value.char === '\n') result = it.next();
      else {
        if (this.isLetterOrDigit(result.value.char)) {
          while (!result.done && this.isLetterOrDigit(result.value.char)) result = it.next();
        } else {
          while (
            !result.done &&
            result.value.char !== ' ' &&
            result.value.char !== '\n' &&
            !this.isLetterOrDigit(result.value.char)
          )
            result = it.next();
        }
        while (!result.done && result.value.char === ' ') result = it.next();
      }
    }
    return result.done ? this.#fragmentLength : result.value.index;
  }

  private getPrevWordIndex() {
    const walker = new NodeWalker(this.#fragment);
    const it = walker.generateBeforeCharacterPositionsReverseStartingWith(this.#fragmentLength - this.selection.focus);
    let result = it.next();
    if (!result.done) {
      if (result.value.char === '\n') result = it.next();
      else {
        while (!result.done && result.value.char === ' ') result = it.next();
        if (!result.done && this.isLetterOrDigit(result.value.char)) {
          while (!result.done && this.isLetterOrDigit(result.value.char)) result = it.next();
        } else {
          while (
            !result.done &&
            result.value.char !== ' ' &&
            result.value.char !== '\n' &&
            !this.isLetterOrDigit(result.value.char)
          )
            result = it.next();
        }
      }
    }
    return result.done ? 0 : this.#fragmentLength - result.value.index;
  }

  private insertFragement(insertFragment: DocumentFragment, fragmentLength: number) {
    const editor = new RichTextEditor(this.#fragment);
    if (this.selection.length > 0) editor.delete(this.selection.start, this.selection.length);
    editor.insertFragement(this.selection.start, insertFragment);
    editor.normalize(
      ParseNewLineMode.lineFeedOnly,
      this.contentProperties.classCandidates,
      this.contentProperties.defaultClass
    );
    this.setFragmentAndNotify(editor.fragment);
    this.selection.setPosition(this.selection.start + fragmentLength);
    this.updateSelection(false);
  }

  private setParagraphClassName(index: number, className: string) {
    const editor = new RichTextEditor(this.#fragment);
    editor.setParagraphClassName(index, className);
    editor.normalize(
      ParseNewLineMode.lineFeedOnly,
      this.contentProperties.classCandidates,
      this.contentProperties.defaultClass
    );
    this.setFragmentAndNotify(editor.fragment);
    this.updateSelection(false);
  }

  private setStyle(style: Partial<CSSStyleDeclaration>) {
    const editor = new RichTextEditor(this.#fragment);
    editor.setStyle(this.selection.start, this.selection.length, style);
    editor.normalize(
      ParseNewLineMode.lineFeedOnly,
      this.contentProperties.classCandidates,
      this.contentProperties.defaultClass
    );
    this.setFragmentAndNotify(editor.fragment);
    this.updateSelection(false);
  }

  private updateSelection(scrollIntoView?: boolean | null) {
    this.changeDetector.detectChanges();
    queueMicrotask(() => {
      const textView = new TextViewAccessor(this.lineElements);
      textView.updateSelection(this.selection.anchor, this.selection.focus, scrollIntoView);
      this.changeDetector.detectChanges(); // für tabindex
    });
  }

  private updatePartLines() {
    for (const part of this.parts) {
      const lines = this.content.lines.slice(part.range.start, part.range.start + part.range.length);
      part.lines.splice(0);
      part.lines.push(...lines);
      const height = lines.map((l) => l.height).reduce((prev, curr) => prev + curr, 0);
      part.rect = new DOMRect(0, 0, this.width, height);
    }
  }
}
