import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { NIFormular } from './ni-formular';
import { NIZeugnisFormular } from './ni-zeugnis-formular';
import { NIZeugnistypComponent } from '../projectables/ni-zeugnistyp.component';
import { NIZeugnissatzComponent } from '../projectables/ni-zeugnissatz.component';
import { NISchuelerComponent } from '../projectables/ni-schueler.component';
import { NIVersaeumnisComponent } from '../projectables/ni-versaeumnis.component';
import { NIUnterschriftGeistigComponent } from '../projectables/ni-unterschrift-geistig.component';
import { FormularViewType } from '../../formular/formular';
import NIFachKey from '../../../models/generated/ni-fach-key';

export class NI1234hjGeistigFormular extends NIZeugnisFormular {
  get viewType(): FormularViewType {
    return NI1234hjGeistigFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    if (this.zeugnis.zeugnissatz.schuljahr >= 2023) {
      faecher.push(
        this.personaleBildung,
        this.geistigDeutsch,
        this.mathematik,
        this.sachunterricht,
        this.geistigGestalten,
        this.musik,
        this.geistigSport,
        this.fremdsprache,
        this.religion
      );
    } else {
      faecher.push(
        this.geistigDeutsch,
        this.mathematik,
        this.sachunterricht,
        this.geistigSport,
        this.musik,
        this.geistigGestalten,
        this.geistigWerken,
        this.religion,
        this.geistigWerteNormen
      );
    }
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    return noteFields;
  }
}

@Component({
  selector: 'fz-ni-1234hj-geistig-formular',
  templateUrl: 'ni-1234hj-geistig-formular.component.html',
})
export class NI1234hjGeistigFormularComponent extends FormularComponentBase<NI1234hjGeistigFormular> {
  static createModel(formular: NIFormular) {
    return {
      zeugnistyp: NIZeugnistypComponent.createModel(formular),
      zeugnissatz: NIZeugnissatzComponent.createModel(formular),
      schueler: NISchuelerComponent.createModel(formular),
      versaeumnis: NIVersaeumnisComponent.createModel(formular),
      faecher: formular.faecher.map((fach) => ({
        trackBy: fach.key,
        key: fach.key,
        label: fach.key === NIFachKey.fremdsprache ? 'Englisch' : fach.gesamt.description.label,
        textField: fach.gesamt.text,
      })),
      arbeitsgemeinschaften: {
        label: formular.arbeitsgemeinschaften.description.label,
        textField: formular.arbeitsgemeinschaften,
      },
      interessen: {
        label: 'Besondere Interessen und Fähigkeiten',
        textField: formular.interessen,
      },
      lernArbeitsverhalten: {
        label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
      },
      sozialverhalten: {
        label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
      },
      bemerkungen: {
        label: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      unterschrift: NIUnterschriftGeistigComponent.createModel(formular),
    };
  }

  get model() {
    return NI1234hjGeistigFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<NI1234hjGeistigFormular>) {
    super(formularService);
  }
}
