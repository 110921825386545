import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableDecoratorBase } from './projectable';
import moment from 'moment';
import { InputComponent } from './input.component';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Calendar } from 'primeng/calendar';
import { DateField } from '../../models/fields/date-field';

@Component({
  selector: 'fz-date',
  templateUrl: 'date.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, DateComponent)],
})
export class DateComponent extends ProjectableDecoratorBase implements AfterViewChecked, Projectable {
  @Input() placeholder = 'Datum';

  @Input() field: DateField<any> | undefined;
  @Output() modelChange = new EventEmitter<Date | null>();
  @ViewChild(InputComponent) input: InputComponent | undefined;
  @ViewChild(Calendar) calendar: Calendar | undefined;

  #text = '';
  today = new Date();

  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private changeDetector: ChangeDetectorRef
  ) {
    super(elementRef, parentProvider);
  }

  @Input()
  get model(): Date | null {
    if (this.#text === '') return null;
    const date = moment(this.#text, 'DD.MM.YYYY');
    return date.isValid() ? date.toDate() : null;
  }
  set model(value: Date | null) {
    if (value != null) this.text = moment(value).format('DD.MM.YYYY');
    else this.text = '';
  }

  get text(): string {
    return this.#text;
  }
  set text(value: string) {
    if (this.#text !== value) {
      const oldModel = this.model;
      this.#text = value;
      if (this.field != null) this.field.value = this.model;
      if (this.model?.getTime() !== oldModel?.getTime()) this.modelChange.emit(this.model);
    }
  }

  ngAfterViewChecked(): void {
    if (this.field != null && this.field.value?.getTime() !== this.model?.getTime()) this.model = this.field.value;
  }

  onHasFocusChange(hasFocus: boolean): void {
    if (hasFocus) {
      this.changeDetector.detectChanges();
      if (this.calendar != null) this.calendar.preventFocus = true;
    } else {
      // eslint-disable-next-line no-self-assign
      this.model = this.model;
      this.input?.selection?.setPosition(this.#text.length);
    }
  }

  setCalendarDate(date: Date): void {
    this.model = date;
    this.input?.selection?.setPosition(this.#text.length);
    this.input?.updateSelection();
  }

  keyFilter(e: string): boolean {
    return (e >= '0' && e <= '9') || e === '.';
  }
}
