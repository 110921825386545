import { Component, Input } from '@angular/core';
import { WorkspaceService } from '../../../shared/services';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { FormularFactory } from '../formular-factory';
import { RichTextField } from '../../../models/fields/rich-text-field';

@Component({
  selector: 'fz-korrektur',
  standalone: true,
  imports: [FormsModule, DialogModule, CheckboxModule, ButtonModule],
  templateUrl: './korrektur.component.html',
  styleUrl: './korrektur.component.scss',
})
export class KorrekturComponent {
  @Input() category: string | undefined;

  korrigierenDialogVisible = false;
  interpunktion = true;
  doppelteLeerzeichen = true;

  constructor(private readonly workspace: WorkspaceService) {}

  show() {
    this.korrigierenDialogVisible = true;
  }

  korrigieren(): void {
    if (this.workspace.selectedZeugnis && this.category != null) {
      for (const field of FormularFactory.createFormular(this.workspace.selectedZeugnis, this.category).getFields()) {
        if (field instanceof RichTextField) {
          if (field.value != null) field.value = this.korrigiereText(field.value);
        }
      }
    }

    this.korrigierenDialogVisible = false;
  }

  korrigiereText(text: string | null): string | null {
    if (text) {
      if (this.interpunktion) {
        text = this.korrigiereInterpunktion(text);
      }
      if (this.doppelteLeerzeichen) {
        text = this.korrigiereLeerzeichen(text);
      }
    }
    return text;
  }

  korrigiereInterpunktion(text: string): string {
    // eslint-disable-next-line no-useless-escape
    text = text?.replace(/\.(?=[^\d\.])[ ]*/g, '. ') ?? '';
    text = text?.replace(/,(?=[^\d])[ ]*/g, ', ') ?? '';
    text = text?.replace(/;(?=[^\d])[ ]*/g, '; ') ?? '';
    text = text?.replace(/\?(?=[^\d])[ ]*/g, '? ') ?? '';
    text = text?.replace(/!(?=[^\d])[ ]*/g, '? ') ?? '';

    return text;
  }

  korrigiereLeerzeichen(text: string): string {
    // eslint-disable-next-line no-useless-escape
    text = text?.replace(/\N\N+/g, ' ') ?? '';
    text = text?.replace(/  +/g, ' ') ?? '';
    text = text?.replace(/ <\/p>/g, '</p>') ?? '';

    return text;
  }
}
