import { SprachenportfolioTyp } from '../../../models/enums/sprachenportfolio-typ';
import { PropertyField } from '../../../models/fields/property-field';
import { RPZeugnis } from '../../../models/rp-zeugnis';

export class SprachenportfolioField extends PropertyField<RPZeugnis, SprachenportfolioTyp | null> {
  constructor(private zeugnis: RPZeugnis) {
    super(
      {
        label: 'Sprachenportfolio',
        labelShort: 'SprPort',
        required: false,
        property: 'sprachenportfolio',
      },
      { object: zeugnis }
    );
  }

  get hasFranzEng() {
    return this.zeugnis.zeugnissatz.schuljahr - this.zeugnis.zeugnissatz.klassenstufe < 2023;
  }

  get value(): SprachenportfolioTyp | null {
    const value = this.zeugnis.sprachenportfolio ?? 'FranzEng';
    if (this.hasFranzEng) return value;
    else return value === 'FranzEng' ? 'Leer' : value;
  }
  set value(value: SprachenportfolioTyp | null) {
    if (value === 'FranzEng') this.zeugnis.sprachenportfolio = null;
    if (!this.hasFranzEng && value === 'Leer') this.zeugnis.sprachenportfolio = null;
    else this.zeugnis.sprachenportfolio = value;
  }
}
