import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { NIZeugnisFormular } from './ni-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { NIZeugnistypComponent } from '../projectables/ni-zeugnistyp.component';
import { NIZeugnissatzComponent } from '../projectables/ni-zeugnissatz.component';
import { NISchuelerComponent } from '../projectables/ni-schueler.component';
import { NIVersaeumnisComponent } from '../projectables/ni-versaeumnis.component';
import { NIUnterschriftComponent } from '../projectables/ni-unterschrift.component';
import { FormularBereich } from '../../../models/formular-bereich';
import { KPBlockFactoryComponent } from '../../kp/kp-block-factory.component';

export class NI34hjBerichtFormular extends NIZeugnisFormular {
  get viewType(): FormularViewType {
    return NI34hjBerichtFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    faecher.push(
      this.deutsch,
      this.mathematik,
      this.sachunterricht,
      this.fremdsprache,
      this.religion,
      this.musik,
      this.bildendeKunst,
      this.werken,
      this.gestalten,
      this.sport
    );
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    return noteFields;
  }
}

@Component({
  selector: 'fz-ni-34hj-bericht-formular',
  templateUrl: 'ni-34hj-bericht-formular.component.html',
})
export class NI34hjBerichtFormularComponent extends FormularComponentBase<NI34hjBerichtFormular> {
  static createBereichModel(bereich: FormularBereich) {
    const formular = bereich.formular as NI34hjBerichtFormular;
    return {
      key: bereich.key ?? 'gesamt',
      label: bereich.description.label,
      textField:
        formular.zeugnis.zeugnissatz.schuljahr < 2024 ||
        bereich.kpRowsVisible.length === 0 ||
        bereich.isVisibleOverrideTextDefaultTrue
          ? bereich.text
          : undefined,
      kp:
        formular.zeugnis.zeugnissatz.schuljahr >= 2024
          ? KPBlockFactoryComponent.createModelFromBereich(bereich)
          : undefined,
      verbalVisibleField:
        formular.zeugnis.zeugnissatz.schuljahr >= 2024 && bereich.kpRowsVisible.length > 0
          ? bereich.isVisibleOverrideTextDefaultTrueField
          : undefined,
    };
  }

  static createModel(formular: NI34hjBerichtFormular) {
    return formular.zeugnis.zeugnissatz.schuljahr >= 2023
      ? {
          zeugnistyp: NIZeugnistypComponent.createModel(formular),
          zeugnissatz: NIZeugnissatzComponent.createModel(formular),
          schueler: NISchuelerComponent.createModel(formular),
          versaeumnis: NIVersaeumnisComponent.createModel(formular),
          faecherMitBereichen: [
            {
              key: formular.deutsch.key,
              label: 'Deutsch Kompetenzbereiche¹',
              bereiche: [
                this.createBereichModel(formular.deutsch.sprechenZuhoeren),
                this.createBereichModel(formular.deutsch.texteVerfassen),
                this.createBereichModel(formular.deutsch.lesen),
              ],
            },
            {
              key: formular.mathematik.key,
              label: 'Mathematik Kompetenzbereiche²',
              bereiche: [
                this.createBereichModel(formular.mathematik.zahlenOperationen),
                this.createBereichModel(formular.mathematik.groessenMessen),
                this.createBereichModel(formular.mathematik.raumForm),
                this.createBereichModel(formular.mathematik.datenZufall),
              ],
            },
            {
              key: formular.sachunterricht.key,
              label: 'Sachunterricht Kompetenzbereiche',
              bereiche: [
                this.createBereichModel(formular.sachunterricht.technik),
                this.createBereichModel(formular.sachunterricht.natur),
                this.createBereichModel(formular.sachunterricht.raum),
                this.createBereichModel(formular.sachunterricht.gesellschaftPolitik),
                this.createBereichModel(formular.sachunterricht.zeitGeschichte),
              ],
            },
            {
              key: formular.fremdsprache.key,
              label: 'Fremdsprache Kompetenzbereiche',
              bereiche: [
                this.createBereichModel(formular.fremdsprache.hoerverstehen),
                this.createBereichModel(formular.fremdsprache.muendlich),
                this.createBereichModel(formular.fremdsprache.lesen),
              ],
            },
          ],
          faecher: [
            ...[
              formular.religion,
              formular.musik,
              formular.bildendeKunst,
              formular.werken,
              formular.gestalten,
              formular.sport,
            ].map((fach) => ({ ...this.createBereichModel(fach.gesamt), key: fach.key, labelTextField: undefined })),
            ...(formular.zeugnis.zeugnissatz.schuljahr >= 2023 &&
            formular.herkunftssprache.gesamt.isVisibleOverrideDefaultTrue
              ? [
                  {
                    ...this.createBereichModel(formular.herkunftssprache.gesamt),
                    key: formular.herkunftssprache.key,
                    label: 'Herkunftssprachlicher Unterricht',
                    labelTextField: formular.herkunftssprache.gesamt.label,
                  },
                ]
              : []),
          ],
          arbeitsgemeinschaften: {
            label: formular.arbeitsgemeinschaften.description.label,
            textField: formular.arbeitsgemeinschaften,
          },
          interessen: {
            label: 'Besondere Interessen und Fähigkeiten',
            textField: formular.interessen,
          },
          lernArbeitsverhalten: {
            label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
          },
          sozialverhalten: {
            label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
          },
          bemerkungen: {
            label: formular.bemerkungen.description.label,
            textField: formular.bemerkungen,
          },
          unterschrift: NIUnterschriftComponent.createModel(formular),
        }
      : {
          zeugnistyp: NIZeugnistypComponent.createModel(formular),
          zeugnissatz: NIZeugnissatzComponent.createModel(formular),
          schueler: NISchuelerComponent.createModel(formular),
          versaeumnis: NIVersaeumnisComponent.createModel(formular),
          interessen: {
            label: formular.interessen.description.label,
            textField: formular.interessen,
          },
          faecherMitBereichen: [
            {
              key: formular.deutsch.key,
              label: formular.deutsch.gesamt.description.label,
              bereiche: [
                this.createBereichModel(formular.deutsch.sprechenZuhoeren),
                this.createBereichModel(formular.deutsch.lesen),
                this.createBereichModel(formular.deutsch.texteVerfassen),
              ],
            },
            {
              key: formular.mathematik.key,
              label: formular.mathematik.gesamt.description.label,
              bereiche: [
                this.createBereichModel(formular.mathematik.zahlenOperationen),
                this.createBereichModel(formular.mathematik.groessenMessen),
                this.createBereichModel(formular.mathematik.raumForm),
              ],
            },
            {
              key: formular.sachunterricht.key,
              label: formular.sachunterricht.gesamt.description.label,
              bereiche: [
                this.createBereichModel(formular.sachunterricht.zeitGeschichte),
                this.createBereichModel(formular.sachunterricht.gesellschaftPolitik),
                this.createBereichModel(formular.sachunterricht.raum),
                this.createBereichModel(formular.sachunterricht.natur),
                this.createBereichModel(formular.sachunterricht.technik),
              ],
            },
            {
              key: formular.fremdsprache.key,
              label: formular.fremdsprache.gesamt.description.label,
              bereiche: [
                this.createBereichModel(formular.fremdsprache.kommunikativeFertigkeiten),
                this.createBereichModel(formular.fremdsprache.verfuegungSprachlicheMittel),
                this.createBereichModel(formular.fremdsprache.interkulturelleKompetenzen),
                this.createBereichModel(formular.fremdsprache.methodenkompetenzen),
              ],
            },
          ],
          faecher: [
            formular.religion,
            formular.musik,
            formular.bildendeKunst,
            formular.werken,
            formular.gestalten,
            formular.sport,
          ].map((fach) => ({
            ...this.createBereichModel(fach.gesamt),
            key: fach.key,
            labelTextField: undefined,
          })),
          lernArbeitsverhalten: {
            label: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
          },
          sozialverhalten: {
            label: formular.sozialLernArbeitsverhalten.sozialverhalten.description.label,
            textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
          },
          bemerkungen: {
            label: formular.bemerkungen.description.label,
            textField: formular.bemerkungen,
          },
          unterschrift: NIUnterschriftComponent.createModel(formular),
        };
  }

  get model() {
    return NI34hjBerichtFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<NI34hjBerichtFormular>) {
    super(formularService);
  }
}
