import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  Block,
  BlockFactory,
  BlockFactoryOwnerProvider,
  BlockFactoryProvider,
  BlockRange,
} from '@modules/blocks/block-factory';
import { BlockDirective } from '@modules/blocks/block.directive';
import { BorderDirection, borderWidthNormal } from '@modules/dom/border-direction';
import { Formular } from '@modules/formular/formular';
import { FormularService } from '@modules/formular/formular.service';
import { PanelComponent } from '@modules/projectables/panel.component';
import { Projectable, ProjectableOwner, ProjectableOwnerProvider } from '@modules/projectables/projectable';
import { FocusableDirective } from '@modules/shared/focusable.directive';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { KPRowField } from '../../models/fields/kp-item-benotung-field';
import { FormularBereich } from '../../models/formular-bereich';
import { FormularFach } from '../../models/formular-fach';

interface BlockRow {
  rect: DOMRect;
  rectRadios: DOMRect;
  projectable: Projectable;
  field: KPRowField;
}
interface BlockModel {
  shadow: Projectable | undefined;
  header: Projectable | undefined;
  rows: BlockRow[];
  rectRows: DOMRect;
  width: number;
}

interface Candidate {
  value: KPRowField;
  label: string;
}

@Component({
  selector: 'fz-kp',
  templateUrl: 'kp-block-factory.component.html',
  providers: [
    provideInterfaceBy(BlockFactoryProvider, KPBlockFactoryComponent),
    provideInterfaceBy(ProjectableOwnerProvider, KPBlockFactoryComponent),
  ],
})
export class KPBlockFactoryComponent implements AfterViewInit, BlockFactory, ProjectableOwner {
  static createModelFromFach(fach: FormularFach) {
    return fach.kpRowsVisible ?? [];
  }
  static createModelFromBereich(bereich: FormularBereich) {
    return bereich.kpRowsVisible ?? [];
  }

  @Input() model: KPRowField[] | undefined;
  @Input() fach?: FormularFach;
  @Input() bereich?: FormularBereich;
  @ViewChild('shadow', { read: PanelComponent }) shadowComponent?: PanelComponent;
  @ViewChildren('header', { read: PanelComponent }) headerComponents = new QueryList<PanelComponent>();
  @ViewChildren('row', { read: PanelComponent }) rowComponents = new QueryList<PanelComponent>();
  @ViewChildren(BlockDirective) blockComponents = new QueryList<BlockDirective>();
  @ViewChildren(FocusableDirective) focusables = new QueryList<FocusableDirective>();
  borderDirection = BorderDirection;
  borderWidth = borderWidthNormal;
  blocks: BlockModel[] = [];
  headerLabels: string[][];
  headers: Record<string, never>[] = [{}];
  #candidates: Candidate[] = [];
  #suppressHeightChange = 0;
  constructor(
    public changeDetector: ChangeDetectorRef,
    private ownerProvider: BlockFactoryOwnerProvider,
    private elementRef: ElementRef<HTMLElement>,
    private formularService: FormularService<Formular>
  ) {
    this.headerLabels = formularService.formular.headerLabels;
  }
  get rows(): KPRowField[] {
    return this.model ?? [];
  }
  get candidates(): Candidate[] {
    const candidates = (this.bereich?.kpRowsHidden ?? this.fach?.kpRowsHidden ?? []).map((r) => ({
      value: r,
      label: r.benotung.item.text,
    }));
    if (
      this.#candidates.length !== candidates.length ||
      this.#candidates.some((c, index) => c.value !== candidates[index]?.value)
    ) {
      this.#candidates = candidates;
    }
    return this.#candidates;
  }
  get hasFocus(): boolean {
    return this.focusables.some((f) => f.hasFocus);
  }
  get hasTeilnahme(): boolean {
    return this.fach?.description?.teilnahmeCandidates != null;
  }
  get teilnahmeCandidates(): { value: string | null; label: string }[] {
    return this.fach?.description?.teilnahmeCandidates ?? [];
  }
  get teilnahme(): string | null {
    return this.fach?.fach?.teilnahmeBereichKey ?? null;
  }
  set teilnahme(value: string | null) {
    if (this.fach?.fach != null) {
      this.fach.fach.teilnahmeBereichKey = value;
    }
  }

  ngAfterViewInit(): void {
    this.rowComponents.changes.subscribe(() => {
      if (this.#suppressHeightChange === 0) {
        this.ownerProvider.provided.invalidate();
        this.focusables.get(0)?.focus();
      }
    });
  }

  projectableContentChange(_projectable: Projectable): void {
    //
  }

  project(): void {
    this.shadowComponent?.project();
    for (const headerComponent of this.headerComponents) headerComponent.project();
    for (const rowComponent of this.rowComponents) rowComponent.project();
  }
  getBlockCount(): number {
    return this.rows.length;
  }
  measureHeight(range: BlockRange): number {
    this.detectChanges();
    return (
      (this.rows.length > 0 ? (this.headerComponents.get(0)?.height ?? 0) + this.borderWidth : 0) +
      this.rowComponents
        .toArray()
        .slice(range.start, range.start + range.length)
        .map((p) => p.height)
        .reduce((prev: number, curr: number) => prev + curr, 0)
    );
  }
  layout(ranges: BlockRange[]): Block[] {
    this.headers.splice(1);
    this.headers.push(...ranges.map(() => ({})));
    this.detectChanges();
    this.blocks = ranges.map((range, index) => {
      const rows: BlockRow[] = [];
      const header = this.rows.length > 0 ? this.headerComponents.get(index + 1) : undefined;
      const top = header?.height ?? 0;
      let rowsHeight = 0;
      let rowIndex = 0;
      for (const rowComponent of this.rowComponents.toArray().slice(range.start, range.start + range.length)) {
        const rowRect = rowComponent.sourceElement.getBoundingClientRect();
        const width = rowRect.width;
        const height = rowRect.height;
        const field = this.rows[range.start + rowIndex];
        rows.push({
          rect: new DOMRect(0, top + rowsHeight, width, height),
          rectRadios: new DOMRect(
            width - this.headerLabels.length * 49,
            top + rowsHeight + 1,
            (this.headerLabels.length + 1) * 49,
            height - 1
          ),
          projectable: rowComponent,
          field,
        });
        rowsHeight += rowComponent.height;
        rowIndex++;
      }
      return {
        shadow: range.start === 0 ? this.shadowComponent : undefined,
        header,
        rows,
        rectRows: new DOMRect(0, top, this.elementRef.nativeElement.getBoundingClientRect().width - 4 * 49, rowsHeight),
        width: this.elementRef.nativeElement.getBoundingClientRect().width,
      };
    });
    this.detectChanges();
    this.project();
    return this.blockComponents.toArray();
  }

  candidateClick(e: { value?: KPRowField; option?: Candidate }) {
    if (e.option != null) {
      e.option.value.value = null;
      this.ownerProvider.provided.invalidate();
      this.focusables.get(0)?.focus();
    } else if (e.value != null) {
      e.value.value = null;
      this.ownerProvider.provided.invalidate();
      this.focusables.get(0)?.focus();
    }
  }

  private detectChanges() {
    this.#suppressHeightChange++;
    this.changeDetector.detectChanges();
    this.#suppressHeightChange--;
  }
}
