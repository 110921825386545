import { ProgressBarModule } from 'primeng/progressbar';
import { Password, PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AfterViewInit, Component, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { AppInfoService } from '../../services';
import { FlinkyAuthService } from './../../services/auth/flinky-auth.service';

import LoginCredentials from '../../../models/generated/login-credentials';
import { NotificationService } from '../../services/notification/notification.service';
import { TagModule } from 'primeng/tag';

import * as signalR from '@microsoft/signalr';

import { VersionService } from '../../services/version.service';
import FlinkyVersionInfo from '../../../models/generated/flinky-version-info';
import { AutoFocusModule } from '../../auto-focus.module';
import { InputTextModule } from 'primeng/inputtext';
import { activateText } from '../../../consts';
import { FZConfirmationService } from '../../services/fz-confirmation.service';
import { SystemActionsService } from '../system-actions/system-actions.service';
import { Benutzer } from '../../../models/generated/benutzer';
import { environment } from 'src/environments/environment';
import { DisclaimerComponent } from '../passwordrecovery/disclaimer/disclaimer.component';
import { FzeGuidedTourService } from '../../services/fze-guided-tour.service';
import { JahrgangService } from '../../services/jahrgang.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DisclaimerComponent) disclaimer: DisclaimerComponent | undefined;

  @ViewChild(Password) password?: Password;
  environment = environment;
  loginform: UntypedFormGroup;
  updateCheckRunning = false;
  updateCheckCompleted = false;

  loginInProcess = false;
  connection?: signalR.HubConnection;
  downloadProgress: number = 0;
  updateHint: string = '';
  updateAvailable: boolean = false;
  downloadError: boolean = false;
  updateDownloaded: boolean = false;
  licenseCheckRunning: boolean = false;

  info?: FlinkyVersionInfo;
  availableUsers: Benutzer[] = [];
  hasDisclaimerConfirmed: boolean = false;

  constructor(
    private flinkyAuth: FlinkyAuthService,
    public appInfo: AppInfoService,
    private router: Router,
    private notificationService: NotificationService,
    private versionService: VersionService,
    private confirmationService: FZConfirmationService,
    private route: ActivatedRoute,
    public systemActionsService: SystemActionsService,
    private fzeTour: FzeGuidedTourService,
    private jahrgangService: JahrgangService
  ) {
    this.loginform = new UntypedFormGroup({
      login: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
    });
  }
  ngOnDestroy(): void {
    this.fzeTour.close();
  }
  ngAfterViewInit(): void {
    this.fzeTour.addSteps([
      {
        attachTo: {
          element: '#hinweis-element',
          on: 'bottom',
        },
        title: 'Hinweise beachten',
        text: ['Hier erfahren Sie Neuigkeiten und finden weitere Informationen zu Flinky Zeugnis Evo.'],
        buttons: this.fzeTour.buttonsCloseNext,
      },
      {
        attachTo: {
          element: '#login-element',
          on: 'bottom',
        },
        title: 'Anmelden!',
        text: [
          'Flinky Zeugnis Evo benötgt ein Konto, um Ihre Zeugnisse sicher zuordnen zu können. Erstellen Sie ein neues oder melden Sie sich mit einem existierenden Konto an.',
        ],
        buttons: this.fzeTour.buttonsCloseBack,
      },
    ]);

    // this.fzeTour.start();
  }

  async ngOnInit(): Promise<void> {
    this.initUsersIfElectron();

    const baseUrl = document.getElementsByTagName('base')[0].href;
    const url = (baseUrl + '/signalr_update').replace(/([^:]\/)\/+/g, '$1');

    this.connection = new signalR.HubConnectionBuilder().withUrl(url).build();

    this.connection.on(
      'AppUpdateProgress',
      (_progress: string, _total: string, percent: string, _transferred: string, _bps: string) => {
        this.downloadProgress = Math.ceil(+percent);
      }
    );
    //obj.Version, obj.ReleaseDate, obj.ReleaseName, obj.ReleaseNotes, obj.StagingPercentage);
    this.connection.on(
      'UpdateAvailable',
      (_version: string, _releaseDate: string, _releaseName: string, _releaseNotes: string, _staging: string) => {
        this.updateCheckRunning = false;
        this.updateAvailable = true;
        this.updateCheckCompleted = true;
      }
    );

    this.connection.on('CheckingForUpdate', () => {
      this.updateCheckRunning = true;
    });

    this.connection.on('UpdateDownloaded', () => {
      this.updateDownloaded = true;
    });

    this.connection.on(
      'UpdateNotAvailable',
      (_version: string, _releaseDate: string, _releaseName: string, _releaseNotes: string, _staging: string) => {
        this.updateCheckRunning = false;
        this.updateAvailable = false;
        this.updateCheckCompleted = true;
      }
    );

    this.connection.on('DownloadError', (message: string) => {
      this.downloadError = true;
      this.updateHint = message;
    });

    const dbIsCompatible = await this.versionService.isDbVersionCompatible();
    this.connection
      .start()
      .then(() => {
        if (dbIsCompatible) {
          this.updateLicenseCheck();
        }
      })

      .catch((err) => console.log(err));

    if (dbIsCompatible) {
      // check if already login available
      if (this.flinkyAuth.isLoggedIn() === true) {
        this.router.navigate(['/main']);
      }
    } else {
      this.showVersionIncompatibleConfirmation(false);
    }

    this.info = await this.versionService.getVersionInfo();

    if (history?.state?.email) {
      this.loginform.patchValue({ login: history.state.email });
    }
  }

  async initUsersIfElectron() {
    if (this.systemActionsService.isElectron) {
      this.availableUsers = await this.flinkyAuth.getAvailableUsers();
      if (this.availableUsers.length === 1) this.loginform.patchValue({ login: this.availableUsers[0].benutzername });
    }
  }

  async login(resetOtherSession: boolean = false): Promise<void> {
    this.loginInProcess = true;
    const loginCredentials: LoginCredentials = {
      username: this.loginform.value.login,
      password: this.loginform.value.password,
      resetOtherSession: resetOtherSession,
    };
    try {
      const result = await this.flinkyAuth.login(loginCredentials, this.hasDisclaimerConfirmed);
      switch (result.resultCode) {
        case 'AnotherSessionExists':
          if (
            (await this.confirmationService.confirm({
              message:
                'Es existiert noch eine andere aktive Sitzung. Bitte loggen Sie sich am Ende der Sitzung immer aus. Soll diese beendet und der Login hier fortgesetzt werden?',
            })) === true
          ) {
            // nutzer einloggen
            this.login(true);
          }
          break;
        case 'NotActivated':
          this.notificationService.showPermanentError(activateText, 'Fehler beim Anmelden');
          this.router.navigate(['/register-form'], {
            state: { state: 'waitforactivation', userId: result.benutzer.id, password: loginCredentials.password },
          });
          break;
        case 'DisclaimerNotConfirmed':
          if ((await this.disclaimer?.show()) ?? false) {
            this.hasDisclaimerConfirmed = true;
            this.login(resetOtherSession);
          }
          break;
        case 'Success':
          if (await this.versionService.isDbVersionCompatible()) {
            this.flinkyAuth.setLoginData(result);
            if (await this.jahrgangService.throwsCryptographicException()) {
              this.router.navigate(['/restore-user-backup'], { queryParams: { throwsCryptographicException: true } });
            } else {
              this.flinkyAuth.loginStateChanged.next(true);
              this.systemActionsService.createAutoBackup(); // kein await, soll im Hintergrund laufen
              this.router.navigate(['/main']);
            }
          } else {
            this.showVersionIncompatibleConfirmation(true);
          }
          break;
        case 'WrongPassword':
          this.notificationService.showPermanentError(
            'Das angegebene Passwort ist nicht korrekt.',
            'Fehler beim Anmelden'
          );
          break;
        case 'MigrationException':
          this.notificationService.showPermanentError('Es ist ein Fehler bei der ', 'Fehler beim Anmelden');
          break;
        default:
          this.notificationService.showPermanentError(result.resultCode, 'Fehler beim Anmelden');
      }
    } finally {
      this.loginInProcess = false;
    }
  }

  async updateLicenseCheck() {
    this.licenseCheckRunning = true;
    await this.versionService.checkForLicenseUpdate(this.connection?.connectionId);
    this.licenseCheckRunning = false;
  }

  private showVersionIncompatibleConfirmation(closable: boolean): void {
    this.confirmationService.confirm({
      header: 'Hinweis',
      message:
        'Ihre Programmversion ist veraltet. Bitte warten Sie bis das automatische Update beendet ist oder installieren Sie die aktuelle Version manuell.',
      acceptLabel: 'Ok',
      acceptVisible: closable,
      rejectVisible: false,
      closeOnEscape: false,
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    PasswordModule,
    ProgressBarModule,
    TagModule,
    AutoFocusModule,
    InputTextModule,
    DisclaimerComponent,
  ],
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent],
})
export class LoginFormModule {}
