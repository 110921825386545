import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Formular } from '@modules/formular/formular';
import { RichTextComponent } from './rich-text.component';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { FocusableDirective } from '@modules/shared/focusable.directive';
import { Block, BlockFactory, BlockFactoryProvider, BlockRange } from '@modules/blocks/block-factory';
import { BlockDirective } from '@modules/blocks/block.directive';
import { FormularService } from '@modules/formular/formular.service';
import { IntelliField } from '../../models/fields/intelli-field';

export interface IntelliBlockModel {
  richTextBlock: Block;
  rect: DOMRect;
}

@Component({
  selector: 'fz-intelli',
  templateUrl: 'intelli.component.html',
  providers: [provideInterfaceBy(BlockFactoryProvider, IntelliComponent)],
})
export class IntelliComponent implements OnInit, AfterViewChecked, BlockFactory {
  @Input() field: IntelliField<any> | undefined;
  @Input() width = 668;
  @ViewChild(RichTextComponent) richText?: RichTextComponent;
  @ViewChildren(BlockDirective) blocks = new QueryList<BlockDirective>();
  @ViewChildren(FocusableDirective) focusables = new QueryList<FocusableDirective>();

  blockModels: IntelliBlockModel[] = [];
  #model: string | null = null;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private formularService: FormularService<Formular>
  ) {}
  get formular(): Formular {
    return this.formularService.formular;
  }
  get model(): string | null {
    return this.#model;
  }
  set model(value: string | null) {
    this.#model = value;
    this.changeDetector.detectChanges();
    if (this.field != null) this.field.value = this.model;
  }

  get hasFocus(): boolean {
    return this.focusables.some((f) => f.hasFocus) || (this.richText?.hasFocus ?? false);
  }

  ngOnInit(): void {
    if (this.field != null) this.#model = this.field.value;
  }
  ngAfterViewChecked(): void {
    if (this.field != null && this.field.value !== this.model) {
      this.#model = this.field.value;
      this.changeDetector.detectChanges();
    }
  }
  onFocusChange(): void {
    this.changeDetector.detectChanges();
  }

  project(): void {
    this.richText?.project();
  }
  getBlockCount(): number {
    if (this.richText == null) this.changeDetector.detectChanges();
    return this.richText?.getBlockCount() ?? 0;
  }
  measureHeight(range: BlockRange): number {
    return this.richText?.measureHeight(range) ?? 0;
  }
  layout(ranges: BlockRange[]): Block[] {
    this.blockModels = (this.richText?.layout(ranges) ?? []).map((block, index) => {
      return {
        richTextBlock: block,
        rect: new DOMRect(0, 0, this.richText?.width ?? 0, this.measureHeight(ranges[index])),
      };
    });

    this.changeDetector.detectChanges();
    return this.blocks.toArray();
  }
  onSelectedBemerkung(bemerkung?: string): void {
    this.richText?.insertSatz(bemerkung);
  }
}
