import { KpEditorComponent } from './kp-editor/kp-editor.component';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CanComponentDeactivate } from '../../guards/can-component-deactivate.guard';
import { BundeslandTyp } from '../../../models/enums/bundesland-typ';

@Component({
  selector: 'fz-koennensprofil',
  templateUrl: './koennensprofil.component.html',
  styleUrls: ['./koennensprofil.component.scss'],
})
export class KoennensprofilComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('editor') editor?: KpEditorComponent;
  bula?: BundeslandTyp;
  step: 'setup' | 'editor' = 'setup';
  subscriptions: Subscription[] = [];
  constructor(private flinkyAuth: FlinkyAuthService) {
    this.subscriptions.push(flinkyAuth.lizenzChanged.subscribe(() => this.init()));
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  init(): void {
    this.bula = this.flinkyAuth.lizenz?.bundesland;
    if (this.bula === 'HE') this.step = 'editor';
    if (this.bula === 'RP') this.step = 'setup';
    if (this.bula === 'BW') this.step = 'editor';
    if (this.bula === 'NI') this.step = 'editor';
  }

  onBestaetigungSet(bestaetigung: boolean): void {
    if (bestaetigung) this.step = 'editor';
  }

  onSaveSettings(_settings: boolean): void {
    this.step = 'editor';
  }

  public canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.editor ? this.editor.canDeactivate() : true;
  }
}
