<ng-template #header>
  <p-steps class="fz-steps-transparent w-full p-2" [model]="steps" [(activeIndex)]="activeStep"></p-steps>
</ng-template>
<div class="flex-grow h-0 overflow-auto">
  <p *ngIf="mode === 'wiederfuellen'" class="p-7">
    Die letzten Zeugnisse wurden gelöscht. Zum Neuanlegen der Zeugnisse füllen Sie bitte die Felder aus.
    <br />
    Die Schüler werden automatisch hinzugefügt.
  </p>
  <fz-select-kundennummer
    *ngIf="activeStep === 0"
    (kundennummerChecked)="activeStep = 1"
    class="flex-grow h-0 overflow-auto"
    [header]="header"
    [showCancel]="false"
    [showDemoLizenzen]="true"
  >
  </fz-select-kundennummer>
  <form *ngIf="activeStep === 1" [formGroup]="jahrgangform" (ngSubmit)="onSubmit()">
    <p-panel>
      <ng-template pTemplate="header">
        <ng-container [ngTemplateOutlet]="header"></ng-container>
      </ng-template>
      <div class="p-fluid p-formgrid p-grid w-full">
        <div class="p-field p-col-12 p-md-6">
          <label for="lizenz">Lizenz*</label>
          <div class="flex w-full gap-2">
            <img src="{{ lizenzImage }}" alt="Zeugniskopf {{ this.authService.lizenz?.kundennummer }}" />
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6"></div>
        <div class="p-field p-col-12 p-md-6">
          <label for="schuljahr">Schuljahr*</label>
          <p-dropdown
            fzAutofocus
            placeholder="Schuljahr wählen"
            id="schuljahr"
            [options]="schuljahre"
            optionLabel="text"
            optionValue="wert"
            formControlName="schuljahr"
            placeholder="Schuljahr wählen"
            (onChange)="schuljahrChanged()"
          ></p-dropdown>
          <div
            *ngIf="jahrgangform.get('schuljahr')?.invalid && jahrgangform.get('schuljahr')?.dirty"
            class="fz-validation-error"
          >
            <div *ngIf="jahrgangform.get('schuljahr')?.errors?.['required']">Schuljahr wird benötigt</div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6"></div>
        <div class="p-field p-col-12 p-md-6">
          <label for="zeugnis">Zeugnisformular*</label>
          <p-dropdown
            placeholder="Zeugnisformular wählen"
            [options]="zeugnisse"
            optionLabel="text"
            formControlName="halbjahrDescription"
            (onChange)="zeugnisChanged()"
            scrollHeight="500px"
          ></p-dropdown>
          <div
            *ngIf="
              jahrgangform.get('halbjahrDescription')?.invalid &&
              (jahrgangform.get('halbjahrDescription')?.dirty || jahrgangform.get('halbjahrDescription')?.touched)
            "
            class="fz-validation-error"
          >
            <div *ngIf="jahrgangform.get('halbjahrDescription')?.errors?.['required']">
              Zeugnisformular wird benötigt
            </div>
          </div>
        </div>
        <div *ngIf="jahrgangform.value.halbjahrDescription?.klassenstufe == 2 && isSL" class="p-field p-col-12 p-md-6">
          <label for="formulartyp">Formulartyp</label>
          <p-dropdown
            placeholder="Formulartyp wählen"
            [options]="zeugnislevels"
            optionLabel="text"
            formControlName="zeugnislevel"
          ></p-dropdown>
          <div
            *ngIf="
              jahrgangform.get('zeugnislevel')?.invalid &&
              (jahrgangform.get('zeugnislevel')?.dirty || jahrgangform.get('zeugnislevel')?.touched)
            "
            class="fz-validation-error"
          >
            <div *ngIf="jahrgangform.get('zeugnislevel')?.errors?.['required']">Formulartyp wird benötigt</div>
          </div>
        </div>
        <div
          *ngIf="jahrgangform.value.halbjahrDescription?.klassenstufe != 2 || !isSL"
          class="p-field p-col-12 p-md-6"
        ></div>
        <div class="p-field p-col-12 p-md-6">
          <label for="klassenleitung">Klassenleitung*</label>
          <input id="klassenleitung" type="text" pInputText formControlName="klassenleitung" />
          <div
            *ngIf="
              jahrgangform.get('klassenleitung')?.invalid &&
              (jahrgangform.get('klassenleitung')?.dirty || jahrgangform.get('klassenleitung')?.touched)
            "
            class="fz-validation-error"
          >
            <div *ngIf="jahrgangform.get('klassenleitung')?.errors?.['required']">Klassenleitung wird benötigt</div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="schulleitung">Schulleitung*</label>
          <input id="schulleitung" type="text" pInputText formControlName="schulleitung" />
          <div
            *ngIf="
              jahrgangform.get('schulleitung')?.invalid &&
              (jahrgangform.get('schulleitung')?.dirty || jahrgangform.get('schulleitung')?.touched)
            "
            class="fz-validation-error"
          >
            <div *ngIf="jahrgangform.get('schulleitung')?.errors?.['required']">Schulleitung wird benötigt</div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="klassenbezeichnung">Klassenbezeichnung*</label>
          <fz-help-info [key]="'jahrgangKlassenbezeichnung'"></fz-help-info>

          <input id="klassenbezeichnung" type="text" pInputText formControlName="klassenbezeichnung" />
          <div
            *ngIf="
              jahrgangform.get('klassenbezeichnung')?.invalid &&
              (jahrgangform.get('klassenbezeichnung')?.dirty || jahrgangform.get('klassenbezeichnung')?.touched)
            "
            class="fz-validation-error"
          >
            <div *ngIf="jahrgangform.get('klassenbezeichnung')?.errors?.['required']">
              Klassenbezeichnung wird benötigt
            </div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="rufname">Rufname der Klasse</label>
          <fz-help-info [key]="'jahrgangRufname'"></fz-help-info>

          <input id="rufname" type="text" pInputText formControlName="rufname" />
        </div>
        <div class="p-field p-col-12 p-md-12 fz-validation-error" *ngIf="!isUnique">
          Es existiert bereits ein Jahrgang/Zeugnissatz mit diesen Daten!
        </div>
      </div>

      <ng-template pTemplate="footer">
        <div class="fz-button-group p-2">
          <button
            pButton
            [loading]="isLoading"
            label="Zurück"
            icon="pi pi-caret-left"
            class="w-64 p-button-secondary"
            (click)="activeStep = 0"
          ></button>
          <button
            pButton
            [loading]="isLoading"
            label="Weiter"
            icon="pi pi-caret-right"
            class="w-64"
            type="submit"
          ></button>
        </div>
      </ng-template>
    </p-panel>
  </form>
</div>
