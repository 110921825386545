/* generated file - do not touch! */

class BereichKey {
  public static readonly sozialverhalten: string = 'sozialverhalten';
  public static readonly lernArbeitsverhalten: string = 'lernArbeitsverhalten';
  public static readonly arbeitsverhalten: string = 'arbeitsverhalten';
  public static readonly lernverhalten: string = 'lernverhalten';
  public static readonly sprechenZuhoeren: string = 'sprechenZuhoeren';
  public static readonly lesen: string = 'lesen';
  public static readonly texteVerfassen: string = 'texteVerfassen';
  public static readonly rechtschreiben: string = 'rechtschreiben';
  public static readonly spracheUntersuchen: string = 'spracheUntersuchen';
}
export default BereichKey;
