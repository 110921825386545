import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularFach } from '../../../models/formular-fach';
import { HEAnlageFormular } from './he-anlage-formular';
import { FormularViewType } from '../../formular/formular';

export class HE234hjKeineanlageFormular extends HEAnlageFormular {
  get viewType(): FormularViewType {
    return HE234hjKeineanlageFormularComponent;
  }
  get faecher(): FormularFach[] {
    return [];
  }
}

@Component({
  selector: 'fz-he-234hj-keineanlage-formular',
  templateUrl: 'he-234hj-keineanlage-formular.component.html',
})
export class HE234hjKeineanlageFormularComponent extends FormularComponentBase<HE234hjKeineanlageFormular> {
  static createModel(_formular: HE234hjKeineanlageFormular) {
    return undefined;
  }

  get model() {
    return HE234hjKeineanlageFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<HE234hjKeineanlageFormular>) {
    super(formularService);
  }
}
