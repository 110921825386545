import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  Block,
  BlockFactory,
  BlockFactoryOwnerProvider,
  BlockFactoryProvider,
  BlockRange,
} from '@modules/blocks/block-factory';
import { BlockDirective } from '@modules/blocks/block.directive';
import { DecoBlockFactoryComponent } from '@modules/blocks/deco-block-factory.component';
import { BorderDirection } from '@modules/dom/border-direction';
import { FormularService } from '@modules/formular/formular.service';
import { FocusableDirective } from '@modules/shared/focusable.directive';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { FormularFach } from '../../../models/formular-fach';
import { NIFormular } from '../formulare/ni-formular';

@Component({
  selector: 'fz-ni-fachtexte-item',
  templateUrl: 'ni-fachtexte-item-block-factory.component.html',
  providers: [provideInterfaceBy(BlockFactoryProvider, NIFachtexteItemBlockFactoryComponent)],
})
export class NIFachtexteItemBlockFactoryComponent implements BlockFactory {
  static createModel(fach: FormularFach) {
    return {
      labelField: fach.gesamt.label,
      textField: fach.gesamt.text,
      label: fach.gesamt.labelNbsp,
    };
  }

  @Input() model: ReturnType<typeof NIFachtexteItemBlockFactoryComponent.createModel> | undefined;
  @Input() isFirstFach = false;
  @Output() delete = new EventEmitter<void>();
  @ViewChild(DecoBlockFactoryComponent) blockFactory: DecoBlockFactoryComponent | undefined;
  @ViewChildren(BlockDirective) blockComponents = new QueryList<BlockDirective>();
  @ViewChildren(FocusableDirective) focusables = new QueryList<FocusableDirective>();

  borderDirection = BorderDirection;
  blocks: Block[] = [];

  constructor(
    public changeDetector: ChangeDetectorRef,
    private ownerProvider: BlockFactoryOwnerProvider,
    private elementRef: ElementRef<HTMLElement>,
    private formularService: FormularService<NIFormular>
  ) {}
  get formular(): NIFormular {
    return this.formularService.formular;
  }
  get hasFocus(): boolean {
    return this.focusables.some((f) => f.hasFocus);
  }

  project(): void {
    this.blockFactory?.project();
  }
  getBlockCount(): number {
    return this.blockFactory?.getBlockCount() ?? 0;
  }
  measureHeight(range: BlockRange): number {
    this.detectChanges();
    return this.blockFactory?.measureHeight(range) ?? 0;
  }
  layout(ranges: BlockRange[]): Block[] {
    this.blocks = this.blockFactory?.layout(ranges) ?? [];
    this.detectChanges();
    return this.blockComponents.toArray();
  }

  private detectChanges() {
    this.changeDetector.detectChanges();
  }
}
