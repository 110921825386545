import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ElementRef,
  ContentChildren,
  AfterContentInit,
  OnDestroy,
} from '@angular/core';
import { PanelComponent } from '@modules/projectables/panel.component';
import { FocusableDirective } from '@modules/shared/focusable.directive';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { Block, BlockFactory, BlockFactoryOwnerProvider, BlockFactoryProvider, BlockRange } from './block-factory';
import { BlockDirective } from './block.directive';
import { ValueField } from '../../models/fields/value-field';
import { Subscription } from 'rxjs';

export interface OptionalBlockModel {
  block: Block;
  rect: DOMRect;
  isFirst: boolean;
}

@Component({
  selector: 'fz-optional',
  templateUrl: 'optional-block-factory.component.html',
  styles: [':host { display: block }'],
  providers: [provideInterfaceBy(BlockFactoryProvider, OptionalBlockFactoryComponent)],
})
export class OptionalBlockFactoryComponent implements BlockFactory, OnDestroy, AfterContentInit, AfterViewChecked {
  @Input() label = '';
  @Input() field: ValueField<any, boolean> | undefined;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() isHiddenChange = new EventEmitter<boolean>();
  @ContentChildren(BlockFactoryProvider) blockFactoryProviders = new QueryList<BlockFactoryProvider>();
  @ViewChildren(BlockDirective) blocks = new QueryList<BlockDirective>();
  @ViewChild(PanelComponent) emptyPanel?: PanelComponent;
  @ViewChildren(FocusableDirective) focusables = new QueryList<FocusableDirective>();
  @ViewChild('focusableContent') focusableContent?: ElementRef<HTMLElement>;

  blockModels: OptionalBlockModel[] = [];
  #isVisible = true;
  private subscriptions: Subscription[] = [];

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private changeDetector: ChangeDetectorRef,
    private blockFactoryOwnerProvider: BlockFactoryOwnerProvider
  ) {}

  ngAfterContentInit(): void {
    this.subscriptions.push(
      this.blockFactoryProviders.changes.subscribe(() => {
        this.blockFactoryOwnerProvider.provided.invalidate();
      })
    );
  }

  ngAfterViewChecked(): void {
    const isVisible = this.field?.value ?? true;
    if (isVisible !== this.isVisible) this.isVisible = isVisible;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) subscription.unsubscribe();
  }

  get blockFactory(): BlockFactory | undefined {
    return this.blockFactoryProviders.first?.provided;
  }

  get hasFocus(): boolean {
    return this.focusables.some((f) => f.hasFocus);
  }

  @Input() get isVisible(): boolean {
    return this.#isVisible;
  }
  set isVisible(value: boolean) {
    if (this.#isVisible !== value) {
      this.#isVisible = value;
      this.isVisibleChange.emit(value);
      this.isHiddenChange.emit(!value);
      if (this.field != null) this.field.value = value;
      this.blockFactoryOwnerProvider.provided.invalidate();
    }
  }

  @Input() get isHidden(): boolean {
    return !this.isVisible;
  }
  set isHidden(value: boolean) {
    this.isVisible = !value;
  }

  project(): void {
    this.blockFactory?.project();
    this.emptyPanel?.project();
  }
  getBlockCount(): number {
    if (this.blockFactory != null && this.isVisible) return this.blockFactory.getBlockCount();
    else return 0;
  }
  measureHeight(range: BlockRange): number {
    if (this.blockFactory != null && this.isVisible) return this.blockFactory.measureHeight(range);
    else return 0;
  }
  layout(ranges: BlockRange[]): Block[] {
    const width = this.elementRef.nativeElement.getBoundingClientRect().width;
    let blockModels: OptionalBlockModel[];
    if (this.blockFactory != null && this.isVisible) {
      blockModels = this.blockFactory.layout(ranges).map((block, index) => {
        return {
          block,
          rect: new DOMRect(0, 0, width, this.measureHeight(ranges[index])),
          isFirst: index === 0,
        };
      });
    } else {
      blockModels = [
        {
          block: { content: undefined, backDeco: undefined, frontDeco: undefined },
          rect: new DOMRect(-38, 0, width, 19),
          isFirst: true,
        },
      ];
    }
    if (this.blockModels.length > 0 && blockModels.length > 0) {
      this.blockModels.splice(1);
      this.blockModels[0].block = blockModels[0].block;
      this.blockModels[0].rect = blockModels[0].rect;
      this.blockModels.push(...blockModels.slice(1));
    } else this.blockModels = blockModels;
    this.changeDetector.detectChanges();
    return this.blocks.toArray();
  }

  onCheckboxChange(): void {
    setTimeout(() => {
      this.focusableContent?.nativeElement?.focus();
    });
  }
}
