import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { JahrgangScopeDto } from '../../models/generated/jahrgang-scope-dto';
import { Jahrgang } from '../../models/jahrgang';
import { Zeugnis } from '../../models/zeugnis';
import { Schueler } from '../../models/schueler';
import { Zeugnissatz } from '../../models/zeugnissatz';
import { JahrgangFactoryService } from './jahrgang-factory.service';

@Injectable({
  providedIn: 'root',
})
export class JahrgangService {
  constructor(
    private http: HttpClient,
    public readonly factory: JahrgangFactoryService
  ) {}

  async throwsCryptographicException(): Promise<boolean> {
    return lastValueFrom(this.http.get<boolean>('/api/jahrgang/throwsCryptographicException'));
  }

  async createJahrgang(zeugnissatz: Zeugnissatz): Promise<string> {
    return lastValueFrom(
      this.http.post<string>('/api/Jahrgang/createJahrgang', zeugnissatz.toScopeIncludeSchuelers(), {
        params: new HttpParams().append('zeugnissatzId', zeugnissatz.id),
      })
    );
  }
  async isJahrgangVorhanden(zeugnissatz: Zeugnissatz): Promise<string | null> {
    return lastValueFrom(
      this.http.post<string | null>('/api/Jahrgang/isJahrgangVorhanden', zeugnissatz.toScope(), {
        params: new HttpParams().append('zeugnissatzId', zeugnissatz.id),
      })
    );
  }

  async getJahrgangCount(): Promise<number> {
    return lastValueFrom(this.http.get<number>('/api/jahrgang/getCount'));
  }

  async getJahrgaenge(): Promise<Jahrgang[]> {
    return this.factory.createJahrgangFromScope(
      await lastValueFrom(this.http.get<JahrgangScopeDto>('/api/jahrgang/getAll'))
    );
  }

  async getJahrgang(jahrgangId: string | undefined): Promise<Jahrgang | undefined> {
    return (
      await this.factory.createJahrgangFromScope(
        await lastValueFrom(
          this.http.get<JahrgangScopeDto>('/api/jahrgang/getJahrgang', {
            params: new HttpParams().appendAll({
              ...(jahrgangId != null && { idJahrgang: jahrgangId }),
            }),
          })
        )
      )
    )[0];
  }

  async updateJahrgang(jahrgang: Jahrgang): Promise<void> {
    await lastValueFrom(this.http.put<Zeugnis>('/api/jahrgang/update', jahrgang.toScopeIncludeZeugnisse()));
  }

  async deleteJahrgang(id: string) {
    await lastValueFrom(
      this.http.delete('/api/jahrgang/deleteJahrgang', { params: new HttpParams().append('idJahrgang', id) })
    );
  }

  async createSchueler(jahrgang: Jahrgang): Promise<Zeugnis> {
    const scope = await lastValueFrom(
      this.http.post<JahrgangScopeDto>('/api/Schueler/create', undefined, {
        params: new HttpParams().append('idJahrgang', jahrgang.id),
      })
    );
    Jahrgang.mergeAdded(jahrgang, scope);
    return jahrgang.zeugnisse.filter((z) => scope.zeugnisse.some((dto) => dto.id === z.id))[0];
  }

  async createMultipleSchueler(jahrgang: Jahrgang, schuelers: Schueler[]): Promise<Zeugnis[]> {
    const scope = await lastValueFrom(
      this.http.post<JahrgangScopeDto>('/api/Schueler/createMultiple', jahrgang.toScopeIncludeSchuelers(schuelers), {
        params: new HttpParams().append('jahrgangId', jahrgang.id),
      })
    );
    Jahrgang.mergeAdded(jahrgang, scope);
    return jahrgang.zeugnisse.filter((z) => scope.zeugnisse.some((dto) => dto.id === z.id));
  }

  async deleteSchueler(schueler: Schueler) {
    await lastValueFrom(
      this.http.delete('/api/Schueler/delete', { params: new HttpParams().append('idSchueler', schueler.id) })
    );
    for (const zeugnis of [...schueler.zeugnisse]) zeugnis.remove();
    schueler.remove();
  }

  async checkForSchulconfigUpdate(zeugnissatzId: string) {
    return lastValueFrom(
      this.http.get<boolean>('/api/jahrgang/checkForSchulconfigUpdate', {
        params: new HttpParams().append('zeugnissatzId', zeugnissatzId),
      })
    );
  }

  async checkForRasterUpdate(zeugnissatzId: string, bundesland: string, kundennummer: string) {
    return lastValueFrom(
      this.http.get<boolean>('/api/jahrgang/checkForRasterUpdate', {
        params: new HttpParams()
          .append('zeugnissatzId', zeugnissatzId)
          .append('kundennummer', kundennummer)
          .append('bundesland', bundesland),
      })
    );
  }

  async updateZeugnissatzRaster(zeugnissatzId: string, bundesland: string, kundennummer: string) {
    await lastValueFrom(
      this.http.post<void>('/api/jahrgang/updateZeugnissatzRaster', undefined, {
        params: new HttpParams()
          .append('zeugnissatzId', zeugnissatzId)
          .append('kundennummer', kundennummer)
          .append('bundesland', bundesland),
      })
    );
  }

  async updateZeugnissatzMitZeugnissen(zeugnissatz: Zeugnissatz) {
    await lastValueFrom(this.http.put<Zeugnis>('/api/jahrgang/update', zeugnissatz.toScopeIncludeZeugnisse()));
  }

  async weiterfuehren(zeugnissatz: Zeugnissatz) {
    const scope = await lastValueFrom(
      this.http.post<JahrgangScopeDto>(
        '/api/Jahrgang/jahrgangWeiterfuehren',
        {
          jahrgaenge: [Jahrgang.toDto(zeugnissatz.jahrgang)],
          schuelers: zeugnissatz.jahrgang.schuelers.map((s) => Schueler.toDto(s)),
          zeugnissaetze: [Zeugnissatz.toDto(zeugnissatz)],
          zeugnisse: [],
        },
        { params: new HttpParams().append('zeugnissatzId', zeugnissatz.id) }
      )
    );
    Jahrgang.mergeAdded(zeugnissatz.jahrgang, scope);
  }

  async deleteCurrentZeugnissatz(jahrgang: Jahrgang) {
    if (jahrgang.currentZeugnissatz != null) {
      await lastValueFrom(
        this.http.delete<string | null>('/api/Jahrgang/deleteZeugnissatz', {
          params: new HttpParams().append('id', jahrgang.currentZeugnissatz.id),
        })
      );
      jahrgang.currentZeugnissatz.remove();
    }
  }
}
