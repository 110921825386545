import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularBereich } from '../../../models/formular-bereich';
import { FormularFach } from '../../../models/formular-fach';
import { HEAnlageFormular } from './he-anlage-formular';
import { FormularViewType } from '../../formular/formular';
import { KPBlockFactoryComponent } from '../../kp/kp-block-factory.component';
import { HEUnterschriftOptionalComponent } from '../projectables/he-unterschrift-optional.component';

export class HE234hjKPFormular extends HEAnlageFormular {
  get viewType(): FormularViewType {
    return HE234hjKPFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  get deutschBereiche(): FormularBereich[] {
    return [
      this.deutsch.sprechenZuhoeren,
      this.deutsch.texteVerfassen,
      this.deutsch.lesen,
      this.deutsch.spracheUntersuchen,
    ];
  }

  override get areKPItemsOptional(): boolean {
    return true;
  }
  override get warnKPItemsIfOptional(): boolean {
    return true;
  }
}

@Component({
  selector: 'fz-he-234hj-kp-formular',
  templateUrl: 'he-234hj-kp-formular.component.html',
})
export class HE234hjKPFormularComponent extends FormularComponentBase<HE234hjKPFormular> {
  static createModel(formular: HE234hjKPFormular) {
    return {
      sozialLernArbeitsverhalten:
        formular.zeugnis.zeugnissatz.schuljahr < 2023 &&
        formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideItemsDefaultFalse
          ? {
              label: formular.sozialLernArbeitsverhalten.gesamt.description.label,
              kp: KPBlockFactoryComponent.createModelFromBereich(formular.sozialLernArbeitsverhalten.gesamt),
            }
          : undefined,
      deutschBereiche: formular.deutschBereiche.map((bereich) => ({
        trackBy: bereich.key ?? 'gesamt',
        key: bereich.key ?? 'gesamt',
        label: bereich.description.label,
        kp: KPBlockFactoryComponent.createModelFromBereich(bereich),
        verbalVisibleField: bereich.isVisibleOverrideTextDefaultTrueField,
        textField: bereich.isVisibleOverrideTextDefaultTrue ? bereich.text : undefined,
      })),
      unterschrift: HEUnterschriftOptionalComponent.createModel(formular),
    };
  }

  get model() {
    return HE234hjKPFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<HE234hjKPFormular>) {
    super(formularService);
  }

  getDeutschBereich(key: string) {
    return this.formular.deutschBereiche.find((b) => (b.key ?? 'gesamt') === key);
  }
}
