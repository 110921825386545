import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, Message } from 'primeng/api';
import { Subscription, lastValueFrom } from 'rxjs';
import { Benutzer } from '../../../models/generated/benutzer';
import { AppInfoService } from '../../services';
import { NotificationService } from '../../services/notification/notification.service';
import { WorkspaceService } from '../../services/workspace.service';
import { KoennensprofilService } from '../koennensprofil-editor/koennensprofil.service';
import { FlinkyAuthService } from './../../services/auth/flinky-auth.service';
import { VersionService } from './../../services/version.service';
import { ProfileService } from './../profile/profile.service';
import { SystemActionsService } from './../system-actions/system-actions.service';

@Component({
  selector: 'fz-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  items: MenuItem[] = [];
  userMenuItems: MenuItem[] = [];
  benutzername?: string;

  labelMenu = 'Menü';

  infoDialogVisible = false;
  backupDialogVisible = false;
  backupCreating = false;

  serverUpdateAvailableMessage: Message = {
    severity: 'custom',
    summary: 'Update verfügabr',
    detail: 'Bitte starten Sie die Anwendung neu.',
    icon: 'pi-replay',
  };
  clientUpdateAvailableMessage: Message = {
    severity: 'custom',
    summary: 'Update verfügabr',
    detail: 'Bitte laden Sie die Seite neu.',
    icon: 'pi-replay',
  };

  subscriptions: Subscription[] = [];

  constructor(
    public appInfo: AppInfoService,
    private flinkyAuth: FlinkyAuthService,
    private profileService: ProfileService,
    private workspaceService: WorkspaceService,
    private http: HttpClient,
    private systemActionsService: SystemActionsService,
    private router: Router,
    public versionService: VersionService,
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone,
    public notificationService: NotificationService,
    private kpService: KoennensprofilService,

    private breakpointObserver: BreakpointObserver
  ) {
    this.flinkyAuth.lizenzChanged.subscribe(() => {
      this.createMainMenu();
    });
  }

  // @HostListener('window:keyup', ['$event']) // event explizit in runOutsideAngular definieren, damit keine globale Change Detection ausgelöst wird
  keyEvent = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === 'p' && this.IsDruckenMoeglich()) {
      this.router.navigate(['/zeugnissatz-drucken']);
    }
  };
  async ngOnInit() {
    // event explizit in runOutsideAngular definieren, damit keine globale Change Detection ausgelöst wird
    this.zone.runOutsideAngular(() => {
      window.addEventListener('keyup', this.keyEvent);
    });
    this.subscriptions.push(
      this.workspaceService.dataLoadedBehaviour.subscribe(() => this.createMainMenu()),
      this.workspaceService.selectedZeugnissatzChange.subscribe(() => this.createMainMenu()),
      this.versionService.updateAvailableChange.subscribe(() => {
        this.notificationService.toggleHeaderMessage(
          this.serverUpdateAvailableMessage,
          this.versionService.serverUpdateAvailable
        );
        this.notificationService.toggleHeaderMessage(
          this.clientUpdateAvailableMessage,
          this.versionService.clientUpdateAvailable
        );
      })
    );

    this.subscriptions.push(
      this.breakpointObserver.observe(['(min-width: 1180px)']).subscribe((state: BreakpointState) => {
        this.labelMenu = state.matches ? 'Menü' : '';
      })
    );

    this.userMenuItems = [
      {
        label: 'Profil',
        icon: 'pi pi-fw pi-user',
        routerLink: '/profile',
      },
      {
        label: 'Passwort ändern',
        icon: 'pi pi-fw pi-key',
        routerLink: '/change-password',
      },
      {
        label: 'Benutzeraccount löschen',
        icon: 'pi pi-fw pi-trash',
        routerLink: '/delete-account',
      },
      {
        label: 'Datensicherung erstellen',
        icon: 'pi pi-fw pi-download',
        command: () => {
          this.createUserBackup();
        },
      },
      {
        label: 'Aus Datensicherung wiederherstellen',
        icon: 'pi pi-fw pi-upload',
        routerLink: '/restore-user-backup',
      },
      { separator: true },
      {
        label: 'Abmelden',
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
          this.logout();
        },
      },
    ];

    const user: Benutzer | null = this.flinkyAuth.getUser();
    this.benutzername = user?.benutzername;
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    window.removeEventListener('keyup', this.keyEvent);
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  async logout(): Promise<void> {
    await this.workspaceService.close();
    this.flinkyAuth.logout();
  }

  createMainMenu(): Promise<boolean> {
    return new Promise<boolean>((resolve, _reject) => {
      this.items = [
        {
          label: 'Schüler und Zeugnisse',
          icon: 'pi pi-fw pi-home',
          routerLink: '/main',
        },
        {
          label: 'Drucken',
          icon: 'pi pi-fw pi-print',
          routerLink: '/zeugnissatz-drucken',
          disabled: !this.IsDruckenMoeglich(),
        },
        {
          label: 'Öffnen',
          icon: 'pi pi-fw pi-folder',
          routerLink: '/jahrgang-oeffnen',
        },
        {
          label: 'Listen',
          icon: 'pi pi-table',
          disabled: this.workspaceService.currentZeugnissatz == null,

          items: [
            {
              label: 'Noteneingabe',
              routerLink: '/noteneingabeliste',
            },
            {
              label: 'Blankoliste',
              routerLink: '/blankoliste',
            },
            {
              label: 'Konferenzliste',
              routerLink: '/konferenzliste',
            },
            {
              label: 'Schülerliste',
              routerLink: '/schuelerliste',
            },
          ],
        },
        {
          label: 'Neu',
          icon: 'pi pi-fw pi-file',
          items: [
            {
              label: 'Neuer Jahrgang und Zeugnisse',
              tooltip: 'Erstellen Sie einen neuen Jahrgang und Zeugnisse',
              routerLink: '/jahrgang-neu',
            },
            {
              label: 'Nächstes Halbjahr',
              tooltip: 'Erstellen Sie Zeugnisse für das nächste Halbjahr oder die nächste Klassenstufe',
              routerLink: '/jahrgang-weiter',
              disabled:
                (this.workspaceService.currentZeugnissatz?.klassenstufe === 4 &&
                  this.workspaceService.currentZeugnissatz?.halbjahr === 1) ||
                this.workspaceService.currentZeugnissatz == null ||
                !this.workspaceService.isCurrentZeugnissatzSelected,
            },
          ],
        },
        {
          label: 'Im- und Export',
          icon: 'pi pi-fw pi-download',
          items: [
            {
              label: 'Import Zeugnisse/Fächer',
              tooltip:
                'Importieren Sie ganze Zeugnisse oder Fächer einer Lehrkraft aus einer Datei oder aus dem Internet',
              routerLink: '/import-page',
            },
            {
              label: 'Export Zeugnisse/Fächer',
              tooltip: 'Exportieren Sie Zeugnisse/Fächer in eine Datei oder ins Internet',
              routerLink: '/export-page',
              disabled: this.workspaceService.currentZeugnissatz == null,
            },
            { separator: true },
            {
              label: 'Import Textbausteine',
              tooltip: 'Importieren Sie Textbausteine für Ihre Schule aus einer Datei oder aus dem Internet',
              routerLink: '/import-tb',
              disabled: this.flinkyAuth.lizenz?.bundesland == null,
            },
            {
              label: 'Export Textbausteine',
              tooltip: 'Exportieren Sie Textbausteine für Ihre Schule in eine Datei oder ins Internet',
              routerLink: '/export-tb',
              disabled: this.flinkyAuth.lizenz?.bundesland == null,
            },
            { separator: true },
            {
              label: 'Import Schülerdaten',
              tooltip: 'Importieren Sie Schülerdaten aus einer Datei',
              routerLink: '/schuelerliste',
              disabled: this.workspaceService.currentZeugnissatz == null,
            },
          ],
          disabled:
            this.workspaceService.currentZeugnissatz != null && !this.workspaceService.isCurrentZeugnissatzSelected,
        },
        {
          label: 'Könnensprofile',
          icon: 'fa fa-table',
          visible: this.isKoennensprofilBundesland(),
          items: [
            {
              label: 'Aktuelle Items herunterladen',
              command: () => {
                this.updateKoennensprofile();
              },
              visible: this.flinkyAuth.lizenz?.bundesland === 'RP',
            },
            {
              label: 'Formulareinrichtung',
              tooltip: 'Richten Sie die Items für die Könnensprofile ein',
              routerLink: '/koennensprofil-editor',
            },
          ],
          disabled: !this.workspaceService.isCurrentZeugnissatzSelected,
        },
        {
          label: 'Textbausteinverwaltung',
          tooltip:
            'Verwalten Sie Ihre Textbausteine. Erst durch den Export werden die Textbausteine für andere Nutzer im Kollegium sichtbar, wenn diese die Textbausteine importieren.',
          icon: 'fa fa-book',
          routerLink: '/textbaustein-editor',
          disabled: !this.workspaceService.isCurrentZeugnissatzSelected || this.flinkyAuth.lizenz?.bundesland == null,
        },
        { separator: true },
        {
          label: 'Zeugnisse löschen',
          tooltip:
            'Löschen Sie alle Zeugnisse des aktuellen Halbjahres. Achtung: Dieser Vorgang kann nicht rückgängig gemacht werden!',
          icon: 'pi pi-fw pi-trash',
          command: () => {
            this.workspaceService.zeugnisseLoeschen();
          },
          disabled: !this.workspaceService.isCurrentZeugnissatzSelected,
        },
        // { separator: true },
        // {
        //   label: 'Statistiken',
        //   icon: 'pi pi-chart-line',
        //   routerLink: '/reporting',
        // },
        {
          separator: true,
        },
        {
          label: 'TeamViewer Fernwartung',
          icon: 'fa fa-arrows-h',
          command: async () => {
            if (this.systemActionsService.isElectron) await lastValueFrom(this.http.get('/api/system/startTeamViewer'));
            else {
              switch (this.systemActionsService.os) {
                case 'Windows':
                  window.open('https://quicksupport.me', '_blank', 'noopener');
                  break;
                case 'MacOS':
                  window.open('https://quicksupport.me', '_blank', 'noopener');
                  break;
                case 'IOS':
                  window.open(
                    'https://apps.apple.com/us/app/teamviewer-quicksupport/id661649585',
                    '_blank',
                    'noopener'
                  );
                  break;
              }
            }
          },
          // visible: this.getIsElectron(),
        },
      ];
      resolve(true);
    });
  }
  private IsDruckenMoeglich(): boolean | undefined {
    return this.workspaceService.currentZeugnissatz != null;
  }

  getIsElectron(): boolean {
    return this.systemActionsService.isElectron;
  }

  isKoennensprofilBundesland() {
    switch (this.flinkyAuth.lizenz?.bundesland) {
      case 'RP':
        return true;
      case 'HE':
        return true;
      case 'BW':
        return true;
      case 'NI':
        return true;
      default:
        return false;
    }
  }

  showInfoDialog(): void {
    this.infoDialogVisible = true;
  }

  infoDialogClosed(): void {
    this.infoDialogVisible = false;
  }

  createUserBackup() {
    this.backupDialogVisible = true;

    setTimeout(async () => {
      this.backupCreating = true;
      await this.workspaceService.saveSelectedZeugnissatz();
      this.createBackup();
    });
  }

  async createBackup() {
    const data = await this.systemActionsService.createUserBackup();
    const contentDisposition = data.headers.get('content-disposition');
    let filename = 'backup.zip';
    if (contentDisposition) {
      filename = this.systemActionsService.getFilenameFromContentDisposition(contentDisposition);
    }
    if (data.body != null) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data.body);
      link.download = filename;
      link.click();
    }
    this.backupCreating = false;
  }

  async updateKoennensprofile() {
    if (
      this.workspaceService.currentZeugnissatz != null &&
      this.workspaceService.currentZeugnissatz.jahrgang.bundesland === 'RP'
    ) {
      await this.kpService.downloadRaster(
        this.kpService.getCode(this.workspaceService.currentZeugnissatz),
        this.workspaceService.currentZeugnissatz.jahrgang.bundesland,
        this.workspaceService.currentZeugnissatz.jahrgang.kundennummer
      );
    }
    this.router.navigate(['/main']);
    this.workspaceService.kpChange.next();
  }
}
