import { Component, ElementRef, EventEmitter, Input, Optional, Output, SkipSelf, ViewChild } from '@angular/core';
import { ProjectableProvider, Projectable, ProjectableDecoratorBase } from './projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { WorkspaceService } from '../../shared/services';
import { TextComponent } from './text.component';
import { TextField } from '../../models/fields/text-field';
import { Field } from '../../models/fields/field';
import { Zeugnis } from '../../models/zeugnis';

export interface CopyToAllArgs {
  zeugnisse: Zeugnis[];
  text: string;
}

@Component({
  selector: 'fz-linked-text',
  templateUrl: 'linked-text.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, LinkedTextComponent)],
})
export class LinkedTextComponent extends ProjectableDecoratorBase implements Projectable {
  @Input() field: TextField<any> | undefined;
  @Input() linkedField: Field | undefined;
  @Input() fieldName: string | undefined;
  @Output() copyToAll = new EventEmitter<CopyToAllArgs>();
  @ViewChild(TextComponent) text: TextComponent | undefined;
  loading: boolean = false;

  constructor(
    elementRef: ElementRef,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private workspaceService: WorkspaceService
  ) {
    super(elementRef, parentProvider);
  }

  get rect(): DOMRect {
    return this.text?.words[0]?.rect ?? new DOMRect();
  }
  get isDifferent(): boolean {
    return this.field?.value != null && this.field.value !== this.linkedField?.value;
  }

  modelChange(): void {
    if (this.field != null && this.linkedField != null) {
      if (this.linkedField.value == null) {
        this.linkedField.value = this.field?.value;
        this.doCopyToAll(true);
      }
    }
  }

  async doCopyToAll(onlyIfEmpty = false) {
    if (this.field != null && this.linkedField != null) {
      try {
        this.loading = true;
        this.linkedField.value = this.field?.value;
        const zeugnisse = this.workspaceService.selectedZeugnisse.filter(
          (z) => z !== this.workspaceService.selectedZeugnis
        );
        if (this.fieldName != null) {
          for (const zeugnis of zeugnisse) {
            if (!onlyIfEmpty || (zeugnis as any)[this.fieldName] == null)
              (zeugnis as any)[this.fieldName] = this.field.value;
          }
        }
        this.copyToAll.emit({
          zeugnisse,
          text: this.field.value ?? '',
        });
        await this.workspaceService.saveSelectedZeugnissatz();
      } finally {
        this.loading = false;
      }
    }
  }
}
