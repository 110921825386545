import { AppInfoService } from '../../shared/services';
import { Component, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'fz-restore-user-backup-page',
  templateUrl: './restore-user-backup-page.component.html',
  styleUrls: ['./restore-user-backup-page.component.scss'],
})
export class RestoreUserBackupPageComponent implements AfterViewInit {
  @Input() throwsCryptographicException: string | undefined;
  constructor(private appInfoService: AppInfoService) {}

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => (this.appInfoService.currentPageTitle = 'Sicherung wiederherstellen'));
  }
}
