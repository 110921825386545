import { Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { ContentElement } from '@modules/pdl/content-element';
import { jsPDF } from 'jspdf';
import { Page } from './formular-viewer.component';

@Component({
  selector: 'fz-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  encapsulation: ViewEncapsulation.Emulated, // kein ShadowDom, da Firefox mit Darstellung von Caret in ShadowDom Probleme hat (bei Wechsel zwischen zwei ContentEditables im gleichen ShadowDom)
})
export class PageComponent {
  @Input() page: Page | undefined;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  setStylesheet(styleString: string): void {
    const el1 = this.elementRef.nativeElement.shadowRoot?.getElementById('style123');
    if (el1) {
      el1.remove();
    }
    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'style123';
    style.innerHTML = styleString;

    this.elementRef.nativeElement.shadowRoot?.appendChild(style);
  }

  exportPdf(doc: jsPDF): void {
    const pageRect = this.elementRef.nativeElement.getBoundingClientRect();
    for (const element of Array.from(this.elementRef.nativeElement.childNodes)) {
      if (element instanceof ContentElement) {
        element.exportPdf(doc, pageRect);
      }
    }
  }
}
