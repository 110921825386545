import { Component, Input } from '@angular/core';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { RPPanelDecoratorBase } from './rp-panel-decorator-base';
import { RPFormular } from '../formulare/rp-formular';

@Component({
  selector: 'fz-rp-sps',
  templateUrl: 'rp-sps.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, RPSpsComponent)],
})
export class RPSpsComponent extends RPPanelDecoratorBase {
  static createModel(formular: RPFormular) {
    return {
      spsSelectField: formular.spsTyp,
    };
  }

  @Input() model: ReturnType<typeof RPSpsComponent.createModel> | undefined;

  prefixOld =
    'Der Schüler/Die Schülerin hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang ';
  suffixOld =
    ' unterrichtet. Er/Sie nimmt am integrativen Unterricht gem. §29 Abs.3 GSchO teil und wird nach einem individuellen Förderplan unterrichtet. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte der aufgeführten Fächer.';

  prefix2024 =
    'Der Schüler/Die Schülerin hat nach Feststellung der Schulbehörde sonderpädagogischen Förderbedarf und wird im Bildungsgang ';

  suffix2024 =
    ' unterrichtet. Er/Sie nimmt am inklusiven Unterricht nach §14a SchulG teil. Die verbale Beurteilung beschreibt die erworbenen Kompetenzen und Lernfortschritte in den Fächern:';

  get prefix() {
    return this.formular.zeugnis.zeugnissatz.schuljahr >= 2024 ? this.prefix2024 : this.prefixOld;
  }

  get suffix() {
    return this.formular.zeugnis.zeugnissatz.schuljahr >= 2024 ? this.suffix2024 : this.suffixOld;
  }

  get lernenLabel(): string {
    return this.genderize(`${this.prefix}LERNEN${this.suffix}`);
  }

  get ganzheitlicheEntwicklungLabel(): string {
    return this.genderize(`${this.prefix}GANZHEITLICHE ENTWICKLUNG${this.suffix}`);
  }

  get spracheLabel(): string {
    return this.genderize(`${this.prefix}SPRACHE${this.suffix}`);
  }

  get freiPrefixLabel(): string {
    return this.genderize(this.prefix);
  }

  get freiSuffixLabel(): string {
    return this.genderize(this.suffix);
  }

  private genderize(s: string) {
    switch (this.formular.zeugnis.schueler.geschlecht) {
      case 'm':
        return s.replaceAll('Der Schüler/Die Schülerin', 'Der Schüler').replaceAll('Er/Sie', 'Er');
      case 'w':
        return s.replaceAll('Der Schüler/Die Schülerin', 'Die Schülerin').replaceAll('Er/Sie', 'Sie');
      default:
        return s;
    }
  }
}
