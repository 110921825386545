import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import BackupDto from '../../../models/generated/backup-dto';
import { ImportService } from '../import/import.service';

@Injectable({
  providedIn: 'root',
})
export class RestoreUserBackupService {
  constructor(
    private http: HttpClient,
    private importService: ImportService
  ) {}

  loadBackups(): Promise<BackupDto[]> {
    return lastValueFrom(this.http.get<BackupDto[]>('/api/backup/getAll'));
  }

  toBase64(s: string) {
    return btoa(String.fromCodePoint(...new TextEncoder().encode(s)));
  }

  async restore(filename: string, password: string, throwsCryptographicException: boolean): Promise<void> {
    await lastValueFrom(
      this.http.get('/api/backup/restore', {
        params: new HttpParams()
          .append('filename', filename)
          .append('throwsCryptographicException', throwsCryptographicException),
        headers: new HttpHeaders().append('password', this.toBase64(password)),
      })
    );
  }
}
