/* generated file - do not touch! */

enum ExportTbResultCode {
  unknown = 'Unknown',
  success = 'Success',
  failUnknownUploadError = 'FailUnknownUploadError',
  noUser = 'NoUser',
  noPin = 'NoPin',
  uploaderNull = 'UploaderNull',
  failCreateFStream = 'FailCreateFStream',
  noData = 'NoData',
  fail = 'Fail',
}
export default ExportTbResultCode;
