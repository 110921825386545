import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularFach } from '../../../models/formular-fach';
import { RPZeugnisFormular } from './rp-zeugnis-formular';
import { RPZeugnissatzComponent } from '../projectables/rp-zeugnissatz.component';
import { RPSchuelerComponent } from '../projectables/rp-schueler.component';
import { RPSpsComponent } from '../projectables/rp-sps.component';
import { RPFachHeaderComponent } from '../projectables/rp-fach-header.component';
import { RPSprachenportfolioComponent } from '../projectables/rp-sprachenportfolio.component';
import { RPVersaeumnisComponent } from '../projectables/rp-versaeumnis.component';
import { RPSchulortComponent } from '../projectables/rp-schulort.component';
import { RPUnterschriftComponent } from '../projectables/rp-unterschrift.component';
import { FormularViewType } from '../../formular/formular';

export class RP1jVerbalFormular extends RPZeugnisFormular {
  get viewType(): FormularViewType {
    return RP1jVerbalFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    faecher.push(this.religion, this.deutschSachunterricht, this.mathematik, this.musikSportBildendeKunst);
    if (this.herkunftssprache.gesamt.isVisibleOverrideDefaultFalse) faecher.push(this.herkunftssprache);
    if (this.herkunftssprache2.gesamt.isVisibleOverrideDefaultFalse) faecher.push(this.herkunftssprache2);
    return faecher;
  }
}

@Component({
  selector: 'fz-rp-1j-verbal-formular',
  templateUrl: 'rp-1j-verbal-formular.component.html',
})
export class RP1jVerbalFormularComponent extends FormularComponentBase<RP1jVerbalFormular> {
  static createModel(formular: RP1jVerbalFormular) {
    return {
      zeugnissatz: RPZeugnissatzComponent.createModel(formular),
      schueler: RPSchuelerComponent.createModel(formular),
      sps: formular.zeugnis.schuelerTyp === 'Lernbehindert' ? RPSpsComponent.createModel(formular) : undefined,
      sozialLernArbeitsverhalten: {
        headerLabel: formular.sozialLernArbeitsverhalten.description.label,
        textField: formular.sozialLernArbeitsverhalten.gesamt.text,
      },
      faecher: formular.faecher.map((fach) => ({
        trackBy: fach.key,
        key: fach.key,
        headerLabel: fach.gesamt.description.label || '',
        header: RPFachHeaderComponent.createModel(fach, formular),
        textField: fach.gesamt.text,
      })),
      sprachenportfolio: RPSprachenportfolioComponent.createModel(formular),
      versaeumnis: RPVersaeumnisComponent.createModel(formular),
      bemerkungen: {
        headerLabel: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      schulort: RPSchulortComponent.createModel(formular),
      unterschrift: RPUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return RP1jVerbalFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<RP1jVerbalFormular>) {
    super(formularService);
  }
}
