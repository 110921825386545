import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TextbausteinHelperService {
  applyValues(
    textWithVariables: string,
    schuelervorname?: string | null,
    schuelername?: string | null,
    klassenstufe?: number | null,
    schuljahrVoll?: string | null,
    schuljahrVollPlus1?: string | null,
    datumKlassenkonferenz?: string | null
  ): string {
    let text = textWithVariables.replace(/Timo\/AnnaVoll/g, `${schuelervorname} ${schuelername}`);
    text = text.replace(/TimoVoll/g, `${schuelervorname} ${schuelername}`);
    text = text.replace(/AnnaVoll/g, `${schuelervorname} ${schuelername}`);

    text = text.replace(/Timos\/Annas/g, this.genitivEndung(schuelervorname));
    text = text.replace(/Timo\/Annas/, this.genitivEndung(schuelervorname));
    text = text.replace(/Timos/g, this.genitivEndung(schuelervorname));
    text = text.replace(/Annas/g, this.genitivEndung(schuelervorname));

    text = text.replace(/Timo\/Anna/g, schuelervorname ?? '');
    text = text.replace(/Timo/g, schuelervorname ?? '');
    text = text.replace(/Anna/g, schuelervorname ?? '');

    if (klassenstufe != null) {
      text = text.replace('{Klassenstufe}', klassenstufe.toString());
      text = text.replace('{Klassenstufe+1}', (klassenstufe + 1).toString());
      text = text.replace('{Klassenstufe-1}', (klassenstufe - 1).toString());
    }
    text = text.replace('{Schuljahr}', schuljahrVoll ?? '');
    text = text.replace('{Schuljahr+1}', schuljahrVollPlus1 ?? '');
    if (datumKlassenkonferenz != null) {
      text = text.replace('{KlassenkonferenzDatum}', datumKlassenkonferenz);
    }
    return text;
  }
  genitivEndung(schuelervorname: string | null | undefined): string {
    return ((schuelervorname?.endsWith('s') ||
      schuelervorname?.endsWith('ss') ||
      schuelervorname?.endsWith('ß') ||
      schuelervorname?.endsWith('x') ||
      schuelervorname?.endsWith('z') ||
      schuelervorname?.endsWith('tz') ||
      schuelervorname?.endsWith('ce')) ??
      false)
      ? schuelervorname + "'"
      : schuelervorname + 's';
  }
}
