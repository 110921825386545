import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { passwordHelp, passwordKomplexRegex, passwordMinLength, passwordRegexString } from '../../../../consts';
import { WorkspaceService } from '../../../services';
import { FlinkyAuthService } from '../../../services/auth/flinky-auth.service';
import { matchingValidator } from '../../../validators/must-match-validator';

export interface ExportData {
  name: string;
  vorname: string;
  passwort: string;
  klasseSchuljahrHalbjahr: string;
}

@Component({
  selector: 'app-export-start',
  templateUrl: './export-start.component.html',
  styleUrls: ['./export-start.component.scss'],
})
export class ExportStartComponent {
  @Input() header: TemplateRef<any> | undefined;
  @Input() currentZeugnis = '';
  @Output() exportDataSet = new EventEmitter<ExportData>();
  exportform: UntypedFormGroup;

  userName: string;
  passwordHelpList = passwordHelp;
  passwordRegexString = passwordRegexString;

  constructor(
    private workspaceService: WorkspaceService,
    flinkyAuth: FlinkyAuthService
  ) {
    this.userName = flinkyAuth.getUser()?.benutzername ?? '';

    this.exportform = new UntypedFormGroup(
      {
        name: new UntypedFormControl(localStorage.getItem(`${this.userName}_export_name`), Validators.required),
        vorname: new UntypedFormControl(localStorage.getItem(`${this.userName}_export_vorname`), Validators.required),
        password: new UntypedFormControl(null, [
          Validators.required,
          Validators.pattern(passwordKomplexRegex),
          Validators.minLength(passwordMinLength),
        ]),
        passwordConfirm: new UntypedFormControl(null, [Validators.required]),
      },
      { validators: matchingValidator('password', 'passwordConfirm') }
    );
  }

  mustMatch(passwordControl: UntypedFormControl, passwordComparisonControl: UntypedFormControl): ValidatorFn {
    return (control: AbstractControl): Record<string, any> | null => {
      return passwordControl.value !== passwordComparisonControl.value ? { mustMatch: { value: control.value } } : null;
    };
  }
  export(): void {
    localStorage.setItem(`${this.userName}_export_name`, this.exportform.value.name);
    localStorage.setItem(`${this.userName}_export_vorname`, this.exportform.value.vorname);
    this.exportDataSet.next({
      name: this.exportform.value.name,
      vorname: this.exportform.value.vorname,
      passwort: this.exportform.value.password,
      klasseSchuljahrHalbjahr: this.workspaceService.currentZeugnissatz?.klasseSchuljahrHalbjahr ?? '',
    });
  }
}
