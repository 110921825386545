<div class="text-center">
  <div class="text-2xl mb-3">
    {{ appInfo.title }} <span class="text-sm">{{ environment.clientVersion }}</span>
  </div>
  <div class="bg-gray-100">
    <div *ngIf="systemActionsService.isElectron">
      <div class="text-xl">Desktopanwendung</div>
      <div>Speichert Ihre Zeugnisse auf diesem Rechner</div>
    </div>
    <div *ngIf="!systemActionsService.isElectron">
      <div class="text-xl">Webanwendung</div>
      <div>
        Speichert Ihre Zeugnisse DSGVO-konform in der Cloud auf Servern in ISO 27001 zertifizierten Rechenzentren in
        Deutschland
      </div>
    </div>
  </div>
  <div class="flex justify-center">
    <img class="w-20 h-20 mt-3 mb-3" src="assets/Flinky-Logo.svg" alt="Flinky Logo" />
  </div>
  <div class="mt-3 text-xl">Registrieren</div>
</div>

<ng-container *ngIf="state === 'initial'">
  <form [formGroup]="registerform">
    <div class="p-fluid p-formgrid p-grid p-mt-2 overflow-hidden">
      <div class="p-field p-col-12 p-md-12">
        <label for="user">E-Mail-Adresse Benutzer&nbsp;<fz-help-info key="benutzeremail"></fz-help-info></label>
        <input
          fzAutofocus
          pInputText
          type="text"
          formControlName="login"
          [pValidateOnly]="true"
          [pKeyFilter]="emailRegex"
        />
        <div class="p-error" *ngIf="registerform.get('login')?.errors?.['validatePattern'] != null">
          Die angegebene E-Mail-Adresse ist ungültig.
        </div>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="passwort">Passwort</label>
        <p-password
          styleClass="p-input-icon-right"
          id="passwort"
          formControlName="password"
          [toggleMask]="true"
          weakLabel="zu unsicher"
          mediumLabel="ausreichend"
          strongLabel="sicher"
          promptLabel="Passwort eingeben"
          [mediumRegex]="passwordRegexString"
          [strongRegex]="passwordRegexString"
        >
          <ng-template pTemplate="header">
            <h6>Passwort wählen</h6>
          </ng-template>
          <ng-template pTemplate="footer">
            <p-divider></p-divider>
            <p class="p-mt-2">Vorgaben</p>
            <ul class="p-pl-2 p-ml-2 p-mt-0 p-mb-2">
              <li *ngFor="let helpItem of passwordHelpList">{{ helpItem }}</li>
            </ul>
          </ng-template>
        </p-password>
        <div
          class="p-error"
          *ngIf="
            registerform.get('passwordComparison')?.value !== '' &&
            registerform.get('password')?.value !== '' &&
            registerform.errors?.['notmatched']
          "
        >
          Passwörter stimmen nicht überein
        </div>
        <div
          class="p-error"
          *ngIf="
            registerform.get('password')?.errors?.['pattern'] &&
            registerform.get('passwordComparison')?.value !== '' &&
            registerform.get('password')?.value !== ''
          "
        >
          Passwort ist nicht komplex genug
        </div>
        <div
          class="p-error"
          *ngIf="
            registerform.get('password')?.errors?.['minlength'] &&
            registerform.get('passwordComparison')?.value !== '' &&
            registerform.get('password')?.value !== ''
          "
        >
          Passwort ist nicht lang genug! Aktuelle Länge:
          {{ registerform.get('password')?.errors?.['minlength']?.actualLength }} Erforderliche Länge:
          {{ registerform.get('password')?.errors?.['minlength']?.requiredLength }}
        </div>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="passwortwiederholung">Passwortwiederholung</label>
        <p-password
          styleClass="p-input-icon-right"
          id="passwortwiederholung"
          [toggleMask]="true"
          formControlName="passwordComparison"
          [feedback]="false"
        >
        </p-password>
      </div>
      <div class="p-field-checkbox p-col-12 p-md-12">
        <p-checkbox inputId="accept" [binary]="true" formControlName="accept"></p-checkbox>
        <label for="accept">Hiermit bestätige ich die Korrektheit der E-Mail-Adresse.</label>
      </div>

      <div class="p-field p-col-12 p-md-12">
        <button
          [loading]="isLoading"
          class="w-full"
          pButton
          type="button"
          label="Benutzer anlegen"
          icon="pi pi-check"
          [disabled]="!registerform.valid || isLoading"
          (click)="register()"
        ></button>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <button
          pButton
          type="button"
          label="Zurück zur Anmeldung"
          routerLink="/login-form"
          [state]="{ email: registerform.get('login')?.value }"
          class="p-button-outlined w-full"
          icon="pi pi-sign-in"
        ></button>
      </div>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="state === 'waitForActivation'">
  <div class="p-field p-col-12 p-md-12">
    <p-tag styleClass="p-mr-2" [severity]="recoverStateSverity" [value]="recoveryState" [rounded]="true"></p-tag>
  </div>
  <div class="p-field p-col-12 p-md-12">
    {{ activateText }}
  </div>
  <div class="p-field p-col-12 p-md-12">Die angegebene E-Mail-Adresse ist: {{ registerform.get('login')?.value }}</div>

  <div class="p-field p-col-12 p-md-12">
    <button
      class="p-button-outlined w-full"
      pButton
      type="button"
      label="E-Mail-Adresse ändern"
      icon="pi pi-arrow-right-arrow-left"
      (click)="changeEmail()"
    ></button>
  </div>

  <div class="p-field p-col-12 p-md-12">
    <button
      [loading]="isLoading"
      [disabled]="isLoading"
      class="w-full"
      pButton
      type="button"
      label="Aktivierungsmail erneut senden"
      icon="pi pi-envelope"
      (click)="resendActivation()"
    ></button>
  </div>

  <div class="p-field p-col-12 p-md-12">
    <button
      class="w-full"
      pButton
      type="button"
      label="Zurück zur Anmeldung"
      routerLink="/login-form"
      [state]="{ email: registerform.get('login')?.value }"
      class="p-button-outlined w-full"
      icon="pi pi-sign-in"
    ></button>
  </div>
</ng-container>

<ng-container *ngIf="state === 'success'">
  Erfolgreich Aktiviert. Sie werden in {{ timeToRedirect }} Sekunden zur Loginseite weitergeleitet.

  <div class="p-field p-col-12 p-md-12">
    <button
      pButton
      type="button"
      label="Zur Anmeldung"
      routerLink="/login-form"
      [state]="{ email: registerform.get('login')?.value }"
      class="p-button-outlined w-full"
      icon="pi pi-sign-in"
    ></button>
  </div>
</ng-container>
<ng-container *ngIf="state === 'error'">
  Fehler bei der Aktivierung

  <div class="p-field p-col-12 p-md-12">
    <button
      pButton
      type="button"
      label="Zur Anmeldung"
      routerLink="/login-form"
      [state]="{ email: registerform.get('login')?.value }"
      class="p-button-outlined w-full"
      icon="pi pi-sign-in"
    ></button></div
></ng-container>

<ng-container *ngIf="state === 'changeEmail'">
  <form [formGroup]="changeform">
    <div class="p-fluid p-formgrid p-grid p-mt-2">
      <div class="p-field p-col-12 p-md-12">
        <label for="user">E-Mail-Adresse Benutzer&nbsp;<fz-help-info key="benutzeremail"></fz-help-info></label>
        <input
          fzAutofocus
          pInputText
          type="text"
          formControlName="login"
          [pValidateOnly]="true"
          [pKeyFilter]="emailRegex"
        />
        <div class="p-error" *ngIf="changeform.get('login')?.errors?.['validatePattern'] != null">
          Die angegebene E-Mail-Adresse ist ungültig.
        </div>
      </div>
      <div class="p-field-checkbox p-col-12 p-md-12">
        <p-checkbox inputId="accept" [binary]="true" formControlName="accept"></p-checkbox>
        <label for="accept">Hiermit bestätige ich die Korrektheit der E-Mail-Adresse.</label>
      </div>

      <div class="p-field p-col-12 p-md-12">
        <button
          class="w-full"
          pButton
          type="button"
          label="Adresse ändern"
          icon="pi pi-check"
          [disabled]="!changeform.valid"
          (click)="doChangeEmail()"
        ></button>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <button
          pButton
          type="button"
          label="Zurück zur Anmeldung"
          routerLink="/login-form"
          [state]="{ email: registerform.get('login')?.value }"
          class="p-button-outlined w-full"
          icon="pi pi-sign-in"
        ></button>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <button
          pButton
          type="button"
          label="Abbrechen"
          class="p-button-outlined w-full"
          icon="pi pi-times"
          (click)="state = 'waitForActivation'"
        ></button>
      </div>
    </div>
  </form>
</ng-container>

<app-disclaimer></app-disclaimer>
