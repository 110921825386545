<div class="flex flex-col p-5 overflow-auto" style="flex: 1 1 0">
  <p-messages [value]="messages"></p-messages>
  <div class="h-10"></div>
  <p-steps [model]="items" [readonly]="true" [activeIndex]="activeStep"></p-steps>
  <div class="h-10"></div>

  <container-element [ngSwitch]="activeStep">
    <div class="flex flex-col gap-2" *ngSwitchCase="0">
      <p-radioButton
        name="groupname"
        value="manuell"
        label="Manuelle Sicherung auswählen"
        [(ngModel)]="restoreType"
      ></p-radioButton>
      <p-radioButton
        name="groupname"
        value="autosicherung"
        label="Automatische Sicherung auswählen"
        [(ngModel)]="restoreType"
      ></p-radioButton>

      <div class="h-10"></div>
      <button
        pButton
        (click)="activeStep = 1"
        class="w-40"
        icon="pi pi-caret-right"
        label="Weiter"
        [disabled]="restoreType === ''"
      ></button>
    </div>

    <div class="flex flex-col gap-2" *ngSwitchCase="1">
      <div class="flex flex-col" *ngIf="restoreType === 'manuell'">
        Sie können hier sowohl eine manuelle Sicherungsdatei aus der aktuellen Software, als auch eine Sicherungsdatei
        der alten Flinky Zeugnis Software nutzen.
        <div class="h-10"></div>
        <button
          pButton
          label="Datei wählen"
          icon="pi pi-upload"
          class="w-40"
          (click)="openFileSelectionDialog()"
        ></button>
        <div class="h-10"></div>
        gewählte Datei: {{ uploader.files.length > 0 ? uploader.files[0].name : '' }}
      </div>
      <p-table
        *ngIf="restoreType === 'autosicherung'"
        [value]="backups"
        sortField="filename"
        [sortOrder]="-1"
        sortMode="single"
        (onSort)="onSort()"
        dataKey="date"
        [scrollable]="true"
        scrollHeight="100%"
        class="flex-grow"
      >
        <ng-template pTemplate="header">
          <tr>
            <th scope="col">Dateiname</th>
            <th scope="col">Uhrzeit</th>
            <th scope="col">Größe</th>
            <th scope="col"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-backup let-rowIndex="rowIndex" let-expanded="expanded">
          <tr *ngIf="rowGroupMetadata[backup.date].index === rowIndex">
            <td colspan="4">
              <button
                type="button"
                pButton
                [pRowToggler]="backup"
                class="p-button-rounded p-button-secondary p-button-outlined"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></button>
              <span class="p-text-bold p-ml-2">{{ backup.date }}</span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-backup>
          <tr>
            <td>
              {{ backup.filename }}
            </td>
            <td>
              {{ backup.time }}
            </td>
            <td>
              {{ backup.filesize }}
            </td>
            <td>
              <button
                pButton
                type="button"
                [label]="filename == backup.fullFilename ? 'Ausgewählt' : 'Wählen'"
                [icon]="filename == backup.fullFilename ? 'pi pi-check' : ''"
                (click)="filename = backup.fullFilename"
                class="w-40"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="flex flex-row gap-5 mt-10">
        <button pButton (click)="activeStep = 0" icon="pi pi-caret-left" class="w-40" label="Zurück"></button>
        <button
          pButton
          (click)="activeStep = 2"
          class="w-40"
          label="Weiter"
          icon="pi pi-caret-right"
          [disabled]="
            (restoreType === 'autosicherung' && filename === '') ||
            (restoreType === 'manuell' && uploader?.files?.length == 0)
          "
        ></button>
      </div>
    </div>

    <div class="flex flex-col gap-2" *ngSwitchCase="2">
      <div>Bitte geben Sie das Passwort des Benutzers an, mit dem die Sicherung erstellt wurde.</div>
      <div class="w-96">
        <p-password
          fzAutofocus
          [toggleMask]="true"
          [feedback]="false"
          styleClass="p-input-icon-right"
          [(ngModel)]="password"
        ></p-password>
      </div>
      <div class="h-10"></div>
      <div class="flex flex-row gap-5">
        <button pButton (click)="activeStep = 1" class="w-48" icon="pi pi-caret-left" label="Zurück"></button>
        <button
          *ngIf="restoreType === 'manuell'"
          pButton
          (click)="doCombinedUpload()"
          label="Wiederherstellen"
          class="w-48"
          icon="pi pi-check"
          [disabled]="password.length == 0"
        ></button>
        <button
          *ngIf="restoreType === 'autosicherung'"
          pButton
          (click)="wiederherstellen(filename)"
          label="Wiederherstellen"
          class="w-48"
          icon="pi pi-check"
          [disabled]="password.length == 0"
        ></button>
      </div>
    </div>
    <div class="flex flex-col gap-2" *ngSwitchCase="3">
      <div class="flex flex-col" *ngIf="backupRestoring">
        <p>Die Datensicherung wird zurückgespielt. Bitte haben Sie einen Moment Geduld.</p>
        <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
      </div>
      <div class="flex flex-col" *ngIf="!backupRestoring && (completed || error != null)">
        <p class="pb-5" *ngIf="error == null">Die Datensicherung wurde erfolgreich eingespielt.</p>
        <p class="pb-5" *ngIf="error != null">Die Datensicherung konnte nicht wieder eingespielt werden.</p>
        <p *ngIf="error != null">{{ error }}</p>
        <div class="h-10"></div>
        <button
          *ngIf="completed"
          pButton
          type="button"
          label="Zur Zeugnisübersicht"
          icon="pi pi-home"
          class="w-64"
          (click)="zurZeugnisUebersicht()"
        ></button>
        <button
          *ngIf="error != null"
          pButton
          icon="pi pi-check"
          (click)="activeStep = 0"
          label="Erneut starten"
          icon="pi pi-replay"
          class="w-64"
          [disabled]="backupRestoring"
        ></button>
      </div>
    </div>
  </container-element>
</div>
<!--
-->
<p-fileUpload
  #uploader
  mode="basic"
  [styleClass]="'fz-system-restore-uploader'"
  chooseIcon="pi pi-folder-open"
  accept=".zip"
  [auto]="false"
  chooseLabel="Sicherungsdatei auswählen"
  (onUpload)="uploadComplete($event)"
  (onError)="uploadError($event)"
></p-fileUpload>
