<ng-container *ngFor="let word of words">
  <span
    [attr.data-placeholder]="placeholder"
    [fzRichText]="word.fragment"
    [dontClone]="true"
    style="white-space: pre-wrap"
  ></span>
</ng-container>
<ng-template #content="fzPortal" fzPortal>
  <ng-container *ngFor="let word of words">
    <fz-string
      class="select-auto"
      #stringElement
      fzTextInput
      fzTextEditor
      fzFocusable
      [fzKeyFilter]="keyFilter"
      [tabindex]="isWordSelected(word) ? 0 : -1"
      [placeholder]="placeholder"
      [textRange]="word.range"
      style="display: inline-block; min-width: 2rem"
      [style.fontFamily]="word.fontFamily"
      [style.fontWeight]="word.fontWeight"
      [style.fontSize]="word.fontSize"
      [style.left.px]="word.rect.left + 0.1"
      [style.top.px]="word.rect.top"
      (contextmenu)="onContextmenu($event)"
      >{{ word.text }}</fz-string
    >
  </ng-container>
</ng-template>
<ng-template #backDeco="fzPortal" fzPortal>
  <ng-container *ngFor="let word of words">
    <div [fzRect]="word.rect">
      <div
        [ngClass]="{
          'plausi-none': field == null,
          'plausi-ok': field != null && field.error == null && field.warning == null,
          'plausi-error': field?.error,
          'plausi-warning': field?.warning,
        }"
      ></div>
    </div>
  </ng-container>
</ng-template>
<ng-template #frontDeco="fzPortal" fzPortal>
  <p-contextMenu #contextMenu appendTo="body" styleClass="w-60" [model]="contextMenuItems"></p-contextMenu>
</ng-template>
