import { PropertyField } from '../../../models/fields/property-field';
import { SLZeugnis } from '../../../models/sl-zeugnis';

export class VersetzungsvermerkField extends PropertyField<SLZeugnis, string | null> {
  constructor(private zeugnis: SLZeugnis) {
    super(
      {
        label: 'Versetzungsvermerk',
        labelShort: 'Vers',
        property: 'versetzungsvermerkTyp',
        required: false,
        displayType: 'Table',
      },
      { object: zeugnis }
    );
  }
  get value(): string | null {
    if (this.zeugnis.versetzungsvermerkTyp === 'Versetzt') return 'ja';
    else if (this.zeugnis.versetzungsvermerkTyp === 'NichtVersetzt') return 'nein';
    else return null;
  }

  override get error(): string | null {
    return null;
  }

  override get displayStringShort() {
    return this.displayString;
  }
}
