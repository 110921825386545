import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularFach } from '../../../models/formular-fach';
import { FormularViewType } from '../../formular/formular';
import { KPBlockFactoryComponent } from '../../kp/kp-block-factory.component';
import { HEFormular } from './he-formular';
import { HEFormularCategoryKey } from '../he-formularsatz';
import { HEUnterschriftOptionalComponent } from '../projectables/he-unterschrift-optional.component';

export class HE234hjSavFormular extends HEFormular {
  get category(): HEFormularCategoryKey {
    return 'anlageSav';
  }

  override get fontSizeFactor(): number | null {
    return this.zeugnis.fontSizeFactorAnlage;
  }
  override set fontSizeFactor(value: number | null) {
    this.zeugnis.fontSizeFactorAnlage = value;
  }

  get viewType(): FormularViewType {
    return HE234hjSavFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  override get areKPItemsOptional(): boolean {
    return true;
  }
  override get warnKPItemsIfOptional(): boolean {
    return true;
  }
}

@Component({
  selector: 'fz-he-234hj-sav-formular',
  templateUrl: 'he-234hj-sav-formular.component.html',
})
export class HE234hjSavFormularComponent extends FormularComponentBase<HE234hjSavFormular> {
  static createModel(formular: HE234hjSavFormular) {
    return formular.anlageSavTyp != null
      ? {
          kpTyp:
            formular.anlageSavTyp === 'KP'
              ? {
                  label: formular.sozialLernArbeitsverhalten.gesamt.description.label,
                  kp: KPBlockFactoryComponent.createModelFromBereich(formular.sozialLernArbeitsverhalten.gesamt),
                  verbalVisibleField:
                    formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideZusatztextDefaultTrueField,
                  textField: formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideZusatztextDefaultTrue
                    ? formular.sozialLernArbeitsverhalten.gesamt.zusatztext
                    : undefined,
                }
              : undefined,
          freitextTyp:
            formular.anlageSavTyp === 'Freitext'
              ? {
                  textField: formular.sozialLernArbeitsverhalten.gesamt.zusatztext,
                }
              : undefined,
          unterschrift: HEUnterschriftOptionalComponent.createModel(formular),
        }
      : undefined;
  }

  get model() {
    return HE234hjSavFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<HE234hjSavFormular>) {
    super(formularService);
  }
}
