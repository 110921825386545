import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import _ from 'lodash-es';
import { ClientException } from '../../models/client-exception';
import ErrorObject from '../../models/generated/error-object';
import { NotificationService } from './notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  lastError: ErrorObject | null = null;

  constructor(
    private notificationService: NotificationService,
    private http: HttpClient
  ) {}

  handleError(error: { rejection: unknown; message?: string; stack?: string }): void {
    const exception = error.rejection;
    if (exception instanceof ClientException) {
      // anzeigen
      this.notificationService.showPermanentWarning(
        exception.message,
        exception.title !== '' ? exception.title : 'Fehler'
      );
    } else if (exception instanceof HttpErrorResponse) {
      if (exception.error instanceof ErrorEvent) {
        this.notificationService.showPermanentError(exception.error.message, 'Es ist ein Fehler aufgetreten');
      } else {
        switch (exception.status) {
          case 0:
          case 504:
            this.notificationService.showPermanentWarning(
              'Der Server ist nicht erreichbar. Bitte prüfen Sie zunächst Ihre Internetverbindung.',
              'Warnung'
            );
            break;
          case 500:
            this.notificationService.showPermanentError(exception.error, 'Es ist ein Fehler aufgetreten');
            break;
          default:
            this.sendError({ message: error.message ?? error.toString(), stack: error.stack ?? '' }, true);
        }
      }
    } else {
      this.sendError({ message: error.message ?? error.toString(), stack: error.stack ?? '' }, true);
    }
  }

  private sendError(err: ErrorObject, shouldSendToServer: boolean) {
    this.notificationService.showPermanentError(`${err.message} - ${err.stack ?? ''}`, 'Es ist ein Fehler aufgetreten');
    if (isDevMode() || !shouldSendToServer) {
      console.error(err);
    } else {
      if (this.lastError == null || (this.lastError.message !== err.message && this.lastError.stack !== err.stack)) {
        try {
          const clonedErr = _.cloneDeep(err);
          clonedErr.message = `${navigator.userAgent}\n${err.message}`;
          // lastValueFrom(this.http.post('/api/error/send', clonedErr));
          this.lastError = err;
        } catch (_ex) {
          // ignorieren
        }
      }
    }
  }
}
