import { FullRange, NodeHelper, NodeWalker, TextPosition } from '@modules/dom';
import itiriri from 'itiriri';
import { ParseNewLineMode } from './content';

export class RichTextEditor {
  public static readonly defaultFontWeight = '400';
  public static readonly defaultFontStyle = 'normal';
  public static readonly defaultTextDecorationLine = 'none';
  public static readonly defaultVerticalAlign = 'baseline';

  #fragment: DocumentFragment;

  constructor(fragment: DocumentFragment) {
    this.#fragment = NodeHelper.cloneContents(fragment);
  }

  private static extractParagraphElement(fragment: DocumentFragment): HTMLParagraphElement {
    const walker = new NodeWalker(fragment);
    const paragraphElement = itiriri(walker.nodes).find(
      (n) => n instanceof HTMLParagraphElement
    ) as HTMLParagraphElement | null;
    if (paragraphElement != null) NodeHelper.unwrap(paragraphElement);
    for (const ignoredParagraphElement of Array.from(
      itiriri(walker.nodes).filter((n) => n instanceof HTMLParagraphElement)
    ) as HTMLParagraphElement[]) {
      NodeHelper.unwrap(ignoredParagraphElement);
    }
    return paragraphElement || document.createElement('p');
  }

  private static normalizeParagraph(
    fragment: DocumentFragment,
    paragraphClass: string,
    classCandidates: string[]
  ): DocumentFragment {
    const paragraphElement = document.createElement('p');
    paragraphElement.className = paragraphClass;
    document.body.appendChild(paragraphElement);
    const spanElement = document.createElement('span');
    paragraphElement.appendChild(spanElement);
    paragraphElement.appendChild(fragment);

    const normalizedFragment = document.createDocumentFragment();
    const walker = new NodeWalker(paragraphElement);
    for (const textNode of Array.from(walker.textNodes)) {
      let spanClass = this.getSpanClass(textNode, classCandidates);
      if (spanClass === paragraphClass) spanClass = '';
      spanElement.className = spanClass;
      const spanStyle = this.extractStyle(window.getComputedStyle(spanElement));
      const textStyle = this.extractStyle(window.getComputedStyle(textNode.parentElement!));

      let normalizedNode: Node = textNode;
      const styleSpanElement = document.createElement('span');
      for (const key in textStyle) {
        if (Object.prototype.hasOwnProperty.call(textStyle, key)) {
          if (textStyle[key] != null && textStyle[key] !== spanStyle[key]) {
            styleSpanElement.style[key] = textStyle[key]!;
            if (normalizedNode !== styleSpanElement) {
              styleSpanElement.appendChild(normalizedNode);
              normalizedNode = styleSpanElement;
            }
          }
        }
      }
      if (spanClass !== '') {
        const classSpanElement = document.createElement('span');
        classSpanElement.className = spanClass;
        classSpanElement.appendChild(normalizedNode);
        normalizedNode = classSpanElement;
      }
      if (
        spanClass !== '' &&
        normalizedFragment.lastChild instanceof HTMLSpanElement &&
        normalizedFragment.lastChild.className === spanClass
      ) {
        normalizedFragment.lastChild.appendChild(normalizedNode.firstChild!);
      } else normalizedFragment.appendChild(normalizedNode);
    }
    document.body.removeChild(paragraphElement);
    normalizedFragment.normalize();
    if (normalizedFragment.childNodes.length === 0) normalizedFragment.appendChild(document.createTextNode(''));
    return normalizedFragment;
  }

  private static getSpanClass(node: Node | null, classCandidates: string[]): string {
    if (node == null) return '';
    if (node instanceof Element && classCandidates.includes(node?.classList?.[0] ?? ''))
      return node?.classList?.[0] ?? '';
    return this.getSpanClass(node.parentNode, classCandidates);
  }

  private static extractStyle(style: CSSStyleDeclaration): Partial<CSSStyleDeclaration> {
    return {
      fontWeight: style.fontWeight,
      fontStyle: style.fontStyle,
      textDecorationLine: style.textDecorationLine,
      verticalAlign: style.verticalAlign,
    };
  }
  public get fragment(): DocumentFragment {
    return this.#fragment;
  }

  public cloneFragment(index: number, length: number): DocumentFragment {
    const range = this.getRangeFromIndexAndLength(index, length);
    return NodeHelper.cloneContents(range);
  }

  public getText(index: number, length: number): string {
    return NodeHelper.toText(this.cloneFragment(index, length));
  }

  public getHtml(index: number, length: number): string {
    return NodeHelper.toHtml(this.cloneFragment(index, length));
  }

  public getStyle(index: number, length: number): Partial<CSSStyleDeclaration> {
    const range = this.getRangeFromIndexAndLength(index, length);
    const div = document.createElement('div');
    div.appendChild(this.#fragment);
    document.body.appendChild(div);
    try {
      const first = itiriri(NodeWalker.getTextNodesForRange(range.startNode, range.endNode)).first();
      if (first == null)
        return {
          fontWeight: RichTextEditor.defaultFontWeight,
          fontStyle: RichTextEditor.defaultFontStyle,
          textDecorationLine: RichTextEditor.defaultTextDecorationLine,
          verticalAlign: RichTextEditor.defaultVerticalAlign,
        };
      const firstStyle = RichTextEditor.extractStyle(window.getComputedStyle(first.parentElement!));
      return itiriri(NodeWalker.getTextNodesForRange(range.startNode, range.endNode))
        .map((n) => RichTextEditor.extractStyle(window.getComputedStyle(n.parentElement!)))
        .reduce(
          (prev, curr) => ({
            fontWeight: prev.fontWeight === curr.fontWeight ? curr.fontWeight : RichTextEditor.defaultFontWeight,
            fontStyle: prev.fontStyle === curr.fontStyle ? curr.fontStyle : RichTextEditor.defaultFontStyle,
            textDecorationLine:
              prev.textDecorationLine === curr.textDecorationLine
                ? curr.textDecorationLine
                : RichTextEditor.defaultTextDecorationLine,
            verticalAlign:
              prev.verticalAlign === curr.verticalAlign ? curr.verticalAlign : RichTextEditor.defaultVerticalAlign,
          }),
          firstStyle
        );
    } finally {
      document.body.removeChild(div);
    }
  }

  public setStyle(index: number, length: number, style: Partial<CSSStyleDeclaration>): void {
    if (length > 0) {
      const posStart = this.findNodeAndOffset(index);
      if (posStart != null) {
        NodeHelper.splitAtPosition(posStart.node as Text, posStart.nodeOffset);
        const posEnd = this.findNodeAndOffset(index + length);
        if (posEnd != null) {
          NodeHelper.splitAtPosition(posEnd.node as Text, posEnd.nodeOffset);
          const startNode = this.findNodeAndOffsetReverse(index)?.node;
          const endNode = this.findNodeAndOffset(index + length)?.node;
          if (startNode != null && endNode != null) {
            for (const text of NodeWalker.getTextNodesForRange(startNode, endNode)) {
              const span = document.createElement('span');
              for (const key in style) {
                if (Object.prototype.hasOwnProperty.call(style, key)) {
                  span.style[key] = style[key] ?? '';
                }
              }
              text.parentNode?.replaceChild(span, text);
              span.appendChild(text);
            }
          }
        }
      }
    }
  }

  public setParagraphClassName(index: number, className: string): void {
    const pos = this.findNodeAndOffset(index);
    if (pos != null) {
      let node: Node | null = pos.node;
      while (node != null && !(node instanceof HTMLParagraphElement)) node = node.parentNode;
      if (node != null && node instanceof HTMLParagraphElement) {
        node.className = className;
      }
    }
  }

  public delete(index: number, length: number): void {
    const range = this.getRangeFromIndexAndLength(index, length);
    NodeHelper.deleteContents(range);
  }

  public insertText(index: number, text: string): void {
    const textNode = document.createTextNode(text);
    const pos = this.findNodeAndOffset(index);
    NodeHelper.insertNode({ node: pos?.node ?? this.#fragment, offset: pos?.nodeOffset ?? 0 }, textNode);
    this.#fragment.normalize();
  }

  public insertFragement(index: number, insertFragment: DocumentFragment): void {
    const pos = this.findNodeAndOffset(index);
    if (pos != null) {
      NodeHelper.insertNode({ node: pos.node, offset: pos.nodeOffset }, insertFragment);
      this.#fragment.normalize();
    }
  }

  public normalize(newLineMode: ParseNewLineMode, classCandidates: string[], defaultClass: string): void {
    let paragraphs = NodeHelper.splitByChar(this.#fragment);
    if (newLineMode === ParseNewLineMode.paragraphTagAndLineFeed) {
      paragraphs = paragraphs.map((p) => NodeHelper.splitByTag(p, 'p')).reduce((prev, curr) => prev.concat(curr), []);
    }
    this.#fragment = NodeHelper.join(
      document.createTextNode('\n'),
      ...paragraphs.map((p) => {
        const extractedParagraphElement = RichTextEditor.extractParagraphElement(p);
        const className = extractedParagraphElement.classList?.[0] ?? '';
        const paragraphElement = document.createElement('p');
        if (classCandidates.includes(className) && className !== defaultClass) paragraphElement.className = className;
        paragraphElement.appendChild(
          RichTextEditor.normalizeParagraph(p, className === '' ? defaultClass : className, classCandidates)
        );
        return paragraphElement;
      })
    );
  }

  private getRangeFromIndexAndLength(index: number, length: number): FullRange {
    const { node: startNode, nodeOffset: startOffset } = this.findNodeAndOffsetReverse(index) ?? {};
    const { node: endNode, nodeOffset: endOffset } = this.findNodeAndOffset(index + length) ?? {};
    if (startNode != null && startOffset != null && endNode != null && endOffset != null) {
      return { startNode, startOffset, endNode, endOffset };
    } else {
      return {
        startNode: this.#fragment,
        startOffset: 0,
        endNode: this.#fragment,
        endOffset: 0,
      };
    }
    // throw `IndexOutOfBounds: startIndex: ${startIndex}, length: ${length}, html: ${this.getText(NodeHelper.cloneContents(node)).html}`;
  }

  private findNodeAndOffset(index: number): TextPosition | undefined {
    const walker = new NodeWalker(this.#fragment);
    return itiriri(walker.textPositions).find((p) => p.index === index);
  }

  private findNodeAndOffsetReverse(index: number): TextPosition | undefined {
    const length = NodeHelper.getLength(this.#fragment);
    const walker = new NodeWalker(this.#fragment);
    return itiriri(walker.textPositionsReverse).find((p) => length - p.index === index);
  }
}
