<span
  [attr.data-placeholder]="placeholder"
  [fzRichText]="fragment"
  [dontClone]="true"
  style="white-space: pre-wrap"
></span>
<ng-template #content="fzPortal" fzPortal>
  <fz-string
    class="select-auto"
    #stringElement
    fzTextInput
    fzTextEditor
    fzFocusable
    (fzHasFocusChange)="hasFocusChange.emit($event)"
    [fzKeyFilter]="keyFilter"
    [placeholder]="placeholder"
    [textRange]="{ start: 0, length: text.length }"
    [fzFont]="font"
    [fzPosition]="rect"
    style="display: inline-block; min-width: 2rem"
    >{{ text }}</fz-string
  >
</ng-template>
<ng-template #backDeco="fzPortal" fzPortal>
  <div [fzRect]="rect">
    <div
      [ngClass]="{
        'plausi-none': field == null,
        'plausi-ok': field != null && field.error == null && field.warning == null,
        'plausi-error': field?.error,
        'plausi-warning': field?.warning,
      }"
    ></div>
  </div>
</ng-template>
<ng-template #frontDeco="fzPortal" fzPortal></ng-template>
