import { FormularFactory } from '@modules/formular/formular-factory';
import { Formularsatz } from '@modules/formular/formularsatz';
import { Bereich } from '../bereich';
import { NoteTyp } from '../enums/note-typ';
import { Note } from '../note';
import { Zeugnis } from '../zeugnis';
import { DropdownCandidate, DropdownCandidateDescription, DropdownFieldBase } from './dropdown-field';
import { FieldDescription } from './field';

export type Notenwert = number | NoteTyp;
export type NoteCandidateDescription = DropdownCandidateDescription<Notenwert | null>;
export type NoteFieldDescription = FieldDescription & {
  isKopfnote: boolean;
  fachKey: string;
  labelKey?: string;
};
export interface NoteFieldRef {
  zeugnis: Zeugnis;
  bereich: Bereich;
}
export class NoteCandidate extends DropdownCandidate<Notenwert | null> {}
export class SuffixCandidate extends DropdownCandidate<string> {}
export class NoteField extends DropdownFieldBase<Notenwert | null, NoteFieldDescription> {
  formularsatz: Formularsatz;
  #candidates: NoteCandidate[] = [];
  #suffixCandidates: SuffixCandidate[] = [];
  constructor(
    description: NoteFieldDescription,
    public ref: NoteFieldRef
  ) {
    super({ displayType: 'Table', ...description });
    this.formularsatz = FormularFactory.getFormularsatz(ref.zeugnis);
  }

  get key() {
    return `${this.description.fachKey}.${this.ref.bereich.key ?? 'gesamt'}.note`;
  }

  get hasSuffix() {
    return (this.suffixCandidates?.length ?? 0) > 1;
  }

  override get displayString() {
    return `${super.displayString}${this.suffix}`;
  }
  override get displayStringShort() {
    return `${super.displayStringShort}${this.suffix}`;
  }
  override get content() {
    return `${super.content}${this.suffix}`;
  }

  get zeugnis() {
    return this.ref.zeugnis;
  }

  get bereich() {
    return this.ref.bereich;
  }

  get candidates(): NoteCandidate[] {
    const candidates = (
      this.description.isKopfnote
        ? this.formularsatz.getKopfnoteCandidates(this.zeugnis, this.description.fachKey)
        : this.formularsatz.getNoteCandidates(this.zeugnis, this.description.fachKey)
    ).map((cd) => new DropdownCandidate(cd));
    candidates.unshift(new DropdownCandidate({ displayStringShort: '<>', content: '', value: null }));
    if (!this.candidatesEqual(candidates, this.#candidates)) this.#candidates = candidates;
    return this.#candidates;
  }
  get suffixCandidates(): SuffixCandidate[] {
    const candidates = (
      this.description.isKopfnote
        ? this.formularsatz.getKopfnoteSuffixCandidates(this.zeugnis, this.description.fachKey)
        : this.formularsatz.getNoteSuffixCandidates(this.zeugnis, this.description.fachKey)
    ).map(
      (c) =>
        new DropdownCandidate({
          value: c,
        })
    );
    candidates.unshift(new DropdownCandidate({ displayStringShort: String.fromCharCode(160), value: '', content: '' }));
    if (!this.candidatesEqual(candidates, this.#suffixCandidates)) this.#suffixCandidates = candidates;
    return this.#suffixCandidates;
  }
  get value(): Notenwert | null {
    if (this.note.typ !== 'Wert') return this.note.typ;
    else return this.wert;
  }
  set value(value: Notenwert | null) {
    if (value == null) {
      this.note.typ = null;
      this.note.wert = 0;
    } else if (typeof value === 'number') {
      this.note.typ = 'Wert';
      this.wert = value;
    } else {
      this.note.typ = value;
      this.note.wert = 0;
    }
  }
  get suffix(): string {
    return this.note.suffix ?? '';
  }
  set suffix(value: string) {
    this.note.suffix = value;
  }
  get text(): string {
    if (this.value == null) return '';
    else {
      const candidate = this.candidates.filter((c) => c.value === this.value)[0];
      if (candidate == null) return '';
      else return `${candidate.content}${this.suffix ?? ''}`;
    }
  }

  private get note(): Note {
    let note: Note | null = this.ref.bereich.note;
    if (note == null) {
      note = new Note();
      this.ref.bereich.note = note;
    }
    return note;
  }

  private get wert(): number {
    if (
      (!this.description.isKopfnote && this.zeugnis.zeugnissatz.is15) ||
      (this.zeugnis.zeugnissatz.jahrgang?.bundesland === 'BW' && this.zeugnis.zeugnissatz.halbjahr === 0)
    )
      return this.note.wert;
    else {
      return 6 - Math.floor((this.note.wert + 2) / 3);
    }
  }
  private set wert(value: number) {
    if (
      (!this.description.isKopfnote && this.zeugnis.zeugnissatz.is15) ||
      (this.zeugnis.zeugnissatz.jahrgang?.bundesland === 'BW' && this.zeugnis.zeugnissatz.halbjahr === 0)
    ) {
      this.note.wert = value;
    } else if (value !== this.wert) {
      this.note.wert = value === 6 ? 0 : (6 - value) * 3 - 1;
    }
  }

  private candidatesEqual(cs1: DropdownCandidate<unknown>[], cs2: DropdownCandidate<unknown>[]): boolean {
    if (cs1.length !== cs2.length) return false;
    for (let index = 0; index < cs1.length; index++) {
      if (cs1[index].value !== cs2[index].value) return false;
      if (cs1[index].displayString !== cs2[index].displayString) return false;
      if (cs1[index].content !== cs2[index].content) return false;
    }
    return true;
  }
}
