<form [formGroup]="importmpodeform" (ngSubmit)="importSelected()">
  <p-panel>
    <ng-template pTemplate="header">
      <ng-container [ngTemplateOutlet]="header ?? null"></ng-container>
    </ng-template>
    <div class="flex flex-col leading-6">
      <div class="flex flex-row">
        <div class="w-64 font-medium">Transfername</div>
        <div>{{ transferFile?.fileName }}</div>
      </div>
      @if (zeugnissatzViewModelNeu != null) {
        <div class="flex flex-row">
          <div class="w-64 font-medium">zu importierender Zeugnissatz</div>
          <div>
            {{ zeugnissatzViewModelNeu.zeugnisTypLabel }} {{ zeugnissatzViewModelNeu.klassenstufeLabel }}
            {{ zeugnissatzViewModelNeu.schuljahrLabel }}
          </div>
        </div>
      }
      @if (zeugnissatzViewModelAlt != null && !jahrgangMismatch) {
        <div class="flex flex-row">
          <div class="w-64 font-medium">aktueller Zeugnissatz</div>
          <div>
            {{ zeugnissatzViewModelAlt.zeugnisTypLabel }} {{ zeugnissatzViewModelAlt.klassenstufeLabel }}
            {{ zeugnissatzViewModelAlt.schuljahrLabel }}
          </div>
        </div>
      }

      <p class="mt-5 mb-2">Legen Sie hier fest, was genau sie importieren möchten:</p>

      <p-radioButton
        *ngIf="!jahrgangMismatch"
        class="mt-2 mb-2"
        label="Fach: Zusammenführen von Fachlehrerdaten in die aktuell geöffneten Zeugnisse. Wählen Sie das Fach und die Schüler aus, die Sie importieren möchten."
        value="fach"
        formControlName="importMode"
        inputId="importMode1"
      ></p-radioButton>

      @if (importmpodeform.value.importMode === 'fach') {
        <div class="flex flex-row gap-2 ml-8 mt-2 mb-2">
          <p-checkbox
            name="appendBeurteilungen"
            [binary]="true"
            [formControl]="appendBeurteilungenControl"
            inputId="appendBeurteilungen"
            [binary]="true"
          ></p-checkbox>
          <label for="appendBeurteilungen"> Beurteilungen anhängen </label>
        </div>
      }

      <p-radioButton
        label="Komplette Zeugnisse: Einen kompletten Import aller Zeugnisse durchführen"
        value="komplett"
        formControlName="importMode"
        inputId="importMode2"
      ></p-radioButton>

      @if (importmpodeform.value.importMode === 'komplett' && matchingJahrgangId != null) {
        <div class="flex flex-row gap-2 ml-8 mt-2">
          <p-checkbox
            name="ueberschreibenBestaetigung"
            [binary]="true"
            [formControl]="ueberschreibenBestaetigungControl"
            inputId="ueberschreiben"
          ></p-checkbox>
          <label for="ueberschreiben">
            @if (matchesCurrentJahrgang) {
              Ja, ich will die aktuell geöffneten Zeugnisse überschreiben.<br /><span class="text-red-700"
                ><strong
                  >Dieser Vorgang ersetzt Ihre aktuell geöffneten Zeugnisse mit den importierten Zeugnissen!</strong
                ></span
              ><br />Nur die importierten Zeugnisse sind danach noch vorhanden.
            } @else {
              Ja, ich will die entsprechenden Zeugnisse (aktuell nicht geöffnet) überschreiben.<br /><span
                class="text-red-700"
                ><strong
                  >Dieser Vorgang ersetzt die entsprechenden Zeugnisse (aktuell nicht geöffnet) mit den importierten
                  Zeugnissen!</strong
                ></span
              ><br />Nur die importierten Zeugnisse sind danach noch vorhanden.
            }
          </label>
        </div>
      }

      @if (importmpodeform.value.importMode === 'fach') {
        <p-pickList
          [source]="faecher"
          [target]="selectedFaecher"
          sourceHeader="nicht zu übernehmende Fächer"
          targetHeader="zu übernehmende Fächer"
          [dragdrop]="!systemActionService.touchDevice"
          [responsive]="true"
          [sourceStyle]="{ height: '10rem' }"
          [targetStyle]="{ height: '10rem' }"
          [showSourceControls]="false"
          [showTargetControls]="false"
          class="mt-2 mb-5"
          (onMoveToTarget)="filterAvailable()"
          (onMoveToSource)="filterAvailable()"
          (onMoveAllToTarget)="filterAvailable()"
          (onMoveAllToSource)="filterAvailable()"
        >
          <ng-template pTemplate="emptymessagesource">
            <div class="flex justify-center align-middle">Keine</div>
          </ng-template>
          <ng-template pTemplate="emptymessagetarget">
            <div class="flex justify-center align-middle">Keine</div>
          </ng-template>

          <ng-template let-fach pTemplate="item">
            <div class="flex gap-5">
              <div class="flex-grow"><i class="pi pi-book"></i>&nbsp;&nbsp;{{ getFachLabel(fach) }}</div>
            </div>
          </ng-template>
        </p-pickList>

        <p-pickList
          [source]="schuelers"
          [target]="selectedSchuelers"
          sourceHeader="nicht zu übernehmende Schüler"
          targetHeader="zu übernehmende Schüler"
          [dragdrop]="!systemActionService.touchDevice"
          [responsive]="true"
          [sourceStyle]="{ height: '10rem' }"
          [targetStyle]="{ height: '10rem' }"
          [showSourceControls]="false"
          [showTargetControls]="false"
          (onMoveToSource)="filterZuordnungen($event)"
          (onMoveAllToSource)="filterZuordnungen($event)"
          class="mb-5"
        >
          <ng-template pTemplate="emptymessagesource">
            <div class="flex justify-center align-middle">Keine</div>
          </ng-template>
          <ng-template pTemplate="emptymessagetarget">
            <div class="flex justify-center align-middle">Keine</div>
          </ng-template>
          <ng-template let-schueler pTemplate="item">
            <div class="flex gap-2 flex-col">
              <div class="flex justify-between flex-row">
                <p><i class="pi pi-id-card"></i>&nbsp;&nbsp;{{ schueler.vorname }} {{ schueler.name }}</p>
                <p
                  *ngIf="isSelectedAndNotMatched(schueler) && getMatchingOption(schueler) === ''"
                  [ngClass]="{ 'bg-red-200': getMatchingOption(schueler) === '' }"
                >
                  Schüler konnte nicht zugeordnet werden.
                </p>
                <p *ngIf="isSelectedAndNotMatched(schueler) && getMatchingOption(schueler) !== ''">neue Zuordnung</p>
              </div>
              <div class="flex flex-grow flex-row justify-end">
                <p-dropdown
                  *ngIf="isSelectedAndNotMatched(schueler) && getMatchingOption(schueler) === ''"
                  class="fz-oben justify-end"
                  value=""
                  [appendTo]="'body'"
                  [options]="mappingOptions"
                  placeholder="Bitte wählen Sie eine Zuordnung"
                  (onChange)="schuelerZuordnungChanged(schueler, $event.value)"
                  optionLabel="label"
                ></p-dropdown>
              </div>
              <div
                class="flex flex-grow flex-row justify-end items-center gap-2"
                *ngIf="isSelectedAndNotMatched(schueler) && getMatchingOption(schueler) !== ''"
              >
                {{ getMatchingOption(schueler) }}
                <button
                  pButton
                  type="button"
                  icon="pi pi-times"
                  class="fz-oben fz-zuordnung-delete-btn p-button-rounded p-button-danger p-button-outlined"
                  (click)="resetZuordnung(schueler)"
                ></button>
              </div>
            </div>
          </ng-template>
        </p-pickList>
      }
    </div>
    <ng-template pTemplate="footer">
      @if (faecher.length === 0 && schuelers.length === 0 && importmpodeform.value.importMode === 'fach') {
        <div class="text-red-700 font-bold mb-2">
          ACHTUNG: Sie überschreiben alle Fächer aller Schüler mit den Daten aus dem Import. Ihre Zeugnisse werden damit
          komplett durch die Version der eingelesenen Daten ersetzt!
        </div>
      }
      <div class="fz-button-group">
        <button
          pButton
          type="button"
          label="Import abbrechen"
          class="w-64 p-button-secondary"
          icon="pi pi-times"
          [loading]="loading"
          (click)="firstStep()"
        ></button>
        <button
          pButton
          type="submit"
          label="Import starten"
          icon="pi pi-check"
          class="w-64"
          [loading]="loading"
          [disabled]="
            !importmpodeform.valid ||
            (!allUnmatchedAssigned() && importmpodeform.value.importMode !== 'komplett') ||
            (this.selectedSchuelers.length == 0 && importmpodeform.value.importMode !== 'komplett')
          "
        ></button>
      </div>
    </ng-template>
  </p-panel>
</form>
