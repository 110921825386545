import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularFach } from '../../../models/formular-fach';
import { HEZeugnisFormular } from './he-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { HEZeugnissatzComponent } from '../projectables/he-zeugnissatz.component';
import { HESchuelerComponent } from '../projectables/he-schueler.component';
import { HEZeugnistypComponent } from '../projectables/he-zeugnistyp.component';
import { HEVersaeumnisComponent } from '../projectables/he-versaeumnis.component';
import { HEVersetzungsvermerkComponent } from '../projectables/he-versetzungsvermerk.component';
import { HEUnterschriftComponent } from '../projectables/he-unterschrift.component';

export class HE123hjFlexFormular extends HEZeugnisFormular {
  get viewType(): FormularViewType {
    return HE123hjFlexFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }
}

@Component({
  selector: 'fz-he-123hj-flex-formular',
  templateUrl: 'he-123hj-flex-formular.component.html',
})
export class HE123hjFlexFormularComponent extends FormularComponentBase<HE123hjFlexFormular> {
  static createModel(formular: HE123hjFlexFormular) {
    return {
      zeugnissatz: HEZeugnissatzComponent.createModel(formular),
      zeugnistyp: HEZeugnistypComponent.createModel(formular),
      schueler: HESchuelerComponent.createModel(formular),
      allgemeineBeurteilung: {
        label: formular.allgemeineBeurteilung.gesamt.description.label,
        textField: formular.allgemeineBeurteilung.gesamt.text,
      },
      bemerkungen: {
        label: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      versaeumnis: HEVersaeumnisComponent.createModel(formular),
      versetzungsvermerk: HEVersetzungsvermerkComponent.createModel(formular),
      unterschrift: HEUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return HE123hjFlexFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<HE123hjFlexFormular>) {
    super(formularService);
  }
}
