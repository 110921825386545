import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { JahrgangScopeDto } from '../../../models/generated/jahrgang-scope-dto';
import ReportJahrgangSelectionOptionDto from '../../../models/generated/report-jahrgang-selection-option-dto';
import { Jahrgang } from '../../../models/jahrgang';
import { JahrgangService } from '../../services/jahrgang.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  constructor(
    private http: HttpClient,
    private jahrgangService: JahrgangService
  ) {}

  getGeschlechter(idJahrgang: string): Promise<any> {
    const httpParams = new HttpParams().append('idJahrgang', idJahrgang);

    const options = { params: httpParams };
    return lastValueFrom(this.http.get('/api/reports/getGeschlechter', options));
  }
  getFormulartypen(idJahrgang: string): Promise<any> {
    const httpParams = new HttpParams().append('idJahrgang', idJahrgang);

    const options = { params: httpParams };
    return lastValueFrom(this.http.get('/api/reports/getFormulartypen', options));
  }

  async getAlleJahrgaenge(): Promise<Jahrgang[]> {
    return this.jahrgangService.factory.createJahrgangFromScope(
      await lastValueFrom(this.http.get<JahrgangScopeDto>('/api/reports/getAlleJahrgaenge'))
    );
  }
  async getJahrgang(idJahrgang: string): Promise<Jahrgang> {
    const httpParams = new HttpParams().append('idJahrgang', idJahrgang);
    const options = { params: httpParams };
    return (
      await this.jahrgangService.factory.createJahrgangFromScope(
        await lastValueFrom(this.http.get<JahrgangScopeDto>('/api/reports/getJahrgang', options))
      )
    )[0];
  }

  getJahrgangSelectionOptions(): Promise<ReportJahrgangSelectionOptionDto[]> {
    return lastValueFrom(this.http.get<ReportJahrgangSelectionOptionDto[]>('/api/reports/getJahrgangSelectOptions'));
  }

  getColor(_num: number) {
    // const hue = num * 137.508; // use golden angle approximation
    // return `hsl(${hue},50%,75%)`;
    // var r = Math.floor(Math.random() * 255);
    // var g = Math.floor(Math.random() * 255);
    // var b = Math.floor(Math.random() * 255);
    // return 'rgba(' + r + ',' + g + ',' + b + ', 0.5)';

    // const randomColor = require('random-color');
    return this.getRandomColor2();
  }

  getRandomColor2() {
    let length = 6;
    const chars = '0123456789ABCDEF';
    let hex = '#';
    while (length--) hex += chars[(Math.random() * 16) | 0];
    return hex;
  }

  getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }
}
