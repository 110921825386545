export type HelpInfoTextArray = Record<string, string>;

export const helpInfoTexts: HelpInfoTextArray = {
  jahrgangRufname:
    'z.B. Tigerenten\nEine freie Bezeichnung zur besseren Identifikation des Jahrgangs.\nDiese Bezeichung erscheint nicht auf dem Zeugnis!',
  jahrgangKlassenbezeichnung: 'z.B. 3a, 3.1...\nTatsächliche Klassenbezeichnung auf dem Zeugnis',
  restoreDatensicherungsdatei:
    'Diese Datei kann sowohl eine Sicherung aus der alten Flinky Zeugnis Software, als auch eine neue manuell erstellte Sicherung sein.\nBitte beachten Sie, dass Sie das Passwort angeben müssen, das zum Zeitpunkt der Erstellung der Sicherung für den Benutzer gültig war.',
  nameErlaubteZeichen: 'Neben - und _ sind nur Zeichen des Alphabets und Ziffern erlaubt.',
  benutzeremail: 'Als Benutzername für den Login wird die E-Mail-Adresse genutzt',
  formularReset: 'Es werden alle Inhalte bis auf die Schülerdaten entfernt.',
  formularResetNoten: 'Es werden alle Noten entfernt.',
  formularKorrektur: 'Überflüssige Leerzeichen und Interpunktion korrigieren',
  formularErsetzen: 'Textteile ersetzen, z.B. bei Korrektur des Namens',
};
