<p-panel class="flex flex-grow flex-col h-0 overflow-auto" header="Machen Sie bitte einige Angaben zum Druck">
  <div class="p-fluid p-grid">
    <div class="p-field p-col-6 p-md-6 align-middle">
      <label for="kopfzeileLabel">Kopfzeile</label>
      <input name="kopfzeileLabel" type="text" pInputText [(ngModel)]="kopfzeileLabel" />
    </div>
    <div class="p-field p-col-6 p-md-6 align-middle">
      <label for="spaltenanzahl">Anzahl Spalten</label>
      <p-inputNumber
        [(ngModel)]="spaltenAnzahl"
        mode="decimal"
        [showButtons]="true"
        inputId="spaltenanzahl"
        [maxFractionDigits]="0"
        [min]="1"
        [max]="20"
        (onInput)="initTable()"
      >
      </p-inputNumber>
    </div>
  </div>

  <p-table #dt [columns]="cols" [value]="data" styleClass="fz-blanko-table" [autoLayout]="true">
    <ng-template pTemplate="caption">
      <div class="p-d-flex justify-between">
        <div class="text-2xl">{{ kopfzeileLabel }}</div>
        <img src="./assets/Flinky-Logo.png" height="32" width="32" alt="Flinky Logo" #image />
        <!-- <img src="./assets/Flinky-Logo.svg" height="32" width="32" alt="Flinky Logo" #image /> -->
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          scope="namevorname"
          *ngFor="let col of columns"
          [ngClass]="{ 'fz-blanko-table-name': col.field === 'nameVorname' }"
        >
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns" [ngClass]="{ 'fz-blanko-table-name': col.field === 'nameVorname' }">
          {{ rowData[col.field] }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <div class="fz-button-group">
      <button pButton label="Drucken" icon="pi pi-print" (click)="print()"></button>
      <button pButton label="PDF herunterladen" icon="pi pi-download" (click)="download()"></button>
    </div>
  </ng-template>
</p-panel>
