<div class="bg-white" [ngClass]="{ absolute: hidden, invisible: hidden }">
  <ng-container #formularContainer></ng-container>
</div>

<div class="flex-grow flex h-0">
  <div class="flex-grow flex flex-col relative">
    <div class="absolute w-full h-full bg-gray-300 overflow-auto" [ngClass]="{ invisible: hidden }">
      <div class="flex-grow flex flex-wrap">
        <div
          class="page-container"
          *ngFor="let page of pages; index as i"
          [style.width.px]="zoomedPageWidth"
          [style.height.px]="zoomedPageHeight"
          [style.zIndex]="pages.length - i"
        >
          <div
            class="page"
            [style.width.px]="pageWidth"
            [style.height.px]="pageHeight"
            [style.transform]="scaleTransform"
            style="transform-origin: 0 0"
          >
            <ng-container *ngIf="!page.formular.formularService.isReadonly">
              <div style="position: absolute" [style.width.px]="pageWidth" [style.height.px]="pageHeight">
                <ng-container *ngFor="let item of page.items">
                  <div [fzPosition]="item.position">
                    <ng-container [fzPortalOutlet]="item.block.backDeco"></ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <fz-page [page]="page" [ngClass]="{ invisible: hidden }"></fz-page>
            <ng-container *ngIf="!page.formular.formularService.isReadonly">
              <fz-page-margin
                [page]="page"
                margin="top"
                [(userLayoutMode)]="userLayoutMode"
                (layoutChange)="invalidateHeaderFooter(); updatePages()"
              ></fz-page-margin>
              <fz-page-margin
                [page]="page"
                margin="bottom"
                [(userLayoutMode)]="userLayoutMode"
                (layoutChange)="invalidateHeaderFooter(); updatePages()"
              ></fz-page-margin>
              <ng-container *ngFor="let instance of page.formularItemInstances">
                <fz-formular-item
                  [style.top.px]="instance.bottomPosition.y + instance.bottomAlignedOffset"
                  [item]="instance.item"
                  [bottomAlignedVisible]="page.isLast"
                  [(userLayoutMode)]="userLayoutMode"
                  (layoutChange)="updatePages()"
                ></fz-formular-item>
              </ng-container>
              <div style="position: absolute; z-index: 1">
                <ng-container *ngFor="let item of page.items">
                  <div [fzPosition]="item.position">
                    <ng-container [fzPortalOutlet]="item.block.frontDeco"></ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute bottom-5 right-10 w-48 flex gap-1 z-10" [ngClass]="{ invisible: hidden }">
      <div class="flex-grow self-center">
        <p-slider [(ngModel)]="zoomlevel" [min]="50" [max]="200" (onChange)="onSliderChange($event)"></p-slider>
      </div>
      {{ zoomlevel }}%
    </div>
    <p-panel class="absolute right-10 shadow-2xl mt-5 z-50" header="Layout" *ngIf="userLayoutMode">
      <ng-template pTemplate="icons">
        <button pButton class="p-panel-header-icon" (click)="userLayoutMode = false">
          <span class="pi pi-times"></span>
        </button>
      </ng-template>
      <button
        pButton
        label="Für alle übernehmen"
        class="p-button-secondary"
        [loading]="loading"
        (click)="applyLayoutToAll()"
      ></button>
    </p-panel>
  </div>
  <fz-formular-sidebar
    *ngIf="sidebarVisible"
    [formularComponent]="formularComponent"
    [category]="category"
  ></fz-formular-sidebar>
</div>
