import { Type } from '@angular/core';
import { FormularCategory, Formularsatz, HalbjahrDescription } from '@modules/formular/formularsatz';
import { NIFormular } from './formulare/ni-formular';
import { NI12hjStandardFormular } from './formulare/ni-12hj-standard-formular.component';
import { NI1234hjLernstandFormular } from './formulare/ni-1234hj-lernstand-formular.component';
import { NI1234hjGeistigFormular } from './formulare/ni-1234hj-geistig-formular.component';
import { NI1234hjFreitextFormular } from './formulare/ni-1234hj-freitext-formular.component';
import { NI34hjStandardFormular } from './formulare/ni-34hj-standard-formular.component';
import NIFachKey from '../../models/generated/ni-fach-key';
import { NI34hjBerichtFormular } from './formulare/ni-34hj-bericht-formular.component';
import { NI34hjFachtexteFormular } from './formulare/ni-34hj-fachtexte-formular.component';
import { BundeslandTyp } from '../../models/enums/bundesland-typ';
import { Zeugnissatz } from '../../models/zeugnissatz';
import { NIZeugnis } from '../../models/ni-zeugnis';
import { Zeugnis } from '../../models/zeugnis';
import { NoteCandidateDescription } from '../../models/fields/note-field';
import { Jahrgang } from '../../models/jahrgang';
import NIBereichKey from '../../models/generated/ni-bereich-key';

export type NIFormularCategoryKey = 'zeugnis' | 'lernstand';

export class NIFormularsatz extends Formularsatz {
  get zeugnisType(): Type<Zeugnis> {
    return NIZeugnis;
  }

  get bundesland(): BundeslandTyp {
    return 'NI';
  }
  getAllHalbjahre(schuljahr: number): HalbjahrDescription[] {
    return [
      {
        text: '1. Klasse, Jahreszeugnis',
        klassenstufe: 1,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '2. Klasse, Halbjahreszeugnis',
        klassenstufe: 2,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '2. Klasse, Jahreszeugnis',
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '3. Klasse, Halbjahreszeugnis',
        klassenstufe: 3,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '3. Klasse, Jahreszeugnis',
        klassenstufe: 3,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '4. Klasse, Halbjahreszeugnis',
        klassenstufe: 4,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '4. Klasse, Jahreszeugnis',
        klassenstufe: 4,
        halbjahr: 1,
        schuljahr,
      },
    ];
  }

  getRemainingHalbjahre(zeugnissatz: Zeugnissatz): HalbjahrDescription[] {
    const halbjahre: HalbjahrDescription[] = [];
    const klassenstufeHalbjahr = zeugnissatz.klassenstufe + zeugnissatz.halbjahr / 2;
    const schuljahr = zeugnissatz.schuljahr - zeugnissatz.klassenstufe;
    if (klassenstufeHalbjahr < 2.0) {
      const newSchuljahr = schuljahr + 2;
      halbjahre.push({
        text: `2. Klasse, Halbjahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 2,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 2.5) {
      const newSchuljahr = schuljahr + 2;
      halbjahre.push({
        text: `2. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 3.0) {
      const newSchuljahr = schuljahr + 3;
      halbjahre.push({
        text: `3. Klasse, Halbjahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 3,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 3.5) {
      const newSchuljahr = schuljahr + 3;
      halbjahre.push({
        text: `3. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 3,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 4.0) {
      const newSchuljahr = schuljahr + 4;
      halbjahre.push({
        text: `4. Klasse, Halbjahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 4,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 4.5) {
      const newSchuljahr = schuljahr + 4;
      halbjahre.push({
        text: `4. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 4,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }

    return halbjahre;
  }

  getCategories(_zeugnis: NIZeugnis): FormularCategory[] {
    const categories: FormularCategory<NIFormularCategoryKey>[] = [];
    categories.push({ key: 'zeugnis', label: 'Zeugnis' });
    categories.push({ key: 'lernstand', label: 'Lernstand' });
    return categories;
  }

  getFormularType(zeugnis: NIZeugnis, category: NIFormularCategoryKey): Type<NIFormular> | null {
    switch (category) {
      case 'zeugnis':
        return this.getZeugnisFormularType(zeugnis);
      case 'lernstand':
        return NI1234hjLernstandFormular;
      default:
        return null;
    }
  }
  getZeugnisFormularType(zeugnis: NIZeugnis): Type<NIFormular> | null {
    const klassenstufe = zeugnis.zeugnissatz.klassenstufe;
    const schuelerTyp = zeugnis.schuelerTyp;
    if (schuelerTyp === 'Geistigbehindert' && zeugnis.geistigTyp === 'Geistig') return NI1234hjGeistigFormular;
    else if (schuelerTyp === 'Geistigbehindert') return NI1234hjFreitextFormular;
    else if (klassenstufe < 3) return NI12hjStandardFormular;
    else {
      if (schuelerTyp == null && (zeugnis.normalTyp === 'Bericht' || zeugnis.normalTyp === 'KP'))
        return NI34hjBerichtFormular;
      else if (schuelerTyp === 'Lernbehindert' && zeugnis.lernTyp == null) return NI34hjBerichtFormular;
      else if (schuelerTyp === 'Lernbehindert' && zeugnis.lernTyp === 'Fachtexte') return NI34hjFachtexteFormular;
      else return NI34hjStandardFormular;
    }
  }

  noteInZiffern(zeugnis: Zeugnis): boolean {
    return zeugnis.zeugnissatz.zeugniskopflizenz.noteInZiffern && zeugnis.zeugnisTyp !== 'Abgang';
  }

  pushNoten(candidates: NoteCandidateDescription[], zeugnis: Zeugnis): void {
    const noteInZiffern = this.noteInZiffern(zeugnis);
    candidates.push(
      { displayStringShort: '1', content: noteInZiffern ? '1' : 'sehr gut', value: 1 },
      { displayStringShort: '2', content: noteInZiffern ? '2' : 'gut', value: 2 },
      { displayStringShort: '3', content: noteInZiffern ? '3' : 'befriedigend', value: 3 },
      { displayStringShort: '4', content: noteInZiffern ? '4' : 'ausreichend', value: 4 },
      { displayStringShort: '5', content: noteInZiffern ? '5' : 'mangelhaft', value: 5 },
      { displayStringShort: '6', content: noteInZiffern ? '6' : 'ungenügend', value: 6 }
    );
  }

  getNoteCandidates(zeugnis: Zeugnis, fachName: string): NoteCandidateDescription[] {
    const candidates: NoteCandidateDescription[] = [];
    if (fachName === NIFachKey.fremdsprache) {
      if (zeugnis.zeugnissatz.klassenstufe < 4) {
        candidates.push({ displayStringShort: 'teilgenommen', content: 'teilgenommen', value: 'Teilgenommen' });
      } else {
        this.pushNoten(candidates, zeugnis);
      }
      candidates.push(
        { displayStringShort: '/', content: '/', value: 'Slash' },
        { displayStringShort: 'k.n.b.w.', content: 'kann nicht beurteilt werden', value: 'NichtFeststellbar' },
        { displayStringShort: 'n.e.', content: 'nicht erteilt', value: 'NichtErteilt' },
        { displayStringShort: 'n.t.', content: 'nicht teilgenommen', value: 'NichtTeilgenommen' },
        { displayStringShort: 's.Anl.', content: 'siehe Anlage', value: 'Stern' },
        { displayStringShort: 's.Bem.', content: 'siehe Bemerkungen', value: 'Attest' }
      );
    } else if (fachName === NIFachKey.herkunftssprache) {
      this.pushNoten(candidates, zeugnis);
      candidates.push(
        { displayStringShort: '/', content: '/', value: 'Slash' },
        { displayStringShort: 'n.e.', content: 'nicht erteilt', value: 'NichtErteilt' },
        { displayStringShort: 'entfällt', content: 'entfällt', value: 'Fachstreichung' },
        { displayStringShort: 'n.t.', content: 'nicht teilgenommen', value: 'NichtTeilgenommen' },
        { displayStringShort: 's.Anl.', content: 'siehe Anlage', value: 'Stern' },
        { displayStringShort: 's.Bem.', content: 'siehe Bemerkungen', value: 'Attest' }
      );
    } else if (fachName === NIFachKey.sport) {
      this.pushNoten(candidates, zeugnis);
      candidates.push(
        { displayStringShort: '/', content: '/', value: 'Slash' },
        { displayStringShort: 'b', content: 'befreit', value: 'Befreit' },
        { displayStringShort: 'k.n.b.w.', content: 'kann nicht beurteilt werden', value: 'NichtFeststellbar' },
        { displayStringShort: 'n.e.', content: 'nicht erteilt', value: 'NichtErteilt' },
        { displayStringShort: 'n.t.', content: 'nicht teilgenommen', value: 'NichtTeilgenommen' },
        { displayStringShort: 's.Anl.', content: 'siehe Anlage', value: 'Stern' },
        { displayStringShort: 's.Bem.', content: 'siehe Bemerkungen', value: 'Attest' }
      );
      // } else if (fachName === NIFachKey.werken) {
      //   this.pushNoten(candidates, zeugnis);
      //   candidates.push(
      //     { label: '/', content: '/', value: 'Slash' },
      //     { label: 'k.n.b.w.', content: 'kann nicht beurteilt werden', value: 'NichtFeststellbar' },
      //     { label: 'n.e.', content: 'nicht erteilt', value: 'NichtErteilt' },
      //     { label: 'n.t.', content: 'nicht teilgenommen', value: 'NichtTeilgenommen' },
      //     { label: 's.Anl.', content: 'siehe Anlage', value: 'Stern' }
      //   );
    } else {
      this.pushNoten(candidates, zeugnis);
      candidates.push(
        { displayStringShort: '/', content: '/', value: 'Slash' },
        { displayStringShort: 'k.n.b.w.', content: 'kann nicht beurteilt werden', value: 'NichtFeststellbar' },
        { displayStringShort: 'n.e.', content: 'nicht erteilt', value: 'NichtErteilt' },
        { displayStringShort: 'n.t.', content: 'nicht teilgenommen', value: 'NichtTeilgenommen' },
        { displayStringShort: 's.Anl.', content: 'siehe Anlage', value: 'Stern' },
        { displayStringShort: 's.Bem.', content: 'siehe Bemerkungen', value: 'Attest' }
      );
    }
    return candidates;
  }

  getNoteSuffixCandidates(): string[] {
    return ['*', '**', '***', '¹', '²', '³'];
  }

  getKopfnoteCandidates(_zeugnis: Zeugnis, _fachName: string): NoteCandidateDescription[] {
    const candidates: NoteCandidateDescription[] = [];
    return candidates;
  }

  getKopfnoteSuffixCandidates(): string[] {
    return [];
  }

  override initJahrgang(jahrgang: Jahrgang): void {
    for (const zeugnis of jahrgang.zeugnisse) {
      if (
        zeugnis.zeugnissatz.schuljahr >= 2023 &&
        ['', '<p></p>'].includes(
          zeugnis.faecher.getItem(NIFachKey.fremdsprache).bereiche.getItem(NIBereichKey.hoerverstehen).text ?? ''
        )
      ) {
        zeugnis.faecher.getItem(NIFachKey.fremdsprache).bereiche.getItem(NIBereichKey.hoerverstehen).text = [
          zeugnis.faecher.getItem(NIFachKey.fremdsprache).bereiche.getItem(NIBereichKey.kommunikativeFertigkeiten)
            .text ?? '',
          zeugnis.faecher.getItem(NIFachKey.fremdsprache).bereiche.getItem(NIBereichKey.verfuegungSprachlicheMittel)
            .text ?? '',
          zeugnis.faecher.getItem(NIFachKey.fremdsprache).bereiche.getItem(NIBereichKey.interkulturelleKompetenzen)
            .text ?? '',
          zeugnis.faecher.getItem(NIFachKey.fremdsprache).bereiche.getItem(NIBereichKey.methodenkompetenzen).text ?? '',
        ]
          .filter((t) => !['', '<p></p>'].includes(t))
          .join('\n');
      }
      if (
        zeugnis.zeugnissatz.schuljahr === 2023 &&
        zeugnis.zeugnissatz.halbjahr === 0 &&
        zeugnis.schuelerTyp === 'Geistigbehindert' &&
        !['', '<p></p>'].includes(zeugnis.faecher.getItem(NIFachKey.werteNormen).bereiche.getItem(null).text ?? '')
      ) {
        zeugnis.faecher.getItem(NIFachKey.religion).bereiche.getItem(null).text = [
          zeugnis.faecher.getItem(NIFachKey.religion).bereiche.getItem(null).text ?? '',
          zeugnis.faecher.getItem(NIFachKey.werteNormen).bereiche.getItem(null).text ?? '',
        ]
          .filter((t) => !['', '<p></p>'].includes(t))
          .join('\n');
        zeugnis.faecher.getItem(NIFachKey.werteNormen).bereiche.getItem(null).text = null;
      }
    }
  }
}
