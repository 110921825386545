<form #form="ngForm" (ngSubmit)="form.control.markAllAsTouched(); form.valid && ersetzen()">
  <p-dialog #korrekturDialog [style]="{ width: '50vw' }" [(visible)]="dialogVisible" header="Text ersetzen">
    <div class="p-fluid p-formgrid p-grid w-full">
      <div class="p-field p-col-12 p-md-6">
        <label for="searchText">Zu ersetzender Text</label>
        <input
          #searchTextInput="ngModel"
          id="searchText"
          name="searchText"
          pInputText
          [(ngModel)]="searchText"
          [minlength]="3"
          required
        />
        <div
          *ngIf="searchTextInput.invalid && (searchTextInput.dirty || searchTextInput.touched)"
          class="fz-validation-error"
        >
          <div *ngIf="searchTextInput.errors?.['required']">Es muss ein zu ersetzender Text angegeben werden.</div>
          <div *ngIf="searchTextInput.errors?.['minlength']">
            Der zu ersetzende Text muss mindestens 3 Zeichen lang sein.
          </div>
        </div>
      </div>
      <div class="p-field p-col-12 p-md-6">
        <label for="relpaceText">Ersetzen durch</label>
        <input #replaceTextInput="ngModel" pInputText id="relpaceText" name="relpaceText" [(ngModel)]="replaceText" />
        <div
          *ngIf="replaceTextInput.invalid && (replaceTextInput.dirty || replaceTextInput.touched)"
          class="fz-validation-error"
        ></div>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <button
        type="button"
        pButton
        icon="pi pi-times"
        label="Abbrechen"
        class="p-button-outlined"
        (click)="dialogVisible = false"
      ></button>
      <button
        type="submit"
        pButton
        icon="pi pi-check"
        label="Ok"
        [disabled]="form.invalid && searchTextInput.touched && replaceTextInput.touched"
      ></button>
    </ng-template>
  </p-dialog>
</form>
