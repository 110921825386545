import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormularComponentBase } from '../formular-component-base';
import { Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { WorkspaceService } from '../../../shared/services';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'fz-formular-sidebar',
  templateUrl: './formular-sidebar.component.html',
  styleUrls: ['./formular-sidebar.component.scss'],
})
export class FormularSidebarComponent implements OnInit, OnDestroy {
  @Input() formularComponent: FormularComponentBase | undefined;
  @Input() category: string | undefined;
  sidebarNarrow = false;
  sidebarOverlay = false;
  sidebarStaticVisible = true;
  sidebarOverlayVisible = false;
  get sidebarVisible(): boolean {
    return this.sidebarOverlay || this.sidebarNarrow ? this.sidebarOverlayVisible : this.sidebarStaticVisible;
  }
  set sidebarVisible(value: boolean) {
    if (this.sidebarOverlay || this.sidebarNarrow) {
      this.sidebarOverlayVisible = value;
      if (value) sessionStorage.setItem('sidebarOverlayVisible', 'true');
      else sessionStorage.removeItem('sidebarOverlayVisible');
    } else {
      this.sidebarStaticVisible = value;
      if (!value) sessionStorage.setItem('sidebarStaticVisible', 'false');
      else sessionStorage.removeItem('sidebarStaticVisible');
    }
  }
  private subscriptions: Subscription[] = [];

  constructor(
    private elementRef: ElementRef,
    private zone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private confirmationService: ConfirmationService,
    public workspace: WorkspaceService
  ) {}

  ngOnInit(): void {
    this.updateFlags();
    this.subscriptions.push(
      this.breakpointObserver.observe(['(min-width: 1500px)']).subscribe((_state: BreakpointState) => {
        this.updateFlags();
      }),
      this.breakpointObserver.observe(['(max-width: 999px)']).subscribe((_state: BreakpointState) => {
        this.updateFlags();
      })
    );
    if (sessionStorage.getItem('sidebarStaticVisible') != null) {
      this.sidebarStaticVisible = sessionStorage.getItem('sidebarStaticVisible') !== 'false';
    }
    if (sessionStorage.getItem('sidebarOverlayVisible') != null) {
      this.sidebarOverlayVisible = sessionStorage.getItem('sidebarOverlayVisible') === 'true';
    }
    this.zone.runOutsideAngular(() => {
      window.addEventListener('mousedown', this.onMousedown);
    });
    if (this.workspace.isReadonly) this.sidebarVisible = true;
  }

  private updateFlags() {
    if (this.breakpointObserver.isMatched(['(min-width: 1500px)'])) {
      this.sidebarOverlay = false;
      this.sidebarNarrow = false;
    } else if (this.breakpointObserver.isMatched(['(max-width: 999px)'])) {
      this.sidebarOverlay = true;
      this.sidebarNarrow = false;
    } else {
      this.sidebarOverlay = false;
      this.sidebarNarrow = true;
    }
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) subscription.unsubscribe();
    window.removeEventListener('mousedown', this.onMousedown);
  }

  onMousedown = (e: Event): void => {
    if (this.sidebarOverlay === true) {
      if (!this.elementRef.nativeElement.contains(e.target)) {
        this.sidebarVisible = false;
        this.changeDetector.detectChanges();
      }
    }
  };

  reset(nurNoten = false) {
    this.confirmationService.confirm({
      header: 'Frage',
      message: nurNoten
        ? 'Sollen die Noten wirklich zurückgesetzt werden?'
        : 'Soll das Formular wirklich zurückgesetzt werden?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      accept: () => {
        if (nurNoten) this.formularComponent?.formular.resetNoten();
        else this.formularComponent?.formular.reset();
      },
    });
  }
}
