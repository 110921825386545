import { ItemListIndex } from './item-list-index';
import { KPFach } from './kp-fach';

export class KPRaster {
  kpItemsPublic: boolean = false;
  isKPActive: boolean = false;
  headers: string[] = [];
  headersVersion: number = 0;

  readonly faecher = new ItemListIndex<KPFach, undefined>(KPFach, undefined);

  static cleanDto(dto: any): any {
    const { faecher, ...cleanDto } = dto;
    return cleanDto;
  }

  static toDto(raster: KPRaster): unknown {
    return { ...this.cleanDto(raster), faecher: ItemListIndex.toDto(KPFach, raster.faecher) };
  }

  static fromDto(dto: any): KPRaster {
    return Object.assign(new KPRaster(), {
      ...this.cleanDto(dto),
      faecher: ItemListIndex.fromDto(KPFach, dto.faecher, undefined),
    });
  }
}
