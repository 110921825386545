/* generated file - do not touch! */

class FachKey {
  public static readonly sozialLernArbeitsverhalten: string = 'sozialLernArbeitsverhalten';
  public static readonly religion: string = 'religion';
  public static readonly sachunterricht: string = 'sachunterricht';
  public static readonly mathematik: string = 'mathematik';
  public static readonly deutsch: string = 'deutsch';
  public static readonly bildendeKunst: string = 'bildendeKunst';
  public static readonly musik: string = 'musik';
  public static readonly sport: string = 'sport';
  public static readonly schrift: string = 'schrift';
}
export default FachKey;
