import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { NodeHelper } from '@modules/dom';
import { Formular } from '@modules/formular/formular';
import { FormularService } from '@modules/formular/formular.service';
import { TextRange } from '@modules/pdl/text-range';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { ProjectableProvider, Projectable, ProjectableBase } from './projectable';

export interface LabelWord {
  fragment: DocumentFragment;
  textNode: Text;
  text: string;
  range: TextRange;
  rect: DOMRect;
  fontFamily: string;
  fontWeight: string;
  fontSize: string;
}

@Component({
  selector: 'fz-label',
  templateUrl: 'label.component.html',
  providers: [provideInterfaceBy(ProjectableProvider, LabelComponent)],
})
export class LabelComponent extends ProjectableBase implements AfterViewInit, AfterViewChecked, Projectable {
  @Input() placeholder?: string;
  @Input() shadow?: string;
  @Input() html = '';
  @Input() color = 'black';
  @ViewChild('componentContent') componentContent!: ElementRef<HTMLElement>;

  words: LabelWord[] = [];
  contentHtml = '';

  constructor(
    elementRef: ElementRef<HTMLElement>,
    @SkipSelf() @Optional() parentProvider: ProjectableProvider | null,
    private changeDetector: ChangeDetectorRef,
    public formularService: FormularService<Formular>
  ) {
    super(elementRef, parentProvider);
    changeDetector.detach();
  }
  ngAfterViewInit(): void {
    this.changeDetector.detectChanges(); // initialisiere this.content
    this.contentHtml = this.componentContent.nativeElement.innerHTML;
    this.updateWords();
  }
  ngAfterViewChecked(): void {
    const contentHtml = this.componentContent.nativeElement.innerHTML;
    if (this.contentHtml !== contentHtml) {
      this.contentHtml = contentHtml;
      this.updateWords();
    }
  }
  projectPosition(parentRect: DOMRect): void {
    // console.log('projecting label ' + this.words[0]?.text);
    for (const word of this.words) {
      const element = word.textNode.parentNode as HTMLElement;
      const childRect = element.getBoundingClientRect();
      const style = window.getComputedStyle(element);
      const topDiff = NodeHelper.getFontTopDiff(style.fontFamily, style.fontSize, style.fontWeight);
      word.rect = new DOMRect(
        childRect.left - parentRect.left,
        childRect.top + topDiff.topDiff - parentRect.top,
        childRect.width,
        topDiff.height
      );
      word.fontWeight = style.fontWeight;
      word.fontFamily = style.fontFamily;
      word.fontSize = style.fontSize;
    }
    this.changeDetector.detectChanges();
  }

  private updateWords() {
    const atoms =
      this.html !== ''
        ? NodeHelper.splitByTextNodeAndWhitespace(NodeHelper.toFragment(this.html))
        : NodeHelper.splitByTextNodeAndWhitespace(NodeHelper.cloneContents(this.componentContent.nativeElement));
    if (atoms.length === 0 && (this.placeholder != null || this.shadow != null)) {
      const textNode = document.createTextNode('');
      atoms.push({
        range: { start: 0, length: 0, spaceLength: 0 },
        rootNode: textNode,
        textNode,
      });
    }
    this.words = atoms.map((atom) => {
      const fragment = document.createDocumentFragment();
      fragment.appendChild(atom.textNode);
      const text = atom.textNode.textContent!;
      atom.textNode.textContent = text.replace(/-/g, String.fromCharCode(8209));
      return {
        fragment,
        textNode: atom.textNode,
        text,
        range: atom.range,
        rect: new DOMRect(),
        fontFamily: '',
        fontSize: '',
        fontWeight: '',
      };
    });
    this.changeDetector.detectChanges();
  }
}
