import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularFach } from '../../../models/formular-fach';
import { RPEmpfehlungFormular } from './rp-empfehlung-formular';
import { FormularViewType } from '../../formular/formular';

export class RP4hEmpfehlungFormular extends RPEmpfehlungFormular {
  get viewType(): FormularViewType {
    return RP4hEmpfehlungFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }
}

@Component({
  selector: 'fz-rp-4h-empfehlung-formular',
  templateUrl: 'rp-4h-empfehlung-formular.component.html',
})
export class RP4hEmpfehlungFormularComponent extends FormularComponentBase<RP4hEmpfehlungFormular> {
  static createModel(formular: RP4hEmpfehlungFormular) {
    return {
      anschriftSchuleField: formular.anschriftSchule,
      anschriftElternField: formular.anschriftEltern,
      empfehlungRealschuleField: formular.empfehlungRealschule,
      empfehlungGymnasiumField: formular.empfehlungGymnasium,
      empfehlungausgabedatumField: formular.empfehlungausgabedatum,
      schulleitungField: formular.zeugnissatz.schulleitung,
    };
  }

  get model() {
    return RP4hEmpfehlungFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<RP4hEmpfehlungFormular>) {
    super(formularService);
  }
}
