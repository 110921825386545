import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { RPZeugnisFormular } from './rp-zeugnis-formular';
import { RPFachHeaderComponent } from '../projectables/rp-fach-header.component';
import { KPBlockFactoryComponent } from '../../kp/kp-block-factory.component';
import { FormularViewType } from '../../formular/formular';
import { RPZeugnissatzComponent } from '../projectables/rp-zeugnissatz.component';
import { RPSchuelerComponent } from '../projectables/rp-schueler.component';
import { RPSpsComponent } from '../projectables/rp-sps.component';
import { RPSprachenportfolioComponent } from '../projectables/rp-sprachenportfolio.component';
import { RPVersaeumnisComponent } from '../projectables/rp-versaeumnis.component';
import { RPSchulortComponent } from '../projectables/rp-schulort.component';
import { RPUnterschriftComponent } from '../projectables/rp-unterschrift.component';

export class RP234jaStandardFormular extends RPZeugnisFormular {
  get viewType(): FormularViewType {
    return RP234jaStandardFormularComponent;
  }

  override get noteFields(): NoteField[] {
    return this.faecher
      .map((f) => f.gesamt)
      .filter(
        (b) =>
          this.zeugnis.zeugnissatz.klassenstufe >= 3 &&
          (this.zeugnis.schuelerTyp === null ||
            (this.zeugnis.schuelerTyp === 'Lernbehindert' && b.bereich.isVisibleOverrideNote === true) ||
            (this.zeugnis.schuelerTyp === 'Schuleingangsphase' && this.zeugnis.zeugnissatz.klassenstufe === 4))
      )
      .map((b) => b.note);
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    faecher.push(
      this.religion,
      this.deutsch,
      this.mathematik,
      this.sachunterricht,
      this.musik,
      this.sport,
      this.bildendeKunst
    );
    if (this.herkunftssprache.gesamt.isVisibleOverrideDefaultFalse) faecher.push(this.herkunftssprache);
    if (this.herkunftssprache2.gesamt.isVisibleOverrideDefaultFalse) faecher.push(this.herkunftssprache2);
    return faecher;
  }
}

@Component({
  selector: 'fz-rp-234ja-standard-formular',
  templateUrl: 'rp-234ja-standard-formular.component.html',
})
export class RP234jaStandardFormularComponent extends FormularComponentBase<RP234jaStandardFormular> {
  static createModel(formular: RP234jaStandardFormular) {
    return {
      zeugnissatz: RPZeugnissatzComponent.createModel(formular),
      schueler: RPSchuelerComponent.createModel(formular),
      sps: formular.zeugnis.schuelerTyp === 'Lernbehindert' ? RPSpsComponent.createModel(formular) : undefined,
      sozialLernArbeitsverhalten: {
        headerLabel: formular.sozialLernArbeitsverhalten.gesamt.description.label,
        textField: formular.sozialLernArbeitsverhalten.gesamt.text,
      },
      faecher: formular.faecher.map((fach) => ({
        trackBy: fach.key,
        key: fach.key,
        headerLabel: fach.gesamt.description.label || '',
        header: RPFachHeaderComponent.createModel(fach, formular),
        kp: formular.isKP ? KPBlockFactoryComponent.createModelFromFach(fach) : undefined,
        textField: formular.isVerbal(fach) ? fach.gesamt.text : undefined,
      })),
      sprachenportfolio: RPSprachenportfolioComponent.createModel(formular),
      schrift: {
        headerLabel: 'Schrift',
        textField: formular.schrift.gesamt.text,
      },
      versaeumnis: RPVersaeumnisComponent.createModel(formular),
      bemerkungen: {
        headerLabel: 'Bemerkungen',
        textField: formular.bemerkungen,
      },
      grundschulziel: formular.isAbschlussMoeglich && formular.isAbschluss ? {} : undefined,
      schulort: RPSchulortComponent.createModel(formular),
      unterschrift: RPUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return RP234jaStandardFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<RP234jaStandardFormular>) {
    super(formularService);
  }

  getFach(key: string) {
    return this.formular.faecher.find((f) => f.key === key);
  }
}
