import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { SLFolgeseiteComponent } from '../projectables/sl-folgeseite.component';
import { SLLegendeComponent } from '../projectables/sl-legende.component';
import { SLSeitenfussComponent } from '../projectables/sl-seitenfuss.component';
import { SLFormular } from './sl-formular';
import { SLZeugnisFormular } from './sl-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { SLZeugnissatzComponent } from '../projectables/sl-zeugnissatz.component';
import { SLSchulbesuchsjahrComponent } from '../projectables/sl-schulbesuchsjahr.component';
import { SLSchuelerComponent } from '../projectables/sl-schueler.component';
import { SLSprachenportfolioComponent } from '../projectables/sl-sprachenportfolio.component';
import { SLVersaeumnisComponent } from '../projectables/sl-versaeumnis.component';
import { SLSchulortComponent } from '../projectables/sl-schulort.component';
import { SLUnterschriftComponent } from '../projectables/sl-unterschrift.component';

export class SL12jVerbalFormular extends SLZeugnisFormular {
  get viewType(): FormularViewType {
    return SL12jVerbalFormularComponent;
  }
}

@Component({
  selector: 'fz-sl-12j-verbal-formular',
  templateUrl: 'sl-12j-verbal-formular.component.html',
})
export class SL12jVerbalFormularComponent extends FormularComponentBase<SL12jVerbalFormular> {
  static createModel(formular: SLFormular) {
    return {
      zeugnissatz: SLZeugnissatzComponent.createModel(formular),
      schulbesuchsjahr: SLSchulbesuchsjahrComponent.createModel(formular),
      schueler: SLSchuelerComponent.createModel(formular),
      sozialverhalten: {
        label: 'Sozialverhalten',
        subLabel:
          '(z.B. Gewissenhaftigkeit, Zuverlässigkeit, Kooperationsbereitschaft, Hilfsbereitschaft, angemessener Umgang mit Konflikten, Selbstkontrolle und -reflexion, Selbstständigkeit, Toleranz, Teamfähigkeit, Einhalten von Regeln)',
        textField: formular.sozialLernArbeitsverhalten.sozialverhalten.text,
      },
      lernArbeitsverhalten: {
        label: 'Lern- und Arbeitsverhalten',
        subLabel:
          '(z.B. Anstrengungsbereitschaft, Ausdauer, Aufmerksamkeit, Lernorganisation, Lernreflexion, Sorgfalt)',
        textField: formular.sozialLernArbeitsverhalten.lernArbeitsverhalten.text,
      },
      besondereFaehigkeiten: {
        label: 'Besondere Fähigkeiten/Fertigkeiten',
        textField: formular.besondereFaehigkeiten,
      },
      fachbezogeneLernLeistungsentwicklung: {
        label: 'Fachbezogene Lern- und Leistungsentwicklung',
        textField: formular.fachbezogeneLernLeistungsentwicklung.gesamt.text,
      },
      sprachenportfolio: formular.zeugnis.zeugnissatz.zeugniskopflizenz.isBilingual
        ? SLSprachenportfolioComponent.createModel(formular)
        : undefined,
      bemerkungen: {
        label: 'Bemerkungen',
        textField: formular.bemerkungen,
      },
      versaeumnis: SLVersaeumnisComponent.createModel(formular),
      schulort: SLSchulortComponent.createModel(formular),
      unterschrift: SLUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return SL12jVerbalFormularComponent.createModel(this.formular);
  }

  slFolgeseiteComponent = SLFolgeseiteComponent;
  slSeitenfussComponent = SLSeitenfussComponent;
  slLegendeComponent = SLLegendeComponent;

  constructor(formularService: FormularService<SL12jVerbalFormular>) {
    super(formularService);
  }
}
