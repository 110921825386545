import { Component, Input } from '@angular/core';
import { WorkspaceService } from '../../../shared/services';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { FormularFactory } from '../formular-factory';
import { InputTextModule } from 'primeng/inputtext';
import { TextField } from '../../../models/fields/text-field';
import { CommonModule } from '@angular/common';
import { FZConfirmationService } from '../../../shared/services/fz-confirmation.service';

@Component({
  selector: 'fz-ersetzen',
  standalone: true,
  imports: [CommonModule, FormsModule, DialogModule, InputTextModule, ButtonModule],
  templateUrl: './ersetzen.component.html',
  styleUrl: './ersetzen.component.scss',
})
export class ErsetzenComponent {
  @Input() category: string | undefined;

  dialogVisible = false;
  searchText = '';
  replaceText = '';

  constructor(
    private readonly workspace: WorkspaceService,
    private readonly confirmation: FZConfirmationService
  ) {}

  show() {
    this.dialogVisible = true;
  }

  async ersetzen() {
    if (this.workspace.selectedZeugnis && this.category != null) {
      if (
        await this.confirmation.confirm({
          header: 'Frage',
          message: `Soll der Text "${this.searchText}" wirklich durch den Text "${this.replaceText}" ersetzt werden? Diese Aktion kann nicht rückgängig gemacht werden. `,
        })
      )
        for (const field of FormularFactory.createFormular(this.workspace.selectedZeugnis, this.category).getFields()) {
          if (field instanceof TextField && field.value != null) {
            field.value = field.value.replaceAll(this.searchText, this.replaceText);
          }
        }
    }
    this.dialogVisible = false;
  }
}
