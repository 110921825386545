import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Jahrgang } from '../../../models/jahrgang';
import { FlinkyAuthService } from '../../services/auth/flinky-auth.service';
import { JahrgangScopeDto } from '../../../models/generated/jahrgang-scope-dto';
import { JahrgangFactoryService } from '../../services/jahrgang-factory.service';
import { TransferFile } from '../../../models/generated/transfer-file';
import { ImportException } from './import-exception';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  constructor(
    private http: HttpClient,
    private authService: FlinkyAuthService,
    private jahrgangFactoryService: JahrgangFactoryService
  ) {}

  async importFromInternet(pin: string, kundennummer: string): Promise<TransferFile> {
    try {
      const response = await lastValueFrom(
        this.http.get<TransferFile>('/api/Import/importFromInternet', {
          observe: 'response',
          params: new HttpParams().append('pin', pin).append('kundennummer', kundennummer),
        })
      );
      if (response.body == null)
        throw new ImportException(
          'Die Transferdatei konnte nicht heruntergeladen werden.\n\nVersuchen Sie es evtl. später erneut.'
        );
      return response.body;
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        throw new ImportException(ex.error);
      } else throw ex;
    }
  }

  async importCheckPassword(transferId: string, password: string) {
    return (
      await this.jahrgangFactoryService.createJahrgangFromScope(
        await lastValueFrom(
          this.http.post<JahrgangScopeDto>('/api/Import/importCheckPassword', { transferId, password })
        )
      )
    )[0];
  }

  async getMatchingJahrgangId(jahrgang: Jahrgang): Promise<string> {
    return lastValueFrom(
      this.http.get<string>('/api/Import/matchingJahrgangId', {
        params: new HttpParams().appendAll({
          guid: jahrgang.guid,
          klassenstufe: jahrgang.currentZeugnissatz?.klassenstufe ?? 0,
          klassenbezeichnung: jahrgang.currentZeugnissatz?.klassenbezeichnung ?? '',
        }),
      })
    );
  }

  async resetImport(transferId: string) {
    const httpParams = new HttpParams().append('id', transferId);
    const options = { params: httpParams };
    await lastValueFrom(this.http.get('/api/Import/resetImport', options));
  }

  async overrideZeugnisse(jahrgang: Jahrgang, jahrgangToReplaceId: string | null): Promise<void> {
    await lastValueFrom(
      this.http.post('/api/Import/overrideZeugnisse', jahrgang.toScopeIncludeZeugnisse(), {
        params: new HttpParams().appendAll({
          jahrgangId: jahrgang.id,
          ...(jahrgangToReplaceId != null ? { jahrgangToReplaceId } : {}),
        }),
      })
    );
  }
}
