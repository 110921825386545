<p-dialog
  #korrekturDialog
  [style]="{ width: '50vw' }"
  [(visible)]="korrigierenDialogVisible"
  header="Texte korrigieren"
>
  <div class="flex flex-col gap-2">
    <p-checkbox
      [(ngModel)]="doppelteLeerzeichen"
      [binary]="true"
      inputId="doppelteLeerzeichen"
      label="Überflüssige Leerzeichen entfernen"
    ></p-checkbox>
    <p-checkbox
      [(ngModel)]="interpunktion"
      [binary]="true"
      inputId="interpunktion"
      label="Leerzeichen bei Interpunktion korrigieren"
    ></p-checkbox>
  </div>

  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      icon="pi pi-times"
      label="Abbrechen"
      class="p-button-outlined"
      (click)="korrigierenDialogVisible = false"
    ></button>
    <button type="button" pButton icon="pi pi-check" label="Ok" (click)="korrigieren()"></button>
  </ng-template>
</p-dialog>
