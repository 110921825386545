import { JahrgangScopeDto } from './generated/jahrgang-scope-dto';
import { Zeugniskopflizenz } from './zeugniskopflizenz';
import { ZeugnissatzContainer } from './generated/zeugnissatz-container';
import { Halbjahr } from './enums/halbjahr';
import { Jahrgang } from './jahrgang';
import { KPRaster } from './kp-raster';
import { Metadata } from './metadata';
import { Zeugnis } from './zeugnis';
import { SprachenportfolioSprache } from './enums/sprachenportfolio-sprache';
import { Schueler } from './schueler';
import { Helper } from './helper';
import { orderBy } from 'lodash-es';
import moment from 'moment';

export class Zeugnissatz extends Metadata {
  readonly zeugnisse: Zeugnis[] = [];
  readonly zeugniskopflizenz: Zeugniskopflizenz;
  raster: KPRaster = new KPRaster();

  klassenbezeichnung: string | null = null;
  zeugnisausgabedatum: string | null = null;
  klassenkonferenzdatum: string | null = null;
  wiederbeginn: string | null = null;
  sprachenportfolioSprache: SprachenportfolioSprache | null = null;
  schulleitung: string | null = null;
  klassenleitung: string | null = null;
  zeugnislevel: number | null = null;
  is15: boolean = false;
  protokollDatum: string | null = null;
  duForm: boolean = false;
  fremdspracheBezeichnung: string | null = null;
  anlageKeineUnterschriften: boolean = false;

  get schuljahrHalbjahr(): number {
    return this.schuljahr + this.halbjahr * 0.5;
  }

  get klassenstufeHalbjahr(): number {
    return this.klassenstufe + this.halbjahr * 0.5;
  }

  get canHaveKP(): boolean {
    if (this.jahrgang.bundesland === 'RP')
      return this.schuljahr >= 2024 || this.klassenstufe >= 3 || (this.klassenstufe === 2 && this.halbjahr === 0);
    if (this.jahrgang.bundesland === 'HE')
      return this.klassenstufe >= 3 || (this.klassenstufe === 2 && this.halbjahr === 1);
    if (this.jahrgang.bundesland === 'BW') return true;
    if (this.jahrgang.bundesland === 'NI') return true;
    return false;
  }

  constructor(
    public readonly id: string,
    public readonly jahrgang: Jahrgang,
    public schuljahr: number,
    public halbjahr: Halbjahr,
    public klassenstufe: number
  ) {
    super();
    this.zeugniskopflizenz = jahrgang.schullizenz.findZeugniskopflizenz(this);
    jahrgang.zeugnissaetze.push(this);
  }

  get name(): string {
    return this.jahrgang.rufname ? this.jahrgang.rufname : (this.klassenbezeichnung ?? '');
  }
  get klasseSchuljahrHalbjahr(): string {
    return [
      `Kl. ${this.name}`,
      !this.name.startsWith(this.klassenstufe.toString()) ? `St. ${this.klassenstufe}` : undefined,
      `${this.halbjahr + 1}. Hj.`,
      `${this.schuljahr}/${this.schuljahr + 1}`,
    ]
      .filter((i) => i != null)
      .join(' | ');
  }

  get dateStart(): Date {
    if (this.halbjahr === 0) return new Date(this.schuljahr, 8 /* September */, 1);
    else return new Date(this.schuljahr + 1, 1 /* Februar */, 15);
  }

  get dateEnd(): Date {
    if (this.halbjahr === 0)
      return moment(new Date(this.schuljahr + 1, 1 /* Februar */, 15))
        .add(-1, 'days')
        .toDate();
    else
      return moment(new Date(this.schuljahr + 1, 8 /* September */, 1))
        .add(-1, 'days')
        .toDate();
  }

  static get zielSchuljahr(): number {
    const now = new Date();
    if (now < new Date(now.getFullYear(), 8, 1)) {
      return now.getFullYear() - 1;
    } else {
      return now.getFullYear();
    }
  }

  static get zielHalbjahr(): Halbjahr {
    const now = new Date();
    const month = now.getMonth() + 1;
    if (month >= 3 && month <= 8) return 1;
    else return 0;
  }

  get zielKlassenstufe(): number {
    return this.klassenstufe + (Zeugnissatz.zielSchuljahr - this.schuljahr);
  }

  get canWeiterfuehren(): boolean {
    if (this.zielKlassenstufe <= 4) return true;
    return false;
  }

  canMerge(zeugnissatz: Zeugnissatz) {
    return (
      this.halbjahr === zeugnissatz.halbjahr &&
      this.schuljahr === zeugnissatz.schuljahr &&
      this.klassenstufe === zeugnissatz.klassenstufe
    );
  }

  remove() {
    // Wichtig: Über Kopie iterieren, da Array durch remove geändert wird
    for (const zeugnis of [...this.zeugnisse]) zeugnis.remove();
    this.jahrgang.zeugnissaetze.splice(this.jahrgang.zeugnissaetze.indexOf(this), 1);
  }

  toScope(): JahrgangScopeDto {
    return {
      jahrgaenge: [Jahrgang.toDto(this.jahrgang)],
      schuelers: [],
      zeugnissaetze: [Zeugnissatz.toDto(this)],
      zeugnisse: [],
    };
  }

  toScopeIncludeSchuelers(): JahrgangScopeDto {
    return {
      jahrgaenge: [Jahrgang.toDto(this.jahrgang)],
      schuelers: orderBy(this.jahrgang.schuelers, (s) => s.id).map((s) => Schueler.toDto(s)),
      zeugnissaetze: [Zeugnissatz.toDto(this)],
      zeugnisse: [],
    };
  }

  toScopeIncludeZeugnisse(): JahrgangScopeDto {
    return {
      jahrgaenge: [Jahrgang.toDto(this.jahrgang)],
      schuelers: orderBy(this.jahrgang.schuelers, (s) => s.id).map((s) => Schueler.toDto(s)),
      zeugnissaetze: [Zeugnissatz.toDto(this)],
      zeugnisse: orderBy(this.zeugnisse, (z) => z.id).map((z) => Zeugnis.toDto(z)),
    };
  }

  static override cleanDto(dto: any): any {
    const { id, jahrgang, zeugniskopflizenz, schuljahr, halbjahr, klassenstufe, raster, zeugnisse, ...cleanDto } =
      Metadata.cleanDto(dto);
    return cleanDto;
  }

  static override toDto(zeugnissatz: Zeugnissatz): ZeugnissatzContainer {
    return {
      ...Metadata.toDto(zeugnissatz),
      id: zeugnissatz.id,
      jahrgangId: zeugnissatz.jahrgang.id,
      schuljahr: zeugnissatz.schuljahr,
      halbjahr: zeugnissatz.halbjahr,
      klassenstufe: zeugnissatz.klassenstufe,
      contentString: JSON.stringify(
        {
          ...this.cleanDto(zeugnissatz),
          raster: KPRaster.toDto(zeugnissatz.raster),
        },
        Helper.jsonNullReplacer
      ),
    };
  }

  static fromDto(dto: ZeugnissatzContainer, jahrgang: Jahrgang): Zeugnissatz {
    const contentDto = JSON.parse(dto.contentString);
    const zeugnissatz = new Zeugnissatz(dto.id, jahrgang, dto.schuljahr, dto.halbjahr as Halbjahr, dto.klassenstufe);
    Metadata.assignDto(zeugnissatz, dto);
    return Object.assign(zeugnissatz, {
      ...this.cleanDto(contentDto),
      raster: contentDto.raster == null ? new KPRaster() : KPRaster.fromDto(contentDto.raster),
    });
  }

  static getContentString(zeugnissatz: Zeugnissatz) {
    return JSON.stringify(
      {
        ...this.cleanDto(zeugnissatz),
        raster: KPRaster.toDto(zeugnissatz.raster),
      },
      Helper.jsonNullReplacer
    );
  }
}
