import { Type } from '@angular/core';
import { BundeslandTyp } from '../../models/enums/bundesland-typ';
import { Halbjahr } from '../../models/enums/halbjahr';
import { NoteCandidateDescription } from '../../models/fields/note-field';
import { Zeugnis } from '../../models/zeugnis';
import { Zeugnissatz } from '../../models/zeugnissatz';
import { Formular } from './formular';
import { NullFormular } from './null-formular.component';
import { Jahrgang } from '../../models/jahrgang';

export interface HalbjahrDescription {
  text: string;
  klassenstufe: number;
  halbjahr: Halbjahr;
  schuljahr: number;
}

export interface FormularCategory<T extends string = string> {
  key: T;
  label: string;
}

export abstract class Formularsatz {
  createFormular(zeugnis: Zeugnis, category: string): Formular {
    const type = this.getFormularType(zeugnis, category);
    return type != null ? new type(zeugnis) : new NullFormular(zeugnis);
  }

  abstract get zeugnisType(): Type<Zeugnis>;
  abstract get bundesland(): BundeslandTyp;
  abstract getCategories(zeugnis: Zeugnis): FormularCategory[];
  abstract getFormularType(zeugnis: Zeugnis, category: string): Type<Formular> | null;

  abstract getAllHalbjahre(schuljahr: number): HalbjahrDescription[];

  abstract getRemainingHalbjahre(zeugnissatz: Zeugnissatz): HalbjahrDescription[];
  abstract getNoteCandidates(zeugnis: Zeugnis, fachName: string): NoteCandidateDescription[];
  abstract getNoteSuffixCandidates(zeugnis: Zeugnis, fachName: string): string[];
  abstract getKopfnoteCandidates(zeugnis: Zeugnis, fachName: string): NoteCandidateDescription[];
  abstract getKopfnoteSuffixCandidates(zeugnis: Zeugnis, fachName: string): string[];

  initJahrgang(_jahrgang: Jahrgang) {
    /* muss nicht implementiert werden */
  }

  getDefaultKPHeaderLabels(_zeugnissatz: Zeugnissatz): string[][] {
    return [
      ['++', '', ''],
      ['+', '', ''],
      ['+/-', '', ''],
      ['-', '', ''],
    ];
  }

  get overviewCategories() {
    return ['zeugnis'];
  }

  overrideLock(_zeugnis: Zeugnis) {
    return false;
  }
}
