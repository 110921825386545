import { InterfaceProvider } from '@modules/shared/interface-provider';
import { PortalDirective } from '@modules/shared/portal.directive';

export interface BlockRange {
  start: number;
  length: number;
}
export interface BlockPosition {
  pageIndex: number;
  y: number;
}
export interface BlockInstance {
  position: BlockPosition;
  range: BlockRange;
  height: number;
}
export interface Block {
  content: PortalDirective | undefined;
  backDeco: PortalDirective | undefined;
  frontDeco: PortalDirective | undefined;
}

export interface BlockFactory {
  project(): void;
  getBlockCount(): number;
  measureHeight(range: BlockRange): number;
  layout(ranges: BlockRange[]): Block[];
}

export class BlockFactoryProvider implements InterfaceProvider<BlockFactory> {
  constructor(public provided: BlockFactory) {}
}

export interface BlockFactoryOwner {
  invalidate(): void;
}

export class BlockFactoryOwnerProvider implements InterfaceProvider<BlockFactoryOwner> {
  constructor(public provided: BlockFactoryOwner) {}
}
