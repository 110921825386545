import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularFach } from '../../../models/formular-fach';
import { HEFormular } from './he-formular';
import { HEZeugnisFormular } from './he-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { HEZeugnissatzComponent } from '../projectables/he-zeugnissatz.component';
import { HEZeugnistypComponent } from '../projectables/he-zeugnistyp.component';
import { HESchuelerComponent } from '../projectables/he-schueler.component';
import { HEVersaeumnisComponent } from '../projectables/he-versaeumnis.component';
import { HEUnterschriftComponent } from '../projectables/he-unterschrift.component';
import { HEVersetzungsvermerkComponent } from '../projectables/he-versetzungsvermerk.component';
import { HEIntensivComponent } from '../projectables/he-intensiv.component';
import { HESavComponent } from '../projectables/he-sav.component';
import { HELeistungenIntensivComponent } from '../projectables/he-leistungen-intensiv.component';
import { FachListBlockFactoryComponent } from '../../blocks/fach-list-block-factory.component';

export class HE1234hjIntensivFormular extends HEZeugnisFormular {
  get viewType(): FormularViewType {
    return HE1234hjIntensivFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }
}

@Component({
  selector: 'fz-he-1234hj-intensiv-formular',
  templateUrl: 'he-1234hj-intensiv-formular.component.html',
})
export class HE1234hjIntensivFormularComponent extends FormularComponentBase<HE1234hjIntensivFormular> {
  static createModel(formular: HEFormular) {
    return {
      zeugnissatz: HEZeugnissatzComponent.createModel(formular),
      zeugnistyp: HEZeugnistypComponent.createModel(formular),
      schueler: HESchuelerComponent.createModel(formular),
      intensiv: HEIntensivComponent.createModel(formular),
      sav: formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideNoteDefaultTrue
        ? HESavComponent.createModel(formular)
        : undefined,
      sozialLernArbeitsverhalten: formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideTextDefaultFalse
        ? {
            label: formular.sozialLernArbeitsverhalten.gesamt.description.label,
            textField: formular.sozialLernArbeitsverhalten.gesamt.text,
          }
        : undefined,
      allgemeineBeurteilung: {
        label: 'Folgende Inhalte wurden im Rahmen der Maßnahme behandelt',
        textField: formular.allgemeineBeurteilung.gesamt.text,
      },
      leistungen: HELeistungenIntensivComponent.createModel(formular),
      regelfach: FachListBlockFactoryComponent.createModel(formular.regelfachList),
      freiwilligesFach: FachListBlockFactoryComponent.createModel(formular.freiwilligesFachList),
      versaeumnis: HEVersaeumnisComponent.createModel(formular),
      bemerkungen: {
        label: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      versetzungsvermerk: HEVersetzungsvermerkComponent.createModel(formular),
      unterschrift: HEUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return HE1234hjIntensivFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<HE1234hjIntensivFormular>) {
    super(formularService);
  }
}
