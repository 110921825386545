import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  Block,
  BlockFactory,
  BlockFactoryOwnerProvider,
  BlockFactoryProvider,
  BlockRange,
} from '@modules/blocks/block-factory';
import { BlockDirective } from '@modules/blocks/block.directive';
import { BorderDirection } from '@modules/dom/border-direction';
import { Formular } from '@modules/formular/formular';
import { FormularService } from '@modules/formular/formular.service';
import { PanelComponent } from '@modules/projectables/panel.component';
import { Projectable } from '@modules/projectables/projectable';
import { FocusableDirective } from '@modules/shared/focusable.directive';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { FachListItemComponent } from './fach-list-item.component';
import { FormularFachList } from '../../models/formular-fach-list';
import { WorkspaceService } from '../../shared/services';
import { Fach } from '../../models/fach';
import { NotificationService } from '../../shared/services/notification/notification.service';

interface BlockRow {
  rect: DOMRect;
  projectable: Projectable;
}
interface BlockModel {
  rect: DOMRect;
  rows: BlockRow[];
}

@Component({
  selector: 'fz-fach-list',
  templateUrl: 'fach-list-block-factory.component.html',
  providers: [provideInterfaceBy(BlockFactoryProvider, FachListBlockFactoryComponent)],
})
export class FachListBlockFactoryComponent implements AfterViewInit, BlockFactory {
  static createModel(fachList: FormularFachList) {
    return {
      faecher: fachList.faecher.map((f) => ({
        ...FachListItemComponent.createModel(f),
        trackBy: f.fach.index.toString(),
        index: f.fach.index.toString(),
      })),
    };
  }
  @Input() model: ReturnType<typeof FachListBlockFactoryComponent.createModel> | undefined;
  @Input() fachList: FormularFachList | undefined;
  @ViewChild('shadow', { read: PanelComponent }) shadowComponent?: PanelComponent;
  @ViewChildren('fachComponent', { read: FachListItemComponent }) fachComponents =
    new QueryList<FachListItemComponent>();
  @ViewChildren(BlockDirective) blockComponents = new QueryList<BlockDirective>();
  @ViewChildren(FocusableDirective) focusables = new QueryList<FocusableDirective>();
  borderDirection = BorderDirection;
  blocks: BlockModel[] = [];
  #suppressHeightChange = 0;

  constructor(
    public changeDetector: ChangeDetectorRef,
    private ownerProvider: BlockFactoryOwnerProvider,
    private elementRef: ElementRef<HTMLElement>,
    private formularService: FormularService<Formular>,
    private workspace: WorkspaceService,
    private notificationService: NotificationService
  ) {}
  get formular(): Formular {
    return this.formularService.formular;
  }
  get hasFocus(): boolean {
    return this.focusables.some((f) => f.hasFocus);
  }

  trackByProperty(_index: number, item: any) {
    return item.trackBy;
  }

  ngAfterViewInit(): void {
    this.fachComponents.changes.subscribe(() => {
      if (this.#suppressHeightChange === 0) {
        this.ownerProvider.provided.invalidate();
        this.focusables.get(0)?.focus();
      }
    });
  }

  addFach(): void {
    if (this.fachList != null) {
      this.fachList.addFach();
      this.detectChanges();
      this.ownerProvider.provided.invalidate();
    }
  }

  deleteFach(index: string): void {
    if (this.fachList != null) {
      const fach = this.fachList.faecher.find((f) => f.fach.index.toString() === index);
      if (fach != null) {
        this.fachList.deleteFach(fach);
        this.detectChanges();
        this.ownerProvider.provided.invalidate();
      }
    }
  }

  async copyFaecher() {
    if (this.fachList != null) {
      const zeugnisse = this.workspace.selectedZeugnisse.filter((z) => z !== this.workspace.selectedZeugnis);
      for (const targetZeugnis of zeugnisse) {
        const targetFachList = targetZeugnis.faecher.getItemList(this.fachList.fachKey);
        const oldFaecher = Array.from(targetFachList);
        targetFachList.clear();
        for (const fach of this.fachList.fachList) {
          const label = fach.bereiche.getItem(null).label;
          if ((label ?? '') !== '') {
            const targetFach = new Fach(this.fachList.fachKey, targetZeugnis);
            targetFach.bereiche.getItem(null).label = label;
            targetFach.bereiche.getItem(null).note =
              oldFaecher.map((f) => f.bereiche.getItem(null)).find((b) => b.label === label)?.note ?? null;
            targetFachList.add(targetFach);
          }
        }
      }
      await this.workspace.saveSelectedZeugnissatz();
      this.notificationService.showSuccess('Fächer wurden für alle Schüler übernommen.', '');
    }
  }

  project(): void {
    this.shadowComponent?.project();
    for (const fachComponent of this.fachComponents) fachComponent.project();
  }
  getBlockCount(): number {
    return this.model?.faecher?.length ?? 0;
  }
  measureHeight(range: BlockRange): number {
    this.detectChanges();
    return this.fachComponents
      .toArray()
      .slice(range.start, range.start + range.length)
      .map((p) => p?.height ?? 0)
      .reduce((prev: number, curr: number) => prev + curr, 0);
  }
  layout(ranges: BlockRange[]): Block[] {
    this.blocks = ranges.map((range) => {
      const rows: BlockRow[] = [];
      const top = 0;
      let rowsHeight = 0;
      for (const fachComponent of this.fachComponents.toArray().slice(range.start, range.start + range.length)) {
        const rowRect = fachComponent.sourceElement.getBoundingClientRect();
        const width = rowRect.width;
        const height = rowRect.height;
        rows.push({
          rect: new DOMRect(0, top + rowsHeight, width, height),
          projectable: fachComponent,
        });
        rowsHeight += fachComponent?.height ?? 0;
      }
      return {
        rows,
        rect: new DOMRect(0, top, this.elementRef.nativeElement.getBoundingClientRect().width, rowsHeight),
      };
    });
    this.detectChanges();
    this.project();
    return this.blockComponents.toArray();
  }

  private detectChanges() {
    this.#suppressHeightChange++;
    this.changeDetector.detectChanges();
    this.#suppressHeightChange--;
  }
}
