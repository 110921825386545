import { Field, FieldDescription } from './field';
import { OnlyPropsOfType } from './types';

export type PropertyFieldDescription<T, TValue> = FieldDescription & {
  property: keyof OnlyPropsOfType<T, TValue>;
  keyPrefix?: string;
};

export interface PropertyFieldRef<T, TValue> {
  object: OnlyPropsOfType<T, TValue>;
}

export abstract class PropertyField<
  T,
  TValue,
  TDesc extends PropertyFieldDescription<T, TValue> = PropertyFieldDescription<T, TValue>,
> extends Field<TDesc> {
  constructor(
    description: TDesc,
    public ref: PropertyFieldRef<T, TValue>
  ) {
    super(description);
  }

  get key() {
    if (this.description.keyPrefix != null) {
      return `${this.description.keyPrefix}.${this.description.property as string}`;
    } else return this.description.property as string;
  }

  get propertyValue(): TValue {
    return this.ref.object[this.description.property];
  }
  set propertyValue(value: TValue) {
    this.ref.object[this.description.property] = value;
  }
  get displayString() {
    if (this.value == null) return '';
    if (typeof this.value === 'object') return '...';
    if (typeof this.value === 'boolean') return this.value === true ? 'ja' : 'nein';
    return this.value.toString();
  }
  get displayStringShort() {
    return this.displayString.substring(0, 2);
  }
}
