import { AfterViewInit, Directive, Input, OnChanges, ViewContainerRef } from '@angular/core';
import { PortalDirective } from './portal.directive';

@Directive({ selector: '[fzPortalOutlet]' })
export class PortalOutletDirective implements OnChanges, AfterViewInit {
  @Input('fzPortalOutlet') portal: PortalDirective | undefined;
  private referenceNode: Node;
  private attachedPortal: PortalDirective | undefined;
  private suppressUpdate = 0;
  constructor(private viewContainer: ViewContainerRef) {
    this.referenceNode = this.viewContainer.element.nativeElement;
  }
  ngOnChanges(): void {
    this.updateAttached();
  }
  ngAfterViewInit(): void {
    this.updateAttached();
  }

  private updateAttached() {
    if (this.suppressUpdate === 0) {
      try {
        this.suppressUpdate++;
        if (this.referenceNode.parentNode != null) {
          if (this.attachedPortal != null) {
            for (const portalNode of this.attachedPortal.view.rootNodes) {
              if (this.referenceNode.parentNode.contains(portalNode))
                this.referenceNode.parentNode.removeChild(portalNode);
            }
          }
          this.attachedPortal = this.portal;
          if (this.attachedPortal != null) {
            for (const portalNode of this.attachedPortal.view.rootNodes.reverse()) {
              // if (portalNode.parentNode != null) {
              //   try {
              //     console.log('removeChild before');
              //     portalNode.parentNode.removeChild(portalNode);
              //     console.log('removeChild after');
              //   } catch (ex) {
              //     console.log('removeChild exception');
              //   }
              // }
              this.referenceNode.parentNode.insertBefore(portalNode, this.referenceNode.nextSibling);
            }
            this.attachedPortal.view.detectChanges();
          }
        }
      } finally {
        this.suppressUpdate--;
      }
    }
  }
}
